import { useIntl } from "react-intl";
import { Divider } from "@mui/material";
import seatForwardDisabled from "assets/images/seat/seat_forward_disabled.png";
import seatForwardNormal from "assets/images/seat/seat_forward_normal.png";
import seatForwardSelect from "assets/images/seat/seat_forward_select.png";
import seatReverseNormal from "assets/images/seat/seat_reverse_normal.png";
import useTranslateStation from "hooks/useTranslateStation";
import styled from "styled-components";
import TrainLogo from "components/TrainLogo";
import { ScheduleType } from "types/scheduleType";
import { CarType } from "types/carType";
import CardLayout from "components/layout/CardLayout";

const SeatInfoIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-inline-end: 4px;
`;

interface RestSeat {
  restSeatNum: number;
  scarNo: string;
  seatNum: number;
}

interface TrainInfoIndicatorProps {
  schedule: ScheduleType;
  car: CarType;
  restSeat: RestSeat;
}

const TrainInfoIndicator = ({
  schedule,
  car,
  restSeat,
}: TrainInfoIndicatorProps) => {
  const intl = useIntl();
  const translateStation = useTranslateStation();

  return (
    <CardLayout className="p-4">
      <div className="mb-2 flex items-center gap-2">
        <TrainLogo stlbTrnClsfCd={schedule.stlbTrnClsfCd} size="large" />

        <div>
          <p className="font-bold">
            {intl.formatMessage({ id: "seatSelection.itinerary" })}
            {`${translateStation(schedule.dptRsStnNm)} → ${translateStation(
              schedule.arvRsStnNm
            )} ${schedule.trnGpNm}`}{" "}
            {intl.formatMessage(
              { id: "seatSelection.trainNumber" },
              { number: +schedule.trnNo }
            )}
          </p>
          <p>
            {intl.formatMessage(
              { id: "seatSelection.carNumber" },
              { number: +car.scarNo }
            )}{" "}
            {intl.formatMessage(
              { id: "seatSelection.remaining" },
              { rest: restSeat.restSeatNum, all: restSeat.seatNum }
            )}
          </p>
        </div>
      </div>

      <Divider />

      <div className="mt-2 grid grid-cols-2 gap-2">
        <div className="flex items-center">
          <SeatInfoIcon src={seatForwardNormal} alt="순방향" />
          <p className="text-sm">
            {intl.formatMessage({ id: "seatSelection.forward" })}
          </p>
        </div>
        <div className="flex items-center">
          <SeatInfoIcon src={seatReverseNormal} alt="역방향" />
          <p className="text-sm">
            {intl.formatMessage({ id: "seatSelection.reverse" })}
          </p>
        </div>
        <div className="flex items-center">
          <SeatInfoIcon src={seatForwardDisabled} alt="예매불가" />
          <p className="text-sm">
            {intl.formatMessage({ id: "seatSelection.unavailable" })}
          </p>
        </div>
        <div className="flex items-center">
          <SeatInfoIcon src={seatForwardSelect} alt="선택좌석" />
          <p className="text-sm">
            {intl.formatMessage({ id: "seatSelection.selected" })}
          </p>
        </div>
      </div>
    </CardLayout>
  );
};

export default TrainInfoIndicator;
