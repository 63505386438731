import defaultLogo from "assets/images/logo/logo.png";
import payboocLogo from "assets/images/logo/paybooc_logo.png";
import gmeLogo from "assets/images/logo/gme_logo.png";
import lottecardLogo from "assets/images/logo/lottecard_logo.png";
import hanacardLogo from "assets/images/logo/hanacard_logo.png";
import { ProviderUtils } from 'utils/providerUtils';

const getLogo = () => {
  if (ProviderUtils.isPaybooc) {
    return payboocLogo;
  }

  if (ProviderUtils.isLottecard) {
    return lottecardLogo;
  }

  if (ProviderUtils.isHanacard) {
    return hanacardLogo;
  }

  if (ProviderUtils.isGME) {
    return gmeLogo;
  }

  return defaultLogo;
};

const getLogoHeight = () => {
  if (ProviderUtils.isGME) {
    return 20;
  }
};

const getLogoWidth = () => {
  if (ProviderUtils.isGME) {
    return 74;
  }

  if (
    ProviderUtils.isHanacard ||
    ProviderUtils.isLottecard ||
    ProviderUtils.isPaybooc
  ) {
    return 120;
  }

  return 54;
};

const LogoImage = () => {
  return (
    <img
      src={getLogo()}
      alt="LACHA Logo"
      width={getLogoWidth()}
      height={getLogoHeight()}
      className="h-[55%]"
    />
  );
};

export default LogoImage;
