import { useCallback, useReducer } from "react";

const deepCopy = <T>(obj: T): T => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const deepCopiedObj: T = {} as T;

  for (const key in obj) {
    deepCopiedObj[key] = deepCopy(obj[key]);
  }
  return deepCopiedObj;
};

export interface FormType {
  [key: string]: string | boolean | undefined | FormType;
}

type ActionType<T extends FormType> =
  | {
      type: "CHANGE";
      name: keyof T;
      value?: T[keyof T];
    }
  | {
      type: "RESET";
      value: T;
    };

const reducer = <T extends FormType>(state: T, action: ActionType<T>): T => {
  switch (action.type) {
    case "CHANGE": {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case "RESET":
      return deepCopy(action.value);
    default:
      return state;
  }
};

const useInputs = (initialForm: FormType) => {
  const [form, dispatch] = useReducer(reducer, initialForm);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    dispatch({ type: "CHANGE", name, value: inputValue });
  }, []);

  const getSavedLoginId = useCallback((savedLoginId: string | null) => {
    dispatch({
      type: "CHANGE",
      name: "loginId",
      value: savedLoginId ? savedLoginId : "",
    });
  }, []);

  const reset = useCallback(
    (name?: keyof FormType) => {
      if (name) {
        dispatch({
          type: "CHANGE",
          name,
          value: initialForm[name],
        });
        return;
      }
      dispatch({
        type: "RESET",
        value: initialForm,
      });
    },
    [initialForm]
  );

  return { form, onChange, reset, getSavedLoginId };
};

export default useInputs;
