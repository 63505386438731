import LoadingSpinner from "components/LoadingSpinner";
import OrderStatusSection from "components/order/OrderStatusSection";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { OrderType } from "types/orderType";
import ProductInfoSection from "./history-detail-panel/sections/ProductInfoSection";
import RelatedProduct from "./history-detail-panel/sections/RelatedProduct";
import PaymentRefundInfoSection from "./history-detail-panel/sections/PaymentRefundInfoSection";
import LachaPointSection from "./history-detail-panel/sections/LachaPointSection";
import { useGetOrderListOfPaymentQuery } from "app/apiSlice";
import CardLayout from "components/layout/CardLayout";
import { getContextualUrl } from "utils/navigation";

interface OrderMainContentProps {
  reservationDetails?: OrderType;
}

const OrderMainContent = ({ reservationDetails }: OrderMainContentProps) => {
  const [searchParams] = useSearchParams();
  const orderIdQuery = searchParams.get("orderId");
  const orderIdParam = useParams().orderId;
  const orderId = orderIdParam || orderIdQuery;
  const paymentId = reservationDetails?.paymentInfo.paymentId;
  const navigate = useNavigate();

  const productType = reservationDetails?.productType;
  const isCancelled = reservationDetails?.orderStatus === "CANCELED";
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";

  const { data } = useGetOrderListOfPaymentQuery(paymentId!);
  const purchasedTogetherItems = data?.orderInfoList?.filter(
    (item) => Number(item.orderId) !== Number(orderId)
  );

  // Fetch reservation details from server
  // useEffect(() => {
  //   if (!reservationDetails) {
  //     if (orderId) {
  //       PaymentService.reserveDetail(Number(orderId))
  //         .then((data) => {
  //           setReservationDetails(data);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching reservation details:", error);
  //           navigate(-1);
  //         });
  //     }
  //   }
  // }, [navigate, orderId, reservationDetails]);

  return !reservationDetails ? (
    <LoadingSpinner />
  ) : (
    <div id={`image-to-be-saved-${orderId}`} className="space-y-3">
      <OrderStatusSection order={reservationDetails} />
      <ProductInfoSection order={reservationDetails} />

      {Boolean(reservationDetails.pointEarned) && (
        <LachaPointSection pointEarned={reservationDetails.pointEarned} />
      )}
      {productType === "TRAIN" && <RelatedProduct order={reservationDetails} />}

      {isPurchased && (
        <PaymentRefundInfoSection type="payment" order={reservationDetails} />
      )}
      {isCancelled && reservationDetails.refundInfo && (
        <PaymentRefundInfoSection type="refund" order={reservationDetails} />
      )}

      {purchasedTogetherItems && purchasedTogetherItems.length > 0 && (
        <CardLayout header={<p className="font-bold">같이 구매한 상품</p>}>
          {purchasedTogetherItems.map((item) => (
            <p
              key={item.orderId}
              className="overflow-hidden overflow-ellipsis text-nowrap text-sm"
              onClick={() =>
                // (window.location.href = getContextualUrl(
                //   `/order-details/${item.orderId}`
                // ))
                navigate(getContextualUrl(`/order-details/${item.orderId}`), {
                  replace: true,
                })
              }
            >
              {item.orderName}
            </p>
          ))}
        </CardLayout>
      )}
    </div>
  );
};

export default OrderMainContent;
