import axios, { AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
import TokenService, { ACCESS_TOKEN } from "./tokenService";
import { OrderType } from "types/orderType";
import { LanguageCodeType } from "const/languageCodes";
import { FormType } from "hooks/useInputs";
import UserService from "./userService";
import { SsoName } from "components/social_login/OAuthAdditionalInfo";
import { MethodType } from "pages/login-panel/FindPwPage";
import { withPaging } from "types/utilityTypes";

export type FindObjective = "findId" | "resetPw";

// interface SignupUserInfo {
//   provider: "Local" | "TravelWallet" | "Paybooc";
//   name: string;
//   loginId: string;
//   phone: string;
//   email: string;
//   pwHash: string;
//   isReceivingEmail: boolean;
//   isReceivingSms: boolean;
//   isReceivingPush: boolean;
//   isReceivingTalk: boolean;
// }

// export interface Credential {
//   [key: string]: Credential[keyof Credential];
//   loginId: string;
//   password: string;
//   remember?: boolean;
// }

export type AutoLoginProvider =
  | "LotteCard"
  | "HanaCard"
  | "Hanpass"
  | "GME"
  | "RedTable";
type DirectLoginProvider =
  | "Local"
  | "Paybooc"
  | "KTM"
  | "TravelWallet"
  | "Mame";

export interface Email {
  email: string;
  loginId: string;
  provider: AutoLoginProvider | DirectLoginProvider;
  ssoId: SsoName | null;
  ssoName: string | null;
}

export type MyOrder = withPaging<OrderType>;

// export interface MyOrder {
//   content: OrderType[];
//   totalPages: number;
//   first: boolean;
//   last: boolean;
//   number: number;

export interface UserState {
  [key: string]: UserState[keyof UserState];
  userId: number | null;
  name: string;
  loginId: string;
  phone: string;
  email: string;
  userStatus: string;
  joinDate: string;
  updateDate: string;
  certDate?: string;
  isReceivingEmail: boolean;
  isReceivingSms: boolean;
  isReceivingTalk: boolean;
  resignDate?: string;
  pwHash?: string;
  provider: string;
  language: LanguageCodeType;
  point: number;
  userType: "USER" | "ADMIN" | "SUPER_ADMIN";
  errorMessage?: string;
  birthDate: string | null;
}

// 인증을 위한 기본 axios 인스턴스 설정
const authApi = axios.create({
  baseURL: apiBaseUrl,
});
// // Axios 인터셉터 설정
// // Add a request interceptor
// authApi.interceptors.request.use(
//   (config) => {
//     const token = getStoredToken();
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
authApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

// 요청에 인증 토큰을 설정하는 함수
const setAuthToken = (token?: string | null) => {
  if (token != null) {
    // 로그인된 경우 모든 요청에 토큰 적용
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // 로그인되지 않은 경우 인증 헤더 삭제
    delete axios.defaults.headers.common["Authorization"];
  }
};

// 사용자 세션 관리를 위한 함수
// const handleUserSession = ({
//   token,
//   remember,
// }: {
//   token: string;
//   remember?: boolean;
// }) => {
//   // if (remember) {
//   //   // 장기 세션의 경우 토큰을 localStorage에 저장
//   //   // localStorage.setItem("token", token);
//   TokenService.setAccessToken(token);
//   // } else {
//   //   // 단기 세션의 경우 토큰을 sessionStorage에 저장
//   //   sessionStorage.setItem("token", token);
//   // }
//   setAuthToken(token);
// };

class AuthService {
  // 사용자 로그인 처리 함수
  // static async login(credentials: Credential): Promise<AxiosResponse> {
  //   const formData = new FormData();
  //   formData.append("loginId", credentials.loginId);
  //   formData.append("password", credentials.password);
  //   try {
  //     const response = await authApi.post("/user/login", formData, {
  //       withCredentials: true,
  //     });

  //     if (response.status === 201 && response.data.accessToken) {
  //       // 로컬 스토리지에 토큰 저장
  //       // TokenService.setAccessToken(response.data.accessToken);
  //       // 세션 관리를 위해 handleUserSession 호출
  //       handleUserSession({
  //         token: response.data.accessToken,
  //         remember: credentials.remember,
  //       });

  //       // 모든 요청에 토큰 자동 포함을 위해 setAuthToken 호출
  //       setAuthToken(response.data.accessToken);
  //     }
  //     return response;
  //   } catch (error) {
  //     // 에러 처리
  //     console.error("사용자 로그인 오류:", error);
  //     throw error;
  //   }
  // }

  // 사용자 로그아웃 처리 함수
  // static async logout(): Promise<undefined> {
  //   const accessToken = TokenService.getAccessToken(); // 액세스 토큰 가져오기

  //   try {
  //     await authApi.post(
  //       "/user/logout",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`, // 헤더에 액세스 토큰 포함
  //         },
  //       }
  //     );
  //     TokenService.removeAccessToken();

  //     // setAuthToken(false);
  //     delete axios.defaults.headers.common["Authorization"];
  //   } catch (error) {
  //     // 에러 처리
  //     console.error("Logout Failed: ", error);
  //     alert("Logout error");
  //     throw error;
  //   }
  // }

  // 사용자 회원탈퇴 함수
  static async withdraw() {
    const accessToken = TokenService.getAccessToken(); // 액세스 토큰 가져오기

    try {
      const response: AxiosResponse<string> = await authApi.post(
        "/user/withdraw",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // 헤더에 액세스 토큰 포함
          },
        }
      );
      // localStorage.removeItem("reservation");
      localStorage.removeItem(ACCESS_TOKEN);
      // localStorage.removeItem("user");

      // setAuthToken(false);
      delete axios.defaults.headers.common["Authorization"];
      return response.data;
    } catch (error) {
      // 에러 처리
      console.error("Account delete Failed: ", error);
      alert("Account delete error");
      // throw error;
    }
  }

  static async isLoggedIn(): Promise<boolean> {
    // Check if the token is present
    const token = TokenService.getAccessToken();
    if (!token) {
      return false;
    }

    // Verifying the token's validity or expiration
    try {
      // await UserService.getMyInfo(); // fetch 하는 data 크기 줄이기
      await UserService.getPoint();
      return true;
    } catch (error) {
      return false;
    }
  }

  // 사용자 등록 처리 함수
  // static async register(userData: FormData): Promise<AxiosResponse> {
  //   try {
  //     const response = await authApi.post("/user/signup", userData);
  //     return response;
  //   } catch (error) {
  //     // 에러 처리
  //     throw error;
  //   }
  // }

  // hanpass, gme register function
  static async registerWithToken(userData: FormData) {
    try {
      const response = await authApi.post("/user/signup", userData);
      if (response.status === 201 && response.data.accessToken) {
        // 로컬 스토리지에 토큰 저장
        TokenService.setAccessToken(response.data.accessToken);
        // 모든 요청에 토큰 자동 포함을 위해 setAuthToken 호출
        setAuthToken(response.data.accessToken);
      }
      return response;
    } catch (error) {
      // 에러 처리
      throw error;
    }
  }

  // 아이디 중복 확인 함수
  static async checkLoginIdAvailability(loginId: string): Promise<string> {
    try {
      const response: AxiosResponse<string> = await authApi.get(
        `/user/checkLoginId`,
        {
          params: { loginId },
        }
      );
      // Return true or false based on the API response
      // Assuming the API returns a boolean indicating availability
      return response.data;
    } catch (error) {
      // Handle errors (e.g., network issues, server errors)
      console.error("Error checking login ID availability:", error);
      throw error;
    }
  }

  // Reset user password
  static async resetPassword(token: string, newPw: string): Promise<string> {
    const accessToken = TokenService.getAccessToken(); // 액세스 토큰 가져오기

    try {
      const response: AxiosResponse<string> = await authApi.post(
        "/user/tryResetPw",
        { token, newPw },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // 헤더에 액세스 토큰 포함
          },
        }
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error resetting user password:", error);
      throw error;
    }
  }

  // 전화번호/이메일 인증 시작 함수
  static async startVerification(
    name: FormType[keyof FormType],
    id: FormType[keyof FormType],
    // phone: FormType[keyof FormType],
    // email: FormType[keyof FormType],
    objective: FindObjective,
    method: MethodType
  ): Promise<string> {
    if (!name) {
      throw new Error("Name is required for verification.");
    }
    if (!id) {
      throw new Error("ID is required for verification.");
    }
    // if (!phone && !email) {
    //   throw new Error("Either phone or email is required for verification.");
    // }
    if (!objective) {
      throw new Error("Objective is required for verification.");
    }

    const requestBody = {
      // userId: myinfo?.userId,
      name,
      // phone,
      email: id,
      objective,
      method,
      // loginId: myinfo?.loginId,
      // phone: phone || myinfo.phone,
      // email: email || myinfo.email,
      // userStatus: myinfo?.userStatus,
      // joinDate: myinfo?.joinDate,
      // updateDate: myinfo?.updateDate,
      // certDate: myinfo?.certDate,
      // isReceivingEmail: myinfo?.isReceivingEmail,
      // isReceivingSms: myinfo?.isReceivingSms,
      // isReceivingTalk: myinfo?.isReceivingTalk,
      // resignDate: myinfo?.resignDate,
      // pwHash: myinfo?.pwHash,
      // provider: myinfo?.provider,
      // language: myinfo?.language,
      // userType: myinfo?.userType,
    };

    try {
      const response: AxiosResponse<string> = await authApi.post(
        "/user/startVerify",
        requestBody
      );
      return response.data;
    } catch (error) {
      console.error("Error during the verification process:", error);
      throw error;
    }
  }

  // 전화번호/이메일 인증 시도 함수
  static async tryVerify(verificationDetails: {
    userId: string;
    code: FormType[keyof FormType];
  }): Promise<{ email: string }> {
    // const { uvId, userId, code, expiredAt } = verificationDetails;
    const { userId, code } = verificationDetails;
    const requestBody = {
      // uvId, // 인증 시도 ID
      userId: userId, // 사용자 ID
      code: code, // 인증 코드
      // expiredAt, // 만료 시간
    };
    try {
      const response = await authApi.post("/user/tryVerify", requestBody);
      return response.data;
    } catch (error) {
      console.error("전화번호/이메일 인증 시도 오류:", error);
      throw error;
    }
  }

  // 이메일 찾기 함수
  static async findEmail(name: string, phone: string): Promise<Email[]> {
    try {
      const response = await authApi.post("/user/findEmail", { name, phone });
      return response.data;
    } catch (error) {
      console.error("이메일 찾기 오류:", error);
      throw error;
    }
  }

  // naver login
  static async naverLogin(code: string, state: string) {
    try {
      const response = await authApi.get(
        `/naverLogin?code=${code}&state=${state}`
      );

      return response;
    } catch (error) {
      // if (isAxiosError(error)) {
      //   const userInfo = error.response?.data.userInfo;
      // }
      throw error;
    }
  }

  // kakao login
  static async kakaoLogin(kakaoId: string) {
    try {
      const response = await authApi.post(`/kakaoLogin?kakaoId=${kakaoId}`, {});
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // line login
  static async lineLogin(code: string, state: string) {
    try {
      const response = await authApi.get(
        `/lineLogin?code=${code}&state=${state}`
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  // Google login
  static async googleLogin(googleId: string) {
    try {
      const response = await authApi.post(
        `/googleLogin?googleId=${googleId}`,
        {}
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

// 앱 시작 시 setAuthToken을 즉시 호출하여 토큰 설정
setAuthToken(TokenService.getAccessToken());

export default AuthService;
