import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { deleteReservation } from "app/reservationSlice";
import { getContextualUrl } from "utils/navigation";

const CreditCancelPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get("errorMessage");

  const moveToOrderHistory = () => {
    navigate(getContextualUrl("/order-history"));
    // navigate(isJTRPage ? "/activity/order-history" : "/order-history");
  };

  useEffect(() => {
    // lotte card iframe이 있는 경우 닫기
    window.top?.closeLotteCancelIframe?.();

    // reservationSlice delete
    dispatch(deleteReservation());
  }, [dispatch]);

  return (
    <Layout onBack={moveToOrderHistory}>
      <div className="mx-auto min-h-[50dvh] w-full max-w-[320px]">
        <div className="mx-auto my-12 flex max-w-[280px] flex-col items-center justify-center gap-2 break-keep text-center">
          <CancelIcon color="error" className="!text-[120px]" />

          <p className="text-xl font-bold text-error">
            <FormattedMessage id="payment.cancel" />
          </p>

          {errorMessage && <p className="text-lg">취소 사유: {errorMessage}</p>}
        </div>
        <CustomButton
          id="payment.orderListButton"
          color="error"
          variant="outlined"
          onClick={moveToOrderHistory}
          style={{
            textTransform: "capitalize",
          }}
          className="!bg-white"
        />
      </div>
    </Layout>
  );
};

export default CreditCancelPage;
