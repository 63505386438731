/**
 *
 * @returns
 *
 * This action creator is used to log out the user.
 * It dispatches an action of type "USER_LOGOUT" to the Redux store.
 * This action is typically used to clear user data and reset the application state.
 *
 * It is used in rootReducer in store.ts to clear the persisted state when the user logs out.
 *
 */
export const logout = () => ({ type: "USER_LOGOUT" });
