import { twMerge } from "tailwind-merge";

interface CardLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  additionalInfo?: React.ReactNode;
  className?: string;
  // sx?: React.CSSProperties | SxProps;
  onClick?: (e?: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

/**
 * A reusable card layout component styled with Tailwind CSS.
 *
 * @param {React.ReactNode} props.children - The main content to be displayed inside the card.
 * @param {React.ReactNode} [props.header] - Optional header content to be displayed at the top of the card.
 * @param {React.ReactNode} [props.additionalInfo] - Optional additional information to be displayed at the bottom of the card.
 * @param {string} [props.className] - Additional Tailwind CSS classes to customize the card's appearance.
 * @param {() => void} [props.onClick] - Optional click handler for the card.
 *
 * @returns {JSX.Element} A styled card layout component.
 */
export default function CardLayout({
  children,
  header,
  additionalInfo,
  className,
  onClick,
  style,
}: CardLayoutProps) {
  return (
    <section
      className={twMerge(
        "space-y-2 overflow-hidden rounded-2xl border border-gray-200 bg-white p-4 shadow-md",
        className
      )}
      onClick={(e) => {
        // e.stopPropagation();
        onClick?.();
      }}
      style={style}
    >
      {header && (
        <>
          <div className="flex flex-row items-center">{header}</div>
          <div className="border-t border-gray-200"></div>
        </>
      )}
      <div className="w-full">{children}</div>
      {additionalInfo && (
        <>
          <div className="border-t border-gray-200"></div>
          <div className="flex flex-row items-center">{additionalInfo}</div>
        </>
      )}
    </section>
  );
}
