import PassengerCounter from "./PassengerCounter";
import DateInformation from "./DateInformation";
import CustomButton from "components/button/CustomButton";
import { useIntl } from "react-intl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAdultsNumber,
  getChildrenNumber,
  getURLSearchParams,
} from "utils/urlSearchParamsUtils";

interface PassengerSelectionPros {
  closeWindow: () => void;
}

/**
 * Component for selecting the number of passengers.
 *
 * @prop {() => void} closeWindow - Function to call when the component should be closed.
 *
 * @returns {JSX.Element}
 */
const PassengerSelection = ({ closeWindow }: PassengerSelectionPros) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [adults, setAdults] = useState(getAdultsNumber);
  const [children, setChildren] = useState(getChildrenNumber);
  const total = adults + children;

  const handleSubmit = () => {
    // Check if at least one adult is selected
    if (adults < 1) {
      alert(intl.formatMessage({ id: "passengerSelection.noAdultError" }));
      return;
    }

    const params = getURLSearchParams();

    params.set("adults", adults.toString());
    params.set("children", children.toString());

    navigate(`?${params.toString()}`);
    closeWindow();
  };

  return (
    <div className="mb-4 mt-16 space-y-2">
      <div className="flex gap-2">
        <PassengerCounter
          label="adults"
          count={adults}
          total={total}
          onIncrement={() => setAdults((prev) => ++prev)}
          onDecrement={() => setAdults((prev) => --prev)}
        />
        <PassengerCounter
          label="children"
          count={children}
          total={total}
          onIncrement={() => setChildren((prev) => ++prev)}
          onDecrement={() => setChildren((prev) => --prev)}
        />
      </div>
      <DateInformation />
      <CustomButton
        id="passengerSelection.confirmed"
        onClick={handleSubmit}
        disabled={adults + children > 9}
      />
    </div>
  );
};

export default PassengerSelection;
