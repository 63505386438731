import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import CustomButton from "components/button/CustomButton";
import SettingsIcon from "@mui/icons-material/Settings";
import PaidIcon from "@mui/icons-material/Paid";
import IosShareIcon from "@mui/icons-material/IosShare";
import CardBalance from "./components/CardBalance";
import { CardContext } from "./CardPageLayout";
import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import CardLayout from "components/layout/CardLayout";
import { getURLSearchParams } from "utils/urlSearchParamsUtils";

const useScrollRestoration = () => {
  const { pathname } = useLocation();
  const scrollY = useRef(0);

  useEffect(() => {
    window.scrollTo(0, scrollY.current);

    return () => {
      scrollY.current = window.scrollY;
    };
  }, [pathname]);
};

const CardDetail = () => {
  useScrollRestoration();
  const navigate = useNavigate();
  const { currentCard, resetCarousel } = useOutletContext<CardContext>();
  const {
    iapCdno: cardNumber,
    // acnRmd: balance
    iapCrdStcd: status,
  } = currentCard || {};
  const isActive = status === "01";

  useEffect(() => {
    if (!isActive) {
      navigate("/card/settings");
    }
  }, [isActive, navigate]);

  const pathname = window.location.pathname;
  const lastUrlSegment = pathname.slice(pathname.lastIndexOf("/") + 1);

  return (
    cardNumber && (
      <div className="space-y-2">
        {/* <ScrollRestoration /> */}
        <CardLayout
          header={<CardBalance />}
          // header={<CardBalance balance={balance} />}
        >
          <div className="flex gap-2">
            <CustomButton
              size="medium"
              onClick={() => navigate("deposit")}
              variant={lastUrlSegment === "deposit" ? "contained" : "outlined"}
              disabled={!isActive}
            >
              <div className="flex min-h-20 flex-col items-center justify-center gap-1 py-2">
                <PaidIcon fontSize="medium" />
                <p className="text-sm font-bold">
                  <FormattedMessage id="card.depositWithdraw" />
                </p>
              </div>
            </CustomButton>
            <CustomButton
              size="medium"
              onClick={() => navigate("transfer")}
              variant={lastUrlSegment === "transfer" ? "contained" : "outlined"}
              disabled={!isActive}
            >
              <div className="flex min-h-20 flex-col items-center justify-center gap-1 py-2">
                <IosShareIcon
                  fontSize="medium"
                  sx={{
                    transform: "rotate(90deg)",
                  }}
                />
                <p className="text-sm font-bold">
                  <FormattedMessage id="card.transfer" />
                </p>
                {/* <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Transfer Balance
                </Typography> */}
              </div>
            </CustomButton>
            <CustomButton
              size="medium"
              onClick={() => navigate("settings")}
              variant={lastUrlSegment === "settings" ? "contained" : "outlined"}
            >
              <div className="flex min-h-20 flex-col items-center justify-center gap-1 py-2">
                <SettingsIcon
                  fontSize="medium"
                  sx={{ justifyItems: "start" }}
                />
                <p className="text-sm font-bold">
                  <FormattedMessage id="card.settings" />
                </p>
              </div>
            </CustomButton>
          </div>
        </CardLayout>
        {lastUrlSegment !== "card" && (
          <CardLayout>
            <div className="w-full">
              <Outlet context={{ currentCard, resetCarousel }} />
            </div>
          </CardLayout>
        )}
      </div>
    )
  );
};

export default CardDetail;
