import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { feature } from "topojson-client";
import { Topology } from "topojson-specification";
// Load the TopoJSON data
import japan from "assets/maps/japan.json";
import Spring from "assets/images/usj/map_spring.webp";
import Summer from "assets/images/usj/map_summer.webp";
import Autumn from "assets/images/usj/map_autumn.webp";
import Winter from "assets/images/usj/map_winter.webp";

import { useMediaQuery } from "@mui/system";
import { useNavigate } from "react-router-dom";
import OkinawaMap from "./OkinawaMap";
import Carousel from "react-multi-carousel";
import clsx from "clsx";

const iconsInfo = [
  // {
  //   name: "오키나와\nOKINAWA",
  //   lat: "27.2344",
  //   lon: "128.3056",
  //   color: "#9763A4",
  //   xOffset: 8,
  //   yOffset: -28,
  //   href: "okinawa",
  // },
  {
    name: "큐슈\nKYUSHU",
    lat: "32.5900",
    lon: "130.7000",
    // color: "#6BB35F",
    color: "#E82300",
    xOffset: -24,
    yOffset: -88,
    href: "kyushu",
  },
  {
    name: "오사카/교토/나라(관서)\nOSAKA/KYOTO/NARA",
    lat: "34.1413",
    lon: "135.5629",
    // color: "#D38161",
    color: "#E82300",
    xOffset: -48,
    yOffset: 14,
    href: "kansai",
  },
  {
    name: "도쿄\nTOKYO",
    lat: "36.1764",
    lon: "139.6500",
    // color: "#C60200",
    color: "#E82300",
    xOffset: 24,
    yOffset: -60,
    href: "tokyo",
  },
  {
    name: "홋카이도\nHOKKAIDO",
    lat: "43.1203",
    lon: "142.4635",
    // color: "#6494A7",
    color: "#E82300",
    xOffset: -96,
    yOffset: -40,
    href: "hokkaido",
  },
];

// interface MapProps {
// data: Topology;
// objectKey: string; // The key to access geometry in TopoJSON objects
// width?: number;
// height?: number;
// fillColor?: string;
// strokeColor?: string;
// strokeWidth?: number;
// }

const images = [Spring, Summer, Autumn, Winter];

const JapanMap = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:512px)");

  // svg를 그릴 엘리먼트 설정을 위한 ref
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    // Load the TopoJSON data

    const drawMap = async () => {
      // Clear any existing content
      d3.select(svgRef.current).selectAll("*").remove();

      // Convert TopoJSON to GeoJSON
      // topojson-client’s feature function converts TopoJSON objects into GeoJSON
      const geojson = feature(
        japan as unknown as Topology,
        japan.objects["japan"] as any
      );

      const getSize = () => {
        if (window.screen.width < 512) {
          return [window.screen.width - 32, window.screen.width - 32] as [
            number,
            number,
          ];
        } else {
          return [480, 480] as [number, number];
        }
      };

      // Create projection (지도 투영 설정 - Mercator)
      // const projection = d3.geoMercator().fitSize([width, height], geojson);
      const projection = d3
        .geoMercator()
        .center([138.5, 38.2]) // 일본 중심 좌표
        .scale(matches ? 1200 : 950) // 확대/축소 정도
        .translate(getSize().map((d) => d / 2) as [number, number]);

      // Create path generator
      const path = d3.geoPath().projection(projection);

      // Create SVG group
      // d3.select(svgRef.current) binds D3 to the React SVG element
      const svg = d3
        .select(svgRef.current)
        .attr("width", getSize()[0])
        .attr("height", getSize()[1]);

      // 지도 바탕 추가 (단순히 SVG 사각형으로 처리)
      // svg
      //   .append("rect")
      //   .attr("width", getSize()[0])
      //   .attr("height", getSize()[1])
      //   .attr("fill", "#cce5ff");

      // Draw map
      svg
        .selectAll("path")
        .data((geojson as any).features)
        .enter()
        .append("path")
        .attr("d", path as any)
        // .attr("vector-effect", "non-scaling-stroke")
        .attr("fill", "#FFFD")
        .attr("stroke", "#0001")
        .attr("stroke-width", 0.5);

      // 마커 추가 - 마커를 나중에 추가해야 지도 위에 표시됨
      svg
        .selectAll("g") //   .selectAll("circle")
        .data(iconsInfo)
        .join("g") //   .join("circle")
        .attr("transform", (d) => {
          const [x, y] = projection([+d.lon, +d.lat]) || [0, 0];
          return `translate(${x}, ${y})`;
        })
        .attr("cursor", "pointer")
        .each(function (d) {
          const group = d3.select(this);
          const href = d.href;

          // 마커 핀
          group
            .append("path")
            .attr(
              "d",
              `M0,-30  
              A15,15 0 1,1 0,-29.85
              L24,-20
              L15,-2
              L6,-20
              Z`
            ) // 핀 모양 (위쪽 원과 꼬리)
            // .attr(
            //   "d",
            //   `M0,-20
            //    A10,10 0 1,1 0,-19.9
            //    L17,-14
            //    L10,0
            //    L3,-14
            //    Z`
            // ) // 핀 모양 (위쪽 원과 꼬리)
            .attr("fill", d.color)
            // .attr("stroke", "black")
            .attr("stroke", d.color)
            .attr("stroke-width", 1)
            .attr("transform", " translate(-10, 0)");

          // 원형 내부
          group
            .append("circle")
            .attr("cx", 5)
            .attr("cy", -30)
            .attr("r", 7.5)
            .attr("fill", "white");

          const textWidth =
            9.5 *
            Math.max(
              ...d.name.split("\n").map((str) => str.replaceAll("/", "").length)
            );
          const textHeight = 40;

          // group
          //   .append("rect")
          //   .attr("x", d.xOffset) // text의 x값보다 약간 작게
          //   .attr("y", d.yOffset) // text의 y값보다 약간 작게
          //   .attr("width", textWidth) // 텍스트 길이에 따라 조정 필요
          //   .attr("height", textHeight) // 두 줄의 텍스트를 감싸도록 설정
          //   .attr("fill", "#FFF9")
          //   .attr("stroke", "#333") // 테두리 색상
          //   .attr("stroke-width", 1) // 테두리 두께
          //   .attr("rx", 8) // 모서리 둥글게
          //   .attr("ry", 8);

          // 텍스트 레이블 (한글)
          group
            .append("text")
            .attr("x", d.xOffset + textWidth / 2)
            .attr("y", d.yOffset + textHeight / 3)
            .attr("text-anchor", "middle") // 수평 가운데 정렬
            .attr("dominant-baseline", "middle") // 수직 가운데 정렬
            .attr("font-size", "11px")
            .attr("font-weight", "bold")
            .attr("fill", "#FFF") // 텍스트 색상
            .text(d.name.split("\n")[0]); // 한글 이름

          // 텍스트 레이블 (영문)
          group
            .append("text")
            .attr("x", d.xOffset + textWidth / 2)
            .attr("y", 15 + d.yOffset + textHeight / 3)
            .attr("text-anchor", "middle") // 수평 가운데 정렬
            .attr("dominant-baseline", "middle") // 수직 가운데 정렬

            //   .attr("y", (d) => {
            //     const [, y] = projection([+d.lon, +d.lat]) || [0, 0];
            //     return y + 4; // 마커 위치와 높이를 맞춤
            //   })
            .attr("font-size", "11px")
            .attr("font-weight", "bold")
            .attr("fill", "#FFF") // 텍스트 색상
            .text(d.name.split("\n")[1]); // 영어 이름

          group.on("click", () => {
            navigate(`category/${href}`);
          });

          group
            .on("touchstart mousedown", function () {
              d3.select(this).select("rect").attr("fill", "#FFF5");
              d3.select(this)
                .select("text") // 그룹 내부의 레이블 선택
                .attr("fill", "red"); // 색상 변경
            })
            .on("touchend mouseup", function () {
              d3.select(this).select("rect").attr("fill", "#FFF9");
              d3.select(this)
                .select("text") // 그룹 내부의 레이블 선택
                .attr("fill", "#333"); // 원래 색상으로 복원
            });
        });

      // // 마커 추가
      // svg
      //   .selectAll("circle")
      //   .data(iconsInfo)
      //   .join("circle")
      //   .attr("cx", (d) => {
      //     const [x] = projection([+d.lon, +d.lat]) || [0, 0];
      //     return x;
      //   })
      //   .attr("cy", (d) => {
      //     const [, y] = projection([+d.lon, +d.lat]) || [0, 0];
      //     return y;
      //   })
      //   .attr("r", 5)

      // Optional: Add zoom behavior
      // const zoom = d3
      //   .zoom()
      //   .scaleExtent([1, 8])
      //   .on("zoom", (event) => {
      //     svg.selectAll("path").attr("transform", event.transform);
      //   });

      // svg.call(zoom as any);
    };

    drawMap();
    window.addEventListener("resize", drawMap);
    return () => {
      window.removeEventListener("resize", drawMap);
    };
  }, [matches, navigate]);

  return (
    <div className="relative rounded-xl bg-black bg-opacity-50">
      <Carousel
        responsive={{
          desktop: {
            breakpoint: {
              max: 5000,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 40,
          },
        }}
        arrows={false}
        autoPlay
        autoPlaySpeed={10000}
        infinite
        rewindWithAnimation
        className="relative max-w-lg self-start rounded-xl"
      >
        {images.map((image) => (
          <div className="relative h-[100vw] max-h-[480px] w-full" key={image}>
            <img
              key={image}
              src={image}
              alt={image}
              className="!h-full w-full"
            />
            {/* Black translucent overlay */}
            {/* <div className="absolute inset-0 bg-black bg-opacity-60" /> */}
          </div>
        ))}
      </Carousel>
      <div className="absolute top-0 transform">
        <svg ref={svgRef} className="rounded-xl"></svg>
        <div
          className={clsx("absolute right-2", {
            "-bottom-4": !matches,
            "bottom-2": matches,
          })}
        >
          <OkinawaMap />
        </div>
      </div>
    </div>
  );
};

export default JapanMap;
