import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LanguageCodeType } from "const/languageCodes";
import { UserState } from "services/authService";
import { ProviderUtils } from "utils/providerUtils";
import { StorageUtils } from "utils/storageUtils";

const setInitialLanguage = () => {
  if (ProviderUtils.isMame) {
    return "ja";
  }

  if (ProviderUtils.isGME) {
    return "en";
  }

  return (StorageUtils.Language.get() || "ko") as LanguageCodeType;
};

const initialState: Readonly<UserState> = {
  userId: null, // number
  name: "",
  loginId: "",
  phone: "",
  email: "",
  userStatus: "",
  joinDate: "",
  updateDate: "",
  // certDate: null,
  isReceivingEmail: false,
  isReceivingSms: false,
  isReceivingTalk: false,
  // resignDate: null,
  // pwHash: null,
  provider: "",
  point: 0, // default 0 point
  language: setInitialLanguage(),
  userType: "USER",
  // errorMessage: null,
  birthDate: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUser: (state, action: PayloadAction<UserState>) => {
      // for (const key in action.payload) {
      //   state[key] = action.payload[key];
      // }
      // state = { ...action.payload };
      return { ...state, ...action.payload };
    },
    updateUser: (state, action: PayloadAction<Partial<UserState>>) => {
      for (const key in action.payload) {
        const value = action.payload[key];
        if (value) {
          state[key] = value;
        }
      }
    },
    changeLanguage: {
      reducer: (state, action: PayloadAction<LanguageCodeType>) => {
        state.language = action.payload;
      },
      prepare: (lang: LanguageCodeType): { payload: LanguageCodeType } => {
        return {
          payload: lang,
        };
      },
    },
    deleteUser: (state) => {
      const language = state.language;
      return { ...initialState, language };
      // return initialState;
    },
    updatePoint(state, action: PayloadAction<number>) {
      state.point = action.payload;
    },
    // updatePoint: {
    //   reducer: (
    //     state,
    //     action: PayloadAction<{
    //       point: number;
    //       status: PointStatus;
    //     }>
    //   ) => {
    //     switch (action.payload.status) {
    //       case "EARN":
    //         state.point = state.point + action.payload.point;
    //         break;
    //       case "USED":
    //         state.point = state.point - action.payload.point;
    //         break;
    //       case "EXPIRED":
    //         state.point = state.point - action.payload.point;
    //         break;
    //       case "USED_CANCELLED":
    //         state.point = state.point + action.payload.point;
    //         break;
    //     }
    //   },
    //   prepare: (
    //     point: number,
    //     status: PointStatus
    //   ): {
    //     payload: {
    //       point: number;
    //       status: PointStatus;
    //     };
    //   } => {
    //     return {
    //       payload: {
    //         point,
    //         status,
    //       },
    //     };
    //   },
    // },
  },
});

export const {
  fetchUser,
  updateUser,
  changeLanguage,
  deleteUser,
  updatePoint,
} = userSlice.actions;

// export const { selectUser } = userSlice.selectors;

export default userSlice.reducer;

/* 
- userSlice, cardsSlice, paymentSlice, reservationSlice, …
- user, card, payment 등 각각의 관심사 별로 redux slice를 분리하고 actions과 reducer를 
*/
