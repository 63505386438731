import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import React from "react";
import { useIntl } from "react-intl";

interface TicketHeaderProps {
  dateTime: string;
  qrCode: React.ReactNode;
}

const TicketHeader = ({ dateTime, qrCode }: TicketHeaderProps) => {
  const intl = useIntl();
  const formatDateTime = useFormatDateToLocaleString();

  return (
    <div className="flex w-full flex-nowrap items-center justify-between gap-4">
      <div className="space-y-1">
        <p className="text-sm font-bold leading-[0.9]">
          {intl.formatMessage({ id: "trainMain.dateOfDeparture" })}
        </p>
        <p className="leading-6">
          {dateTime} (
          {formatDateTime(dateTime, {
            weekday: "short",
          })}
          )
        </p>
      </div>
      <div>{qrCode}</div>
    </div>
  );
};

export default TicketHeader;
