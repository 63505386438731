import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { deleteMyOrders } from "app/myOrdersSlice";
import { updatePoint } from "app/userSlice";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PaymentService, {
  CancelFeeCheckResponse,
} from "services/paymentService";
import UserService from "services/userService";
import { OrderType } from "types/orderType";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "components/button/CustomButton";
import LoadingSpinner from "components/LoadingSpinner";
import CancelInfoSummarySection from "pages/ktx/order-history-panel/order-cancel-panel/CancelInfoSummarySection";
import CancelTermSection from "pages/ktx/order-history-panel/history-detail-panel/sections/CancelTermSection";
import CardLayout from "components/layout/CardLayout";
import useModalSheet from "./overlay/useModalSheet";

const useCancelReservation = (reservationDetails: OrderType | null) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const productType = reservationDetails?.productType;
  const [isCancelProcessing, setIsCancelProcessing] = useState(false);
  const [cancelFeeDetails, setCancelFeeDetails] =
    useState<CancelFeeCheckResponse>();

  useEffect(() => {
    if (reservationDetails && reservationDetails.reserveStatus === "COMPLETE") {
      PaymentService.cancelFeeCheck(reservationDetails.orderId)
        .then((data) => {
          setCancelFeeDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching cancel fee check details:", error);
        });
    }
  }, [reservationDetails]);

  const handleCancelClick = async () => {
    // let conf = window.confirm(
    //   intl.formatMessage({ id: "orders.cancelConfirm" })
    // );
    // if (!conf) {
    //   return;
    // }

    setIsCancelProcessing(true);

    if (reservationDetails) {
      PaymentService.cancelReservation(reservationDetails.orderId)
        .then(() => {
          // if (data.result === "SUCCESS") {
          alert(intl.formatMessage({ id: "orders.cancelComplete" }));
          dispatch(deleteMyOrders());
          UserService.getPoint().then((point) => {
            dispatch(updatePoint(point));
          });

          if (window.location.pathname === "/hanacard/reservations") {
            window.location.reload();
            // navigate("/hanacard/reservations");
            return;
          }
          navigate(`/order-history`);
        })
        .catch((error) => {
          alert("주문 취소가 실패했습니다");
          console.error(error);
          // alert("취소 과정 중 오류가 발생했습니다: " + error);
        })
        .finally(() => {
          setIsCancelProcessing(false);
        });
    }
  };

  const [CancelModal, setCancelModalVisible] = useModalSheet({
    callbackOk: handleCancelClick,
    callbackCancel: () => {
      setCancelModalVisible(false);
    },
  });

  const CancelDetails = (
    // cancelFeeDetails &&
    <CardLayout className="py-1">
      <CancelModal modal cancellable>
        <p className="text-black">
          {intl.formatMessage({ id: "orders.cancelConfirm" })}
        </p>
      </CancelModal>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          "& .MuiButtonBase-root": { px: 0 },
          "& .MuiAccordionSummary-content": { my: 0 },
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="common.voucherUsage"
          id="common.voucherUsage"
          sx={{
            minHeight: "36px",
          }}
        >
          <p className="font-bold text-error">
            <FormattedMessage id="orders.cancelButton" />
          </p>
        </AccordionSummary>
        <AccordionDetails className="">
          <div>
            {productType === "TRAIN" && cancelFeeDetails && (
              <>
                <CancelInfoSummarySection cancelFee={cancelFeeDetails} />
                <Divider />
                <CancelTermSection />
              </>
            )}
            <CustomButton
              color="error"
              size="medium"
              onClick={() => setCancelModalVisible(true)}
              disabled={isCancelProcessing}
            >
              <FormattedMessage id="orders.cancelButton" />
            </CustomButton>
          </div>
        </AccordionDetails>
      </Accordion>
      {isCancelProcessing && <LoadingSpinner overlap />}
    </CardLayout>
  );

  return [CancelDetails, handleCancelClick] as const;
};

export default useCancelReservation;
