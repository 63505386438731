/**
 * Constructs a contextual URL based on the current page's path.
 * This function is mostly used in hanacard jtr-related pages to prevent navigating to ktx-related pages.
 *
 * @param baseUrl - The base URL to be appended or returned as is.
 * @param prefixPath - The prefix path to check against the current page's pathname. Defaults to "/activity".
 * @returns The contextual URL. If the current page's pathname starts with the `prefixPath`,
 *          the returned URL will be the `prefixPath` concatenated with the `baseUrl`.
 *          Otherwise, it will return the `baseUrl` as is.
 */
export const getContextualUrl = (baseUrl: string, prefixPath = "/activity") => {
  const isJTRPage = window.location.pathname.startsWith(prefixPath);
  return isJTRPage ? `${prefixPath}${baseUrl}` : baseUrl;
};

export const isJTRPage = window.location.pathname.startsWith("/activity");
