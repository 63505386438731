// export const PaymentMethod = {
//   CREDIT: "Credit",
//   HANPASS: "Hanpass",
// };

export enum PaymentMethod {
  CREDIT = "Credit",
  INICIS = "Inicis",
  CORPORATE = "KeyIn",
  OVERSEAS = "Overseas",
  HANPASS = "Hanpass",
  GME = "GME",
  TRANSFER = "Transfer",
  PHONE = "Phone",
  KTC = "KTC",
}

// export type PaymentMethodValue = `${PaymentMethod}`;
