/**
 * USJ related activity reservation context
 *
 * This module defines the context and types related to usj activity reservations.
 * It provides a context for managing reservation details such as booker information,
 * travel date, product list, and passenger details.
 */

import React, { createContext } from "react";
import { TravelReservationProduct } from "services/travelService";

/**
 * Represents a passenger with a name and email.
 */
export interface JTRPassenger {
  name: string;
  email: string;
}

/**
 * Defines the context type for activity reservations.
 */
interface ActivityReservationContextType {
  bookerInfo: {
    name: string;
    email: string;
    phone: string;
  };
  setBookerInfo: React.Dispatch<
    React.SetStateAction<{
      name: string;
      email: string;
      phone: string;
    }>
  >;

  travelDate: string;
  setTravelDate: React.Dispatch<React.SetStateAction<string>>;
  productList: TravelReservationProduct[];
  setProductList: React.Dispatch<
    React.SetStateAction<TravelReservationProduct[]>
  >;
  number: number;
  setNumber: React.Dispatch<React.SetStateAction<number>>;

  passengerDetails: JTRPassenger[];
  setPassengerDetails: React.Dispatch<React.SetStateAction<JTRPassenger[]>>;
  isPassengersValid: boolean;
  setIsPassengersValid: React.Dispatch<React.SetStateAction<boolean>>;

  passengersDetailRef: React.RefObject<HTMLDivElement>;
}

/**
 * Context for activity reservations.
 */
export const ActivityReservationContext =
  createContext<ActivityReservationContextType>(null!);

