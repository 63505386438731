import { Grid, Skeleton, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import { Coupon } from "utils/reservationUtils";
import useLanguages from "hooks/useLanguages";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";

interface PriceSummarySectionProps {
  price?: OrderType;
  selectedCoupon?: Coupon;
  isLoading: boolean;
}

const PriceSummarySection = ({
  price,
  selectedCoupon,
  isLoading,
}: PriceSummarySectionProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  const ktxPrice = price?.trainOriginalPrice ?? 0;
  const kakaoPrice = selectedCoupon?.saleAmount ?? 5000;
  const discount = ktxPrice + kakaoPrice - (price?.totalPrice ?? 0);
  const pointEarned = price?.pointEarned ?? 0;
  const totalCommission = price?.totalCommission ?? 0;
  const totalPrice = price?.totalPrice ?? 0;

  // Adjusting the total and final price if a coupon is applied
  // if (selectedCoupon) {
  //   totalPrice = totalPrice + selectedCoupon.saleAmount;
  //   finalPrice = finalPrice + selectedCoupon.saleAmount;
  // }

  const getHeader = () => {
    return (
      <p className="font-bold">
        {intl.formatMessage({ id: "payment.paymentAmount" })}
      </p>
    );
  };

  const getAdditionalInfo = () => {
    return (
      // 예상 적립금
      pointEarned > 0 && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <p>{intl.formatMessage({ id: "payment.estimatedPoint" })}</p>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            {isLoading ? (
              <Skeleton variant="text" height={24} />
            ) : (
              <p>{formatPrice(pointEarned)}</p>
            )}
          </Grid>
          {isKorean && (
            <p className="ml-3 mt-1 text-sm text-text-secondary">
              - 기차표 예매 시 라차 적립금을 제공하며 다음 예매 시 사용
              가능합니다
            </p>
          )}
        </Grid>
      )
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      {/* 열차 금액 */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <p>KTX {intl.formatMessage({ id: "orders.price" })}</p>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end" }}>
          {isLoading ? (
            <Skeleton variant="text" height={24} />
          ) : (
            <p>{formatPrice(ktxPrice)}</p>
          )}
        </Grid>
      </Grid>

      {/* 카카오 이용권 금액 */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          {/* <p>{intl.formatMessage({ id: "payment.combinedAmount" })}</p> */}
          <p>{isKorean ? "카카오 T 이용권" : "Kakao T coupon"}</p>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end" }}>
          {isLoading ? (
            <Skeleton variant="text" height={24} />
          ) : (
            <p>{formatPrice(kakaoPrice)}</p>
          )}
        </Grid>
      </Grid>

      {/* 할인 금액 */}
      {discount > 0 && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            {/* <p>{intl.formatMessage({ id: "payment.combinedAmount" })}</p> */}
            <p>{isKorean ? "할인 금액" : "Discount"}</p>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            {isLoading ? (
              <Skeleton variant="text" height={24} />
            ) : (
              <p className="text-error">{`-${formatPrice(discount)}`}</p>
            )}
          </Grid>
        </Grid>
      )}

      {/* 수수료 */}
      {totalCommission > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item xs={8}>
            <p>{intl.formatMessage({ id: "payment.commission" })}</p>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <p>{formatPrice(totalCommission)}</p>
          </Grid>
        </Grid>
      )}

      {/* 결제 예정금액 */}
      <Grid container justifyContent="space-between" alignItems="end">
        <Grid item xs={7}>
          <p className="text-xl font-bold">
            {intl.formatMessage({ id: "payment.expectedPaymentAmount" })}
          </p>
        </Grid>
        <Grid item xs={5} sx={{ textAlign: "end" }}>
          {isLoading ? (
            <Skeleton variant="text" height={28} />
          ) : (
            <p className="text-xl font-bold text-error">
              {formatPrice(totalPrice)}
            </p>
          )}
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default PriceSummarySection;
