import { Grid, Modal, Stack } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import { forwardRef, memo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useLanguages from "../useLanguages";
import { ProviderUtils } from "utils/providerUtils";
import { GME_BOTTOM_PADDING } from "const/layout";
import { getMobileOperatingSystem } from "utils/osUtils";
import CardLayout from "components/layout/CardLayout";

type BaseCustomBottomSheetProps = {
  children: React.ReactNode;
  variant?: "button" | "icon"; // button - 확인/취소 버튼 있는 모달, icon - 취소 아이콘 있는 모달
  height?: string;
  modal?: boolean;
  okText?: string | React.ReactElement;
  // cancellable?: boolean;
  // cancelText?: string;
  cover?: boolean;
  title?: string;
  buttonDirection?: "row" | "column";
};

type CustomBottomSheetProps = BaseCustomBottomSheetProps &
  (
    | {
        cancellable: true;
        cancelText?: string;
      }
    | {
        cancellable?: false;
        cancelText?: never;
      }
  );

interface useModalSheetProps {
  defaultVisible?: boolean;
  callbackOk?: () => void; // modal 확인 버튼 클릭 시 실행할 콜백
  callbackCancel?: () => void; // modal 취소 버튼 클릭 시 실행할 콜백
}

/**
 * Modal이나 bottomSheet 보여주는 component
 * 현재는 BottomSheet나 Modal을 사용할 때마다, component 안에서 hook에서 return하는 component를 JSX에 추가해주어야한다.
 *
 * TODO: react createPortal 사용하도록 수정하기
 *
 */
const useModalSheet = ({
  defaultVisible = false,
  callbackOk,
  callbackCancel,
}: useModalSheetProps = {}) => {
  const { isKorean } = useLanguages();
  const [visible, setVisible] = useState(defaultVisible);

  const CustomBottomSheet = memo(
    ({
      children,
      variant = "button",
      modal,
      cancellable = false,
      okText = isKorean ? "확인" : "Ok",
      cancelText = isKorean ? "취소" : "Cancel",
      cover = false,
      title,
      buttonDirection = "row",
    }: CustomBottomSheetProps) => {
      const hideModalSheet = () => {
        setVisible(false);
      };

      const handleOk = () => {
        callbackOk?.();
        hideModalSheet();
      };

      const handleCancel = () => {
        callbackCancel?.();
        hideModalSheet();
      };

      const ModalContent = forwardRef<HTMLDivElement>((props, ref) => {
        const os = getMobileOperatingSystem();
        /* 실제 모달 content */
        const cardStyle = modal
          ? ({
              width: "clamp(280px, 80vw, 440px)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            } as const)
          : ({
              width: "100vw",
              position: "fixed",
              bottom: `calc(env(safe-area-inset-bottom))`,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              maxHeight: cover
                ? `calc(100dvh - env(safe-area-inset-top) - ${0}px)`
                : "80dvh",
              pb: `${ProviderUtils.isGME && os === "iOS" ? GME_BOTTOM_PADDING : 8}px`,
            } as const);

        return (
          <div {...props} ref={ref}>
            {visible && (
              <CardLayout
                className="overflow-auto"
                style={{
                  ...cardStyle,
                }}
              >
                {modal
                  ? variant === "icon" && (
                      <CloseIcon
                        onClick={handleCancel}
                        className="fixed right-4 z-10"
                      />
                    )
                  : (title || variant === "icon") && (
                      <div className="fixed z-10 flex h-14 w-full -translate-x-4 -translate-y-4 items-center justify-center rounded-t-xl bg-white shadow-md">
                        {title && (
                          <p className="w-[80%] text-center font-bold">
                            {title}
                          </p>
                        )}
                        {variant === "icon" && (
                          <CloseIcon
                            onClick={handleCancel}
                            className="fixed right-4 z-10"
                          />
                        )}
                      </div>
                    )}
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{ position: "relative" }}
                >
                  <Grid
                    item
                    xs
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      wordBreak: "keep-all",
                      position: "relative",
                    }}
                  >
                    {children}
                  </Grid>
                  {variant === "button" && (
                    <Grid item xs={1} gridAutoRows="1fr">
                      <Stack
                        direction={buttonDirection}
                        justifySelf="flex-end"
                        sx={{
                          mt: 2,
                          width: "100%",
                        }}
                        gap={1}
                      >
                        {cancellable && buttonDirection === "row" && (
                          <CustomButton
                            type="button"
                            variant="outlined"
                            color="error"
                            size="medium"
                            onClick={handleCancel}
                            sx={{ width: "100%" }}
                          >
                            {cancelText}
                          </CustomButton>
                        )}
                        <CustomButton
                          type="button"
                          size="medium"
                          onClick={handleOk}
                          sx={{ width: "100%" }}
                        >
                          {okText}
                        </CustomButton>
                        {cancellable && buttonDirection === "column" && (
                          <CustomButton
                            type="button"
                            variant="outlined"
                            color="error"
                            size="medium"
                            onClick={handleCancel}
                            sx={{ width: "100%" }}
                          >
                            {cancelText}
                          </CustomButton>
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </CardLayout>
            )}
          </div>
        );
      });

      return (
        <Modal
          open={visible}
          onClose={handleCancel}
          aria-labelledby="modal"
          // aria-describedby="modal-modal-description"
          sx={{ zIndex: 100000001 }}
        >
          <ModalContent />
        </Modal>
      );
    }
  );

  return [CustomBottomSheet, setVisible] as const;
};

export default useModalSheet;
