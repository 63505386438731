import { Grid, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";
import type { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";

interface OrderSummarySectionProps {
  totalPrice: number;
  pointEarned?: number;
  reservationDetails: OrderType[];
  point: number;
  hanpassCouponDiscountAmount?: number;
}

const OrderSummarySection = ({
  reservationDetails,
  totalPrice,
  point,
  hanpassCouponDiscountAmount = 0,
}: OrderSummarySectionProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const formatPrice = useFormatPrice();

  // data
  const getHeader = () => {
    return (
      <p className="font-bold">
        {intl.formatMessage({ id: "payment.priceInfo" })}
      </p>
    );
  };

  const productType = reservationDetails[0]?.productType;

  const totalActivityPrice =
    reservationDetails?.reduce((acc, reservation) => {
      return acc + reservation.totalPrice;
    }, 0) ?? 0;
  const totalKTXPrice =
    reservationDetails?.reduce((acc, reservation) => {
      return acc + reservation.trainOriginalPrice;
    }, 0) ?? 0;
  const pointEarned = reservationDetails?.reduce((acc, reservation) => {
    return acc + (reservation.pointEarned ?? 0);
  }, 0);
  const kakaoPrice = reservationDetails?.reduce((acc, reservation) => {
    return acc + (reservation.goodsList?.price ?? 0);
  }, 0);
  const discount = reservationDetails?.reduce((acc, reservation) => {
    return acc + (reservation.totalDiscount ?? 0);
  }, 0);
  const totalDiscount = discount + hanpassCouponDiscountAmount;

  const getAdditionalInfo = () => {
    return (
      pointEarned > 0 && (
        <div className="flex w-full justify-between">
          <p>적립예정 적립금: </p>
          {formatPrice(pointEarned)}
        </div>
      )
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      {/* KTX 복합상품금액 표시 */}
      {productType === "TRAIN" && (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <p>
                {/* <FormattedMessage id="payment.combined" /> */}
                <p>KTX 금액</p>
              </p>
            </Grid>
            <Grid item>
              <p>{formatPrice(totalKTXPrice)}</p>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            {/* 카카오 이용권 금액 */}
            <Grid item xs={8}>
              {/* <p>{intl.formatMessage({ id: "payment.combinedAmount" })}</p> */}
              <p className="text-start">카카오 T 이용권</p>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "end" }}>
              <p>{formatPrice(kakaoPrice)}</p>
            </Grid>
          </Grid>
        </>
      )}

      {productType === "TRAVEL" && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <p>
              {/* <FormattedMessage id="payment.combined" /> */}
              <p>금액</p>
            </p>
          </Grid>
          <Grid item>
            <p>{formatPrice(totalActivityPrice)}</p>
          </Grid>
        </Grid>
      )}

      {/* 할인 금액 표시 */}
      {totalDiscount > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <p>{isKorean ? "할인 금액" : "Discount amount"}</p>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <p>{`-${formatPrice(totalDiscount)}`}</p>
          </Grid>

          {ProviderUtils.isHanpass && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                paddingInlineStart: 1,
                color: theme.palette.black.main,
              }}
            >
              <Grid item xs={6}>
                <p className="text-start text-sm">
                  * {isKorean ? "한패스 할인" : "Hanpass discount"}
                </p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-end text-sm">
                  {formatPrice(hanpassCouponDiscountAmount)}
                </p>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {/* 수수료 */}
      {/* {commission > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="payment.commission" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{formatPrice(commission ?? 0)}</Typography>
          </Grid>
        </Grid>
      )} */}

      {/* 사용 적립금 */}
      {point > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <p>
              <FormattedMessage id="point.point" />
            </p>
          </Grid>
          <Grid item>
            <p>-{formatPrice(point)}</p>
          </Grid>
        </Grid>
      )}

      {/* 포인트파크 */}
      {/* {(__DEV__ || !ProviderUtils.provider) && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={5} sx={{ textAlign: "start" }}>
            <Typography sx={{ wordBreak: "keep-all" }}>
              <FormattedMessage id="pointpark.pointpark" />
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="end" gap={0.5}>
              <TextField
                value={pointParkPoint}
                disabled
                fullWidth
                InputProps={{
                  sx: {
                    height: "48px",
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                    },
                  },
                  startAdornment: pointParkPoint > 0 ? "-" : "",
                  endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
                }}
              />
              <Box sx={{ width: "40%" }}>
                <CustomButton
                  size="large"
                  onClick={
                    pointParkPoint ? cancelPointParkPoint : checkPointParkPoint
                  }
                >
                  {pointParkPoint
                    ? intl.formatMessage({ id: "pointpark.cancel" })
                    : intl.formatMessage({ id: "pointpark.apply" })}
                </CustomButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )} */}

      {/* 총주문금액 표시 */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="end"
        className="mt-2"
      >
        <Grid item>
          <p className="text-xl font-bold">
            <FormattedMessage id="payment.totalPrice" />
          </p>
        </Grid>
        <Grid item>
          <p className="text-xl font-bold text-error">
            {formatPrice(totalPrice - point - hanpassCouponDiscountAmount)}
          </p>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default OrderSummarySection;
