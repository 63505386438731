import { FormControlLabel, Radio, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Coupon } from "utils/reservationUtils";
import KakaoAccordion from "components/coupon/KakaoAccordion";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";

interface CouponSectionProps {
  selectedCoupon?: Coupon;
  handleCouponChange: (coupon: Coupon) => void;
  relatedGoods?: Coupon[];
}

const CouponSection = ({
  selectedCoupon,
  handleCouponChange,
  relatedGoods,
}: CouponSectionProps) => {
  const formatPrice = useFormatPrice();

  const getHeader = () => {
    return (
      <div>
        <p className="font-bold">
          <FormattedMessage id="booking.noTrainOnlyBooking" />
          {/* 기차표 예매 시 카카오T 이용권 혜택도 함께 받아보실 수 있어요 */}
        </p>
        <p className="font-bold text-error">
          <FormattedMessage id="booking.vouchersDelivery" />
        </p>
      </div>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <p className="mb-2 w-full pl-2">
        {relatedGoods && relatedGoods[0]?.goodsName}
      </p>
      <Grid container alignItems="center" sx={{ width: "95%" }}>
        {relatedGoods?.map((good) => (
          <Grid key={`${good.goodsId}`} item xs={6}>
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={selectedCoupon?.goodsId === good.goodsId}
                  onChange={() => handleCouponChange(good)}
                />
              }
              label={
                <p className="text-sm font-bold">{`${formatPrice(good.saleAmount)}`}</p>
              }
            />
          </Grid>
        ))}
      </Grid>
      <KakaoAccordion descriptionKey={relatedGoods?.[0].description} />
    </CardLayout>
  );
};

export default CouponSection;
