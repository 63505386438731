import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";

interface LachaPointSectionProps {
  pointEarned?: number;
}

const LachaPointSection = ({ pointEarned = 0 }: LachaPointSectionProps) => {
  const formatPrice = useFormatPrice();

  return (
    <CardLayout>
      <div className="flex justify-between">
        <p className="font-bold">적립 포인트: </p>
        <p className="font-bold">{formatPrice(pointEarned)}</p>
      </div>
    </CardLayout>
  );
};

export default LachaPointSection;
