import { Checkbox } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";
import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { OrderType } from "types/orderType";
import { useNavigate } from "react-router-dom";
import useFetchOrderInfo from "hooks/orderInfos/useFetchOrderInfo";
import OrderSkeleton from "components/skeleton/OrderSkeleton";
import PaymentService from "services/paymentService";
import OrderThumbnail from "components/reservation/OrderThumbnail";
import { ProviderUtils } from "utils/providerUtils";
import { providerClasses } from "utils/tailwindUtils";
import { FormattedMessage } from "react-intl";
import { getContextualUrl, isJTRPage } from "utils/navigation";

/**
 * ShoppingCartPage Component
 *
 * This component displays a page with all the items in the user's cart.
 *
 * It fetches the list of orders from the server and displays them in a list.
 *
 * The user can select the items they want to pay and click the "Pay" button.
 * The "Pay" button will redirect the user to the payment page with the selected items.
 *
 * If there are no items in the cart, the page will display a message and a button to redirect the user to the main page.
 */
const ShoppingCartPage = () => {
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  const { recentOrders } = useAppSelector((state) => state.myOrders);
  const carts = recentOrders.filter((order) => order.orderStatus === "PENDING");
  const [selectedItems, setSelectedItems] = useState<OrderType[]>([]);
  const [isFetching, fetchOrderInfos] = useFetchOrderInfo("PENDING");

  useEffect(() => {
    // if (recentOrders.length === 0) {
    fetchOrderInfos();
    // }
  }, [fetchOrderInfos]);

  const moveToPaymentPage = async () => {
    try {
      const paymentId = await PaymentService.createPaymentId(
        selectedItems.map((item) => item.orderId)
      );

      navigate(getContextualUrl(`/payment/${paymentId}`));
      // navigate(
      //   isJTRPage ? `/activity/payment/${paymentId}` : `/payment/${paymentId}`
      // );
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: OrderType
  ) => {
    if (e.target.checked) {
      if (ProviderUtils.isGME || ProviderUtils.isHanpass) {
        setSelectedItems([item]);
        return;
      }
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.orderId !== item.orderId));
    }
  };

  return (
    <Layout
      text={<FormattedMessage id="cart.cart" />}
      footer={false}
      onBack={
        () =>
          navigate(getContextualUrl("/user-settings"), {
            replace: true,
          })
        // navigate(isJTRPage ? "/activity/user-settings" : "/user-settings", {
        //   replace: true,
        // })
      }
    >
      <section
        className="h-100 mt-4 flex flex-col items-center justify-center space-y-2"
        style={{
          marginBottom: `${BOTTOM_NAVIGATION_HEIGHT * 3}px`,
        }}
      >
        {isFetching ? (
          <OrderSkeleton number={4} />
        ) : carts.length === 0 ? (
          <CardLayout className="mt-20 w-[90%]">
            <div className="space-y-4 break-keep font-bold">
              <div className="text-center">
                <p className="text-xl">
                  <FormattedMessage id="cart.noItems" />
                </p>
                <p className="text-text-secondary">
                  <FormattedMessage id="cart.message" />
                </p>
              </div>
              <div className="space-y-2">
                {ProviderUtils.isHanacard &&
                  (isJTRPage ? (
                    <CustomButton
                      variant="outlined"
                      size="large"
                      onClick={() => navigate(getContextualUrl("/"))}
                    >
                      <FormattedMessage id="cart.bookUSJ" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      variant="outlined"
                      size="large"
                      onClick={() => navigate("/")}
                    >
                      <FormattedMessage id="cart.bookKTX" />
                    </CustomButton>
                  ))}

                {!ProviderUtils.isHanacard && (
                  <>
                    <CustomButton
                      variant="outlined"
                      size="large"
                      onClick={() => navigate("/")}
                    >
                      <FormattedMessage id="cart.bookKTX" />
                    </CustomButton>
                    <CustomButton
                      variant="outlined"
                      size="large"
                      onClick={() => navigate("/activity")}
                    >
                      <FormattedMessage id="cart.bookUSJ" />
                    </CustomButton>
                  </>
                )}
              </div>
            </div>
          </CardLayout>
        ) : (
          recentOrders.map((item) => (
            <CardLayout key={item.orderId} className="w-full p-3">
              <div className="grid w-full grid-cols-[24px_1fr] gap-1">
                <Checkbox
                  checked={selectedItems.some(
                    (i) => i.orderId === item.orderId
                  )}
                  size="small"
                  onChange={(e) => handleCheck(e, item)}
                  className="col-span-1 self-start justify-self-start !p-0"
                />
                <div className="space-y-2">
                  <OrderThumbnail order={item} withoutBorder />
                </div>
              </div>
            </CardLayout>
          ))
        )}
      </section>

      <div
        className={`fixed bottom-[calc(env(safe-area-inset-bottom))] left-0 flex w-screen items-center justify-center bg-white px-4 py-2 shadow-2xl max-h-[${BOTTOM_NAVIGATION_HEIGHT * 2}px] min-h-[${BOTTOM_NAVIGATION_HEIGHT}px]`}
      >
        <div className="grid w-full max-w-[512px] grid-cols-12 gap-2">
          <div className="col-span-8 flex gap-4">
            <p className="font-bold text-text-secondary">
              <FormattedMessage id="cart.total" />
            </p>
            <h6
              className={`text-[1.3rem] font-bold ${providerClasses["text-primary"]}`}
            >
              {formatPrice(selectedItems.reduce((a, b) => a + b.totalPrice, 0))}
            </h6>
          </div>

          <CustomButton
            size="medium"
            onClick={moveToPaymentPage}
            disabled={selectedItems.length === 0}
            className="col-span-4"
          >
            <FormattedMessage id="cart.pay" />
          </CustomButton>
        </div>
      </div>
    </Layout>
  );
};

export default ShoppingCartPage;
