import { isAxiosError } from "axios";
import CustomButton from "components/button/CustomButton";
import LoadingSpinner from "components/LoadingSpinner";
import LoginContent from "components/login/LoginContent";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";
import {
  ActivityReservationContext,
  JTRPassenger,
} from "context/ActivityContextx";
import useModalSheet from "hooks/overlay/useModalSheet";
import useLanguages from "hooks/useLanguages";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import PaymentService from "services/paymentService";
import TravelService, {
  TravelReservationData,
  TravelReservationProduct,
} from "services/travelService";
import { getContextualUrl } from "utils/navigation";
import { ProviderUtils } from "utils/providerUtils";
import { BookerInfo } from "utils/reservationUtils";

const createActivityReservationData = (
  bookerInfo: BookerInfo,
  travelDate: string,
  productList: TravelReservationProduct[],
  passengersDetails?: JTRPassenger[]
): TravelReservationData => {
  const productListWithPassengers = [...productList];
  if (productListWithPassengers[0]) {
    productListWithPassengers[0].passengers = passengersDetails?.map(
      (passenger) => {
        return {
          linkAddress: passenger.email,
          linkMan: passenger.name,
        };
      }
    );
  }

  return {
    userName: bookerInfo.name,
    userEmail: bookerInfo.email,
    userPhone: bookerInfo.phone,
    travelDate,
    lang: "ko-KR",
    productList: productListWithPassengers,
  };
};

const initialBookerValidState = {
  name: true,
  phone: true,
  email: true,
};

export type FormValidState = typeof initialBookerValidState;

export const OrderButton = () => {
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const {
    bookerInfo,
    travelDate,
    productList,
    passengerDetails,
    isPassengersValid,
    passengersDetailRef,
  } = useContext(ActivityReservationContext);

  // 모달
  const [LoginModal, setLoginModalVisible] = useModalSheet();
  const [AlertModal, setAlertModalVisible] = useModalSheet({
    callbackOk: () => {
      window.scrollTo({ top: 640, behavior: "smooth" });
    },
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [ErrorModal, setErrorModalVisible] = useModalSheet({});

  const [PassengerAlertModal, setPassengerAlertModalVisible] = useModalSheet({
    callbackOk: () => {
      passengersDetailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  });
  const [CartModal, setCartModalVisible] = useModalSheet({
    callbackOk: () => {
      navigate(getContextualUrl("/cart"));
      // navigate("/activity/cart");
    },
    callbackCancel: () => navigate(-1),
  });

  // reservation loading state
  const [isLoading, setIsLoading] = useState(false);

  const activityReservationData = useMemo(
    () =>
      createActivityReservationData(
        bookerInfo,
        travelDate,
        productList,
        passengerDetails
      ),
    [bookerInfo, passengerDetails, productList, travelDate]
  );

  const isLoggedIn = useCallback(async () => {
    // if (!user) {
    if (!(await AuthService.isLoggedIn())) {
      // 하나카드인 경우 로그인 페이지로 이동해서 다시 접속하도록 유도
      if (ProviderUtils.isHanacard) {
        navigate("/login");
        return;
      }

      setLoginModalVisible(true);
      return false;
    }
    return true;
  }, [navigate, setLoginModalVisible]);

  const ifProductSelected = useCallback(() => {
    if (activityReservationData.productList.length === 0) {
      return false;
    }
    return true;
  }, [activityReservationData.productList.length]);

  const makeTravelReservation = useCallback(async () => {
    // 상품 미선택 시 모달 띄우기
    if (!ifProductSelected()) {
      setAlertModalVisible(true);
      return;
    }

    // 로그인하지 않은 경우 로그인 모달 띄우기
    if (!(await isLoggedIn())) {
      // throw new Error("Unauthenticated user");
      setLoginModalVisible(true);
      return;
    }

    // 탑승객 정보 유효한지 확인
    if (!isPassengersValid) {
      setPassengerAlertModalVisible(true);
      return;
    }

    try {
      setIsLoading(true);
      const { orderIdList, errorMessage } =
        await TravelService.makeTravelReservation(activityReservationData);

      if (errorMessage) {
        alert(errorMessage);
        window.location.reload();
        return;
      }

      return orderIdList;
    } catch (error) {
      console.error(error);
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.errorMessage;
        setErrorMessage(errorMessage);
        setErrorModalVisible(true);
      }

      // throw error;
    } finally {
      setIsLoading(false);
    }
  }, [
    activityReservationData,
    ifProductSelected,
    isLoggedIn,
    isPassengersValid,
    setAlertModalVisible,
    setErrorModalVisible,
    setLoginModalVisible,
    setPassengerAlertModalVisible,
  ]);

  const handleTravelReserve = useCallback(async () => {
    makeTravelReservation().then((orderIdList) => {
      if (orderIdList) {
        setCartModalVisible(true);
      }
    });
  }, [makeTravelReservation, setCartModalVisible]);

  const handlePayment = async (e: React.FormEvent) => {
    e.preventDefault();
    makeTravelReservation()
      .then(async (orderIdList) => {
        if (orderIdList) {
          const paymentId = await PaymentService.createPaymentId(orderIdList);

          navigate(`/activity/payment/${paymentId}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return isLoading ? (
    <LoadingSpinner overlap />
  ) : (
    <section
      className={`fixed bottom-0 left-0 z-10 flex w-full items-center justify-center bg-white px-4 shadow-[0_-2px_4px_rgba(0,0,0,0.1)]`}
      style={{
        height: `${BOTTOM_NAVIGATION_HEIGHT + 8}px`,
        //   boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="my-2 flex w-full max-w-lg gap-2">
        <CustomButton
          size="medium"
          variant="outlined"
          onClick={handleTravelReserve}
        >
          {isKorean ? "장바구니 담기" : "Add to cart"}
        </CustomButton>
        <CustomButton size="medium" onClick={handlePayment}>
          {isKorean ? "주문하기" : "Order"}
        </CustomButton>
      </div>
      <AlertModal modal>
        <p className="text-lg font-bold">
          {isKorean ? "상품을 선택해주세요" : "Please select a product"}
        </p>
      </AlertModal>

      <PassengerAlertModal modal>
        <p className="text-lg font-bold">
          {isKorean
            ? "유효한 탑승객 정보를 입력하세요"
            : "Please enter valid passenger information"}
        </p>
      </PassengerAlertModal>

      <CartModal modal cancellable cancelText="계속 쇼핑하기">
        <div>
          <p className="text-lg font-bold">
            {isKorean
              ? "상품을 장바구니에 담았어요."
              : "Products have been added to your cart."}
          </p>
          <p className="text-lg font-bold">
            {isKorean
              ? "장바구니에 담긴 상품은 20분 후에 자동으로 삭제됩니다. 장바구니로 이동할까요?"
              : "Products will be automatically removed from cart in 20 minutes. Do you want to move to your cart?"}
          </p>
        </div>
      </CartModal>

      {/* 로그인 모달 */}
      <LoginModal modal variant="icon">
        <LoginContent
          isModal={true}
          closeModal={() => {
            setLoginModalVisible(false);
            window.location.reload();
          }}
        />
      </LoginModal>
      <ErrorModal modal>
        <p className="text-lg font-bold">{errorMessage}</p>
      </ErrorModal>
    </section>
  );
};
