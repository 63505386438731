import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Fab,
  Grid,
  Button,
  useTheme,
  Card,
  TextField,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingSpinner from "components/LoadingSpinner";
import { styled } from "@mui/material/styles";
import CustomButton from "components/button/CustomButton";
import {
  DesktopTimePicker,
  MobileDatePicker,
  pickersLayoutClasses,
} from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/ko";
import useLanguages from "hooks/useLanguages";
import QuickLinks from "pages/ktx/station-panel/sections/QuickLinks";
import useModalSheet from "hooks/overlay/useModalSheet";
import useGetStationNameFromCode from "hooks/useTranslateStation";
import PassengerSelection from "./PassengerSelection";
import {
  getAdultsNumber,
  getChildrenNumber,
  getURLSearchParams,
} from "utils/urlSearchParamsUtils";
import history from "history/browser";
import BookingFormHeader from "./BookingFormHeader";

const convertTimeStringToMoment = (inputString) => {
  const timeString = inputString
    ? `${inputString.slice(0, 2)}:${inputString.slice(
        2,
        4
      )}:${inputString.slice(4)}`
    : "00:00:00";
  return moment(timeString, "HH:mm:ss");
};

const BookingForm = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();

  const getStationNameFrom = useGetStationNameFromCode();
  const [loading, setLoading] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const params = getURLSearchParams();
  const [StationModalSheet, setStationModalSheetVisible] = useModalSheet();
  const [stationOption, setStationOption] = useState("");
  const [PassengerModalSheet, setPassengerModalSheetVisible] = useModalSheet();

  const handleStationClick = (e) => {
    const { name } = e.target;
    setStationOption(name);
    history.push(`?${params.toString()}`, "");
    setStationModalSheetVisible(true);
  };

  const handlePassengerClick = () => {
    // console.log(params.toString());

    history.push(`?${params.toString()}`, "");
    setPassengerModalSheetVisible(true);
  };

  // Close modal when back button is clicked
  useEffect(() => {
    window.document.documentElement.focus();
    const historyEvent = history.listen(({ action }) => {
      // alert(`CreditPaymentPage: ${action}`);

      if (action === "POP") {
        setStationModalSheetVisible(false);
        setPassengerModalSheetVisible(false);
        navigate(`?${params.toString()}`);
      }
    });
    return historyEvent;
  }, [
    navigate,
    params,
    setPassengerModalSheetVisible,
    setStationModalSheetVisible,
  ]);

  // 출발지와 도착지 역이름,역코드 교환 이벤트 핸들러
  const handleSwap = () => {
    const newArrival = params.get("departure") ?? "";
    const newArrivalCode = params.get("departureCode") ?? "";
    const newDeparture = params.get("arrival") ?? "";
    const newDepartureCode = params.get("arrivalCode") ?? "";

    params.set("departure", newDeparture);
    params.set("departureCode", newDepartureCode);
    params.set("arrival", newArrival);
    params.set("arrivalCode", newArrivalCode);

    navigate(`?${params.toString()}`);
  };

  const getPassengerText = () => {
    const adults = getAdultsNumber();
    const children = getChildrenNumber();
    const textParts = [];

    if (adults > 0) {
      textParts.push(
        `${intl.formatMessage(
          { id: "passengerSelection.adultsNumber" },
          { number: `${adults}` }
        )}`
      );
    }

    if (children > 0) {
      textParts.push(
        `${intl.formatMessage(
          { id: "passengerSelection.childrenNumber" },
          { number: `${children}` }
        )}`
      );
    }

    // 성인과 어린이 모두 0명일 경우 기본 텍스트 표시
    return (
      textParts.join(", ") ||
      intl.formatMessage({ id: "passengerSelection.passengers" })
    );
  };

  const handleDateChange = (value) => {
    if (value?.isBefore(moment(), "day")) {
      alert("오늘 이전의 날짜는 선택할 수 없습니다.");
      return;
    }

    params.set("date", value?.format("YYYY-MM-DD") ?? "");
    navigate(`?${params.toString()}`);
  };

  const handleDepartureTimeChange = (value) => {
    params.set("departureTime", value?.format("HHmmss") ?? "000000");
    navigate(`?${params.toString()}`);
  };

  const isFormComplete = () => {
    return (
      params.get("departure") && params.get("arrival") && params.get("date")
    );
  };

  // 폼 제출 이벤트 핸들러
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // 필수 필드 검사
    if (!isFormComplete()) {
      alert("모든 필드를 채워주세요.");
      setLoading(false);
      return;
    }

    navigate(`/search-results?${params.toString()}`);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Card sx={{ py: 2 }}>
            <Stack
              component="form"
              onSubmit={handleSubmit}
              gap={2}
              marginX={2}
              sx={{
                overflow: "auto",
              }}
            >
              <BookingFormHeader />

              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ position: "relative", width: "100%" }}
                >
                  <Grid item xs={6}>
                    {/* "Departure station" */}
                    <StationInput
                      onFocus={(e) => e.target.blur()}
                      locale={intl.locale}
                      type="text"
                      name="departure"
                      readOnly
                      value={getStationNameFrom(params.get("departure") || "")}
                      onClick={handleStationClick}
                      placeholder={intl.formatMessage({
                        id: "trainMain.from",
                      })}
                      id="departure"
                      autoComplete="off"
                    />
                  </Grid>
                  {/* Swap Icon */}
                  <Fab
                    aria-label="swap_stations"
                    size="small"
                    onClick={handleSwap}
                    sx={{
                      boxShadow: "none",
                      position: "absolute",
                      left: "calc(50% - 15px)",
                      top: "calc(50% - 15px)",
                      background: "none",
                      color: theme.palette.text.primary,
                      zIndex: 1,
                    }}
                  >
                    <SwapHorizIcon />
                  </Fab>
                  <Grid item xs={6}>
                    <StationInput
                      // "Arrival station"
                      onFocus={(e) => e.target.blur()}
                      locale={intl.locale}
                      type="text"
                      name="arrival"
                      readOnly
                      value={getStationNameFrom(params.get("arrival") || "")}
                      onClick={handleStationClick}
                      placeholder={intl.formatMessage({
                        id: "trainMain.to",
                      })}
                      id="arrival"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* Customer count input */}
                  <Button
                    name="passengers"
                    onClick={handlePassengerClick}
                    fullWidth
                    style={{
                      border: `1px solid ${theme.border.color.main}`,
                      color: theme.palette.text.primary,
                    }}
                    sx={{
                      color: "black",
                      height: 64,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {getPassengerText()}
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  {/* Date input */}
                  <DateInput
                    // label={
                    //   !searchParams.date
                    //     ? intl.formatMessage({
                    //         id: "trainMain.selectADate",
                    //       })
                    //     : ""
                    // }
                    value={
                      params.get("date") ? moment(params.get("date")) : null
                    }
                    onChange={handleDateChange}
                    closeOnSelect={true}
                    minDate={moment().add(1, "days")}
                    maxDate={moment().add(1, "months")}
                    disableHighlightToday
                    displayStaticWrapperAs="mobile"
                    slotProps={{
                      toolbar: {
                        toolbarFormat: isKorean
                          ? "YY년 MM월 DD일"
                          : "MMM DD, YY",
                        toolbarPlaceholder: "",
                        hidden: false,
                      },
                      actionBar: {
                        actions: [],
                      },
                      textField: {
                        placeholder: intl.formatMessage({
                          id: "trainMain.selectADate",
                        }),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Departure time input */}
                  <DepartureTime
                    label={intl.formatMessage({ id: "booking.departureTime" })}
                    views={["hours", "minutes"]}
                    defaultValue={convertTimeStringToMoment(
                      params.get("departureTime")
                    )}
                    ampm={false}
                    // openTo="meridiem"

                    onChange={handleDepartureTimeChange}
                    closeOnSelect={false}
                    open={timePickerOpen}
                    onOpen={() => setTimePickerOpen(true)}
                    onClose={() => setTimePickerOpen(false)}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        onClick: () => setTimePickerOpen((prev) => !prev),
                      },

                      layout: {
                        sx: {
                          [`.${pickersLayoutClasses.contentWrapper}`]: {
                            gridColumn: 1,
                            gridRow: 1,

                            "& > .MuiMultiSectionDigitalClock-root": {
                              justifyContent: "space-between",
                              "& > ul": {
                                width: "100%",
                              },
                            },
                          },
                          [`.${pickersLayoutClasses.actionBar}`]: {
                            gridColumn: 1,
                            gridRow: 2,
                          },
                        },
                      },
                    }}
                    slots={{
                      actionBar: ActionList,
                      openPickerButton: () => null,
                    }}
                  />
                </Grid>
              </Grid>
              {/* Train search button*/}
              <CustomButton
                id="trainMain.search"
                disabled={!isFormComplete()}
                color="secondary"
                style={{ opacity: 0.8, fontWeight: 500 }}
              />
            </Stack>
          </Card>

          {/* 기차역 선택 모달 */}
          <StationModalSheet
            cover
            variant="icon"
            title={
              <FormattedMessage
                id={
                  stationOption === "departure"
                    ? `stationSelectionPopup.selectDeparture` // "출발지 선택"
                    : `stationSelectionPopup.selectArrival` // "도착지 선택"
                }
              />
            }
          >
            <QuickLinks
              stationOption={stationOption}
              closeWindow={() => setStationModalSheetVisible(false)}
            />
          </StationModalSheet>
          {/* 탑승객 선택 모달 */}
          <PassengerModalSheet
            cover
            variant="icon"
            title={<FormattedMessage id="passengerSelection.passengers" />}
          >
            <PassengerSelection
              variant="icon"
              closeWindow={() => setPassengerModalSheetVisible(false)}
            />
          </PassengerModalSheet>
        </>
      )}
    </>
  );
};

export default BookingForm;

const DateInput = styled(MobileDatePicker)`
  width: 100%;

  & .MuiInputBase-root {
    height: 64px;
  }
  & .MuiInputBase-input {
    text-align: center;
    font-weight: bold;
  }

  & label.MuiFormLabel-root {
    width: 100%;
    text-align: center;
  }

  & label.MuiFormLabel-root {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .MuiOutlinedInput-notchedOutline legend {
    display: hidden;
  }
`;

const DepartureTime = styled(DesktopTimePicker)`
  width: 100%;

  & .MuiInputBase-root {
    height: 64px;
  }
  & .MuiInputBase-input {
    text-align: center;
    font-weight: bold;
  }
`;

const StationInput = styled(TextField)(({ id, locale, theme }) => {
  const commonStyle = {
    width: "100%",
    height: "64px",
    border: `1px solid ${theme.border.color.main}`,
    boxSizing: "border-box",
    fontSize: "1.125rem",
    fontWeight: "bold",
    textAlign: "center",
    wordBreak: "keep-all",

    "&::placeholder": {
      fontWeight: "normal",
      color: theme.palette.grey,
    },
    color: theme.palette.text.primary,

    borderInlineEnd: id === "departure" && `none`,
    borderInlineStart: id === "arrival" && `none`,
  };

  const fromBorderRadiusStyle =
    locale !== "ur"
      ? {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        }
      : {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        };

  const toBorderRadiusStyle =
    locale !== "ur"
      ? {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        }
      : {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        };

  return {
    width: "100%",
    overflow: "hidden",

    "& .MuiInputBase-input": {
      ...commonStyle,
      ...(id === "departure" ? fromBorderRadiusStyle : toBorderRadiusStyle),
    },
    "& .MuiOutlinedInput-input": {
      border: `1px solid ${theme.border.color.main}`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  };
});

const ActionList = (props) => {
  const { isKorean } = useLanguages();

  const {
    onAccept,
    onCancel,
    className,
    // onClear,
    // onSetToday,
  } = props;
  const actions = [
    { text: isKorean ? "확인" : "OK", method: onAccept },
    { text: isKorean ? "취소" : "Cancel", method: onCancel },
    // { text: 'Clear', method: onClear },
    // { text: 'Today', method: onSetToday },
  ];
  return (
    // Propagate the className such that CSS selectors can be applied
    <Stack direction="row" justifyContent="center" className={className}>
      {actions.map(({ text, method }) => (
        <Button key={text} onClick={method} sx={{ fontSize: "1rem" }}>
          {text}
        </Button>
      ))}
    </Stack>
  );
};
