import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { IntlProvider } from "react-intl";
// import * as locales from "assets/locales";
import ko from "assets/locales/ko.json";
import { loadLocaleData } from "assets/locales";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import AppBody from "./router/AppBody";
import { useAppSelector } from "app/hooks";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ko";
import Metatag from "Metatag";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "components/ScrollToTop";

import { ProviderUtils } from "utils/providerUtils";
import useFetchWebBridge from "hooks/useFetchWebBridge";
import useHappytalk from "hooks/useHappytalk";
import clsx from "clsx";
import DialogProvider from "hooks/useDialog";
import { getTheme } from "utils/themeUtils";
// @media ${({ theme }) => theme.device.tablet} {
// flex-direction: column;
// height: calc(90vw * 16 / 9);
// }

// const messages = locales;

type MessageType = Record<string, string>;

const App = () => {
  useFetchWebBridge(); // For fetching lottecard web bridge file
  useHappytalk(["/"]);

  const { theme } = useAppSelector((state) => state.theme);
  const colorTheme = getTheme(theme);
  // const [messages, setMessages] = useState<MessageType>(
  //   ko as unknown as MessageType
  // );
  const [messages, setMessages] = useState<MessageType>(
    ko as unknown as MessageType
  );

  const language = useAppSelector((state) => state.user?.language) || "ko";

  useEffect(() => {
    loadLocaleData(language).then((messages: { default: MessageType }[]) => {
      const message = messages.reduce((acc, cur) => {
        return { ...acc, ...cur.default };
      }, {} as MessageType);
      setMessages(message as MessageType);
      // setMessages(messages.default as MessageType);
    });
    // const setScreenSize = () => {
    //   const vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty("--vh", `${vh}px`);
    // };
    // setScreenSize();

    document.documentElement.dir = language !== "ur" ? "ltr" : "rtl";

    document.body.classList.add("bg-background");

    return () => {
      document.body.classList.remove("bg-background");
    };
    // window.addEventListener("resize", setScreenSize);
    // return () => window.removeEventListener("resize", setScreenSize);
  }, [language]);

  return (
    <ThemeProvider theme={colorTheme}>
      <HelmetProvider>
        <IntlProvider
          locale={language}
          // messages={messages[language] as MessageType}
          messages={messages}
        >
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={language === "ko" ? "ko" : "en"}
          >
            <CssBaseline />
            <Metatag />

            <div
              className={clsx(
                "m-auto max-w-full bg-background",
                ProviderUtils.twPrefix
              )}
            >
              <DialogProvider>
                <Container maxWidth={false} disableGutters>
                  <ScrollToTop />
                  <AppBody />
                </Container>
              </DialogProvider>
            </div>
          </LocalizationProvider>
        </IntlProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
