/**
 * Function to close lottecard webview
 * 
 * @remarks
 * This is only needed to close webview in a lottecard app.
 * Otherwise, it does nothing.
 */
export const closeWebview = () => {
  const $lucifer = new Lucifer.default();
  $lucifer.closePage();
};
