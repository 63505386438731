import Layout from "components/layout/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import { FormattedMessage } from "react-intl";
import type { OrderType } from "types/orderType";
import useCancelReservation from "hooks/useCancelReservation";
import { ProviderUtils } from "utils/providerUtils";
import CustomButton from "components/button/CustomButton";
import OrderMainContent from "../OrderMainContent";
import useSaveAsImage from "hooks/useSaveAsImage";
import CardLayout from "components/layout/CardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";

interface OrderDetailPageProps {
  reservationDetails: OrderType;
}

const OrderDetailPage = ({ reservationDetails }: OrderDetailPageProps) => {
  const navigate = useNavigate();
  const hanacard = useLocation().state?.hanacard ?? false;
  const orderId = reservationDetails?.orderId;
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";
  const saveAsImage = useSaveAsImage(orderId);

  // const hanacard = useLocation().state?.hanacard ?? false;
  const backToOrderList = useCallback(async () => {
    if (hanacard) {
      navigate("/hanacard/reservations");
      return;
    }
    navigate(-1);

    // navigate("/order-history", { replace: true });
  }, [hanacard, navigate]);

  // cancel reservation
  const [CancelDetailsComponent] = useCancelReservation(reservationDetails);
  const productType = reservationDetails?.productType;

  return (
    <Layout
      text={<FormattedMessage id="orders.detailTitle" />}
      onBack={backToOrderList}
    >
      {!reservationDetails ? (
        <LoadingSpinner />
      ) : (
        <div className="my-4 space-y-3">
          <OrderMainContent reservationDetails={reservationDetails} />

          {/* 하나카드 - 내역 저장하기 버튼 */}
          {ProviderUtils.isHanacard && (
            <CustomButton
              className="hide-from-saved-image !bg-white"
              size="medium"
              variant="outlined"
              onClick={saveAsImage}
              id={"orders.saveOrderDetail"}
            />
          )}
          {productType === "TRAVEL" && reservationDetails.isRefundable && (
            <CardLayout>
              <p className="mb-2 font-bold text-error">
                <FormattedMessage id="orders.canceled" />
              </p>
              <p className="mb-2">
                주문 취소는 라쿠카라차 고객센터에서 접수하실 수 있습니다.
              </p>
              <p>고객센터: 02-568-1220</p>
              <div className="text-sm text-text-secondary">
                <p>평일 09:00~18:00</p> <p>점심 12:00~13:00</p>
                <p>주말 및 공휴일 휴무</p>
              </div>
            </CardLayout>
          )}
          {productType === "TRAIN" && isPurchased && (
            <div className="hide-from-saved-image">
              {/* 결제 완료한 경우 취소 예상 금액 보여주기 */}
              {CancelDetailsComponent}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default OrderDetailPage;
