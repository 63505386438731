import useLanguages from "hooks/useLanguages";
import { useEffect, useState } from "react";
import { StorageUtils } from "utils/storageUtils";

const useA2HS = () => {
  /**
   * prompt가 실행될 수 있는 환경인 경우에만 모달창을 나타내기 위해
   * 변경 시 리렌더링을 발생시키기 위해서 useRef가 아닌 useState를 사용하였습니다.
   */
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isPromptHidden, setIsPromptHidden] = useState(false);
  const showPrompt = deferredPrompt && !isPromptHidden;

  useEffect(() => {
    const hideUntil = StorageUtils.InstallPrompt.getHideUntil();
    if (hideUntil === null) {
      setIsPromptHidden(false);
      return;
    }

    if (Number(hideUntil) < Date.now()) {
      StorageUtils.InstallPrompt.removeHideUntil();
      setIsPromptHidden(false);
      return;
    }

    setIsPromptHidden(true);
  }, []);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const installApp = () => {
    // 설치 메서드 실행
    deferredPrompt?.prompt();
    deferredPrompt?.userChoice.then(() => {
      clearPrompt();
    });
  };

  const clearPrompt = () => {
    setDeferredPrompt(null);

    const oneHourLater = Date.now() + 60 * 60 * 1000;
    // const oneDayLater = Date.now() + 24 * 60 * 60 * 1000;
    // window.localStorage.setItem("hide_install_prompt_until", oneHourLater);
    StorageUtils.InstallPrompt.setHideUntil(oneHourLater);
    setIsPromptHidden(true);
  };

  return { showPrompt, installApp, clearPrompt };
};

export default function A2HS() {
  const { isKorean } = useLanguages();
  const { showPrompt, installApp, clearPrompt } = useA2HS();

  // return deferredPrompt && (
  return (
    showPrompt && (
      <div className="fixed bottom-0 left-0 z-[100000] flex w-full flex-col gap-3 rounded-t-2xl bg-white px-6 py-4 shadow-[0_-20px_25px_-5px_rgb(0_0_0_/_0.1)]">
        <h5 className="text-lg font-bold">
          {isKorean ? "바로가기 추가" : "Add to home screen"}
        </h5>
        <p className="">
          {isKorean
            ? "배경화면에 라차 바로가기를 추가하시겠습니까?"
            : "Would you like to add Lacha to your home screen?"}
        </p>
        <div className="flex w-2/3 justify-end gap-4 self-end">
          <button onClick={clearPrompt} className="font-bold text-gray-500">
            {isKorean ? "취소" : "Cancel"}
          </button>
          <button onClick={installApp} className="font-bold text-blue-700">
            {isKorean ? "추가" : "Add"}
          </button>
        </div>
      </div>
    )
  );
}
