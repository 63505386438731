import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

interface LoadingSpinnerProps {
  overlap?: boolean;
  className?: string;
}

const LoadingSpinner = ({
  overlap = false,
  className,
}: LoadingSpinnerProps) => {
  // const [scrollTop, setScrollTop] = useState(
  //   document.documentElement.scrollTop
  // );

  // useEffect(() => {
  //   const onScroll = () => {
  //     setScrollTop(document.documentElement.scrollTop);
  //   };
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  return (
    <div
      className={twMerge(
        clsx("flex w-full flex-col items-center justify-center", {
          "h-[80vh]": !overlap,
          [`fixed left-1/2 top-0 h-screen -translate-x-1/2`]: overlap,
        }),
        className
      )}
      // style={overlap ? { top: `${scrollTop}px` } : {}}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingSpinner;
