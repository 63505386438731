import { PaymentMethod } from "const/paymentMethod";
import history from "history/browser";
import { useState } from "react";
import PaymentService from "services/paymentService";
import useDebounce from "./useDebounce";
import { getContextualUrl } from "utils/navigation";

const useProcessPayment = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const debounce = useDebounce();

  const processPayment = async (
    // orderIds: number | number[],
    paymentId: number,
    paymentMethod: PaymentMethod,
    point: number = 0,
    externalCouponId?: number
  ) => {
    const payment = async () => {
      setIsProcessing(true);

      try {
        switch (paymentMethod) {
          case "GME":
            const { availablePay } = await PaymentService.paymentRequest(
              paymentId,
              paymentMethod,
              point,
              externalCouponId
            );

            return availablePay;

          default:
            await PaymentService.initializePayment(
              paymentId,
              paymentMethod,
              point,
              externalCouponId
            );
            // 다날 페이지에서 뒤로 가는 경우 장바구니 페이지로 이동하도록 history stack 수정
            history.replace(getContextualUrl("/cart"));
        }
      } catch (error) {
        if (error instanceof Error) {
          alert(`결제 실패:  ${error.message}`);
        }
      } finally {
        setIsProcessing(false);
      }
    };

    debounce(payment);
  };

  return [isProcessing, processPayment] as const;
};

export default useProcessPayment;
