import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { providerClasses } from "utils/tailwindUtils";

const LoginLinks = ({ isModal }: { isModal: boolean }) => {
  return (
    <div
      className={`relative z-[1] flex items-center justify-center gap-2 break-keep text-center text-sm ${providerClasses["text-primary"]} `}
    >
      <Link to="/find-id" state={{ isModal }}>
        <FormattedMessage id="user.findId" />
      </Link>{" "}
      |
      <Link to="/find-password" state={{ isModal }}>
        <FormattedMessage id="user.forgot" />
      </Link>{" "}
      |
      <Link to="/register" state={{ isModal }}>
        <FormattedMessage id="user.signup" />
      </Link>
    </div>
  );
};

export default LoginLinks;
