import React, { memo, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useInputs from "hooks/useInputs";
import { StorageUtils } from "utils/storageUtils";
import RecentLoginIcon from "./RecentLoginIcon";
import { Credential, useLoginMutation } from "app/apiSlice";
import LoadingSpinner from "components/LoadingSpinner";

interface LoginFormProps {
  closeModal?: () => void;
}

const initialForm: Credential = {
  loginId: "",
  loginPassword: "",
};

const LoginForm = ({ closeModal }: LoginFormProps) => {
  // hooks
  const intl = useIntl();
  const navigate = useNavigate();

  const [login, { isSuccess, isLoading }] = useLoginMutation();

  // 사용자 로그인 정보
  const { form, onChange, getSavedLoginId } = useInputs(initialForm);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberId, setRememberId] = useState(false);

  useEffect(() => {
    const savedLoginId = StorageUtils.SavedLoginId.get();

    if (savedLoginId) {
      getSavedLoginId(savedLoginId);
      setRememberId(true);
    }
  }, [getSavedLoginId]);

  // 로그인 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const data: Credential = {
      loginId: String(form.loginId),
      loginPassword: String(form.loginPassword),
    };

    login(data);

    // try {
    //   const response = await AuthService.login(data);

    //   if (response.status === 201) {
    //     TokenService.setAccessToken(response.data.accessToken);

    //     // 사용자 정보
    //     // const userInfoResponse = await UserService.getMyInfo();
    //     // dispatch(fetchUser(userInfoResponse));

    //     // 아이디 저장 여부에 따라 로컬 스토리지에 저장 또는 삭제
    //     if (rememberId) {
    //       StorageUtils.SavedLoginId.set(String(form.loginId));
    //     } else {
    //       StorageUtils.SavedLoginId.remove();
    //     }

    //     StorageUtils.RecentLoginMethod.set("DEFAULT_FORM");

    //     if (closeModal != null) {
    //       closeModal();
    //       return;
    //     }

    //     navigate(state?.from || "/");
    //   }
    // } catch (error) {
    //   if (isAxiosError(error)) {
    //     if (!error.response) {
    //       alert("네트워크 오류가 발생했습니다. 나중에 다시 시도해주세요.");
    //     } else if (error.response.status === 400) {
    //       alert("아이디 또는 비밀번호가 정확하지 않습니다.");
    //     } else {
    //       alert("로그인 중 오류가 발생했습니다.");
    //     }
    //   }
    // }
  };

  // if (error) {
  // if (isAxiosError(error)) {
  // if (!error) {
  //   alert("네트워크 오류가 발생했습니다. 나중에 다시 시도해주세요.");
  // } else if (error.response.status === 400) {
  //   alert("아이디 또는 비밀번호가 정확하지 않습니다.");
  // } else {
  //   alert("로그인 중 오류가 발생했습니다.");
  // }
  // }
  // }

  if (isSuccess) {
    // console.log("login success");
    // 아이디 저장 여부에 따라 로컬 스토리지에 저장 또는 삭제
    if (rememberId) {
      StorageUtils.SavedLoginId.set(String(form.loginId));
    } else {
      StorageUtils.SavedLoginId.remove();
    }

    StorageUtils.RecentLoginMethod.set("DEFAULT_FORM");

    if (closeModal != null) {
      closeModal();
      return;
    }

    navigate("/");
  }

  return (
    <form className="wi-full space-y-4" onSubmit={handleSubmit}>
      <div className="space-y-2">
        <TextField
          label={intl.formatMessage({ id: "signup.email" })}
          type="text"
          name="loginId"
          variant="outlined"
          value={form.loginId}
          onChange={onChange}
          fullWidth
          required
        />
        <TextField
          label={intl.formatMessage({ id: "user.password" })}
          type={showPassword ? "text" : "password"}
          name="loginPassword"
          variant="outlined"
          value={form.loginPassword}
          onChange={onChange}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <IconButton onClick={(_e) => setShowPassword((prev) => !prev)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
      </div>
      <div className="text-end">
        <FormControlLabel
          sx={{ alignSelf: "end", m: 0 }}
          control={
            <Checkbox
              name="remember"
              checked={rememberId}
              sx={{ paddingInlineEnd: 0.3 }}
              onChange={(e) => setRememberId(e.target.checked)}
            />
          }
          label={intl.formatMessage({ id: "user.remember" })}
        />
        <CustomButton>
          <FormattedMessage id="user.signIn" />
          {StorageUtils.RecentLoginMethod.get() === "DEFAULT_FORM" && (
            <RecentLoginIcon />
          )}
        </CustomButton>
      </div>
      {isLoading && <LoadingSpinner overlap />}
    </form>
  );
};

export default memo(LoginForm);
