import { SeatAppFlgType, TrainParams } from "app/reservationSlice";
import { SeatDetails } from "components/seat/Seat";
import { ScheduleType } from "types/scheduleType";
import { getAdultsNumber, getChildrenNumber } from "./urlSearchParamsUtils";

interface ReservationData {
  gdNo: string;
  gdConsGpSqno: string;
  gdConsItmId: string;
  gdItmId: string;

  runDt: string;
  trnNo: string;
  trnGpCd: string;
  dptRsStnCd: string;
  dptDt: string;
  dptTm: string;
  arvRsStnCd: string;
  arvDt: string;
  arvTm: string;
  trnPsrmClCd: string; // 열차 좌석 등급 코드
  gdUtlPsNm: string; // 승객 이름 목록
  btdt: string;

  tripPrnb1: number; // 성인 승객 수
  tripPrnb3: number; // 어린이 승객 수
  prnbCnt?: number; // 승객 수

  trnCnt?: string; // 열차 수
  seatAppFlg: SeatAppFlgType; // 좌석 지정 플래그

  scarNo: string; // 차량 번호 목록
  seatNo: string; // 좌석 번호 목록

  rsvPsNm: string; // 예약자 이름
  emailAdr: string; // 예약자 이메일
  hndyTeln: string; // 예약자 전화번호
  rsvPsBtDt: string; // 예약자 생년월일

  goodsId?: string; // 상품 ID (고정값 또는 설정에서 가져옴)
  agencyCode?: string; // 대리점 코드
}

export interface Coupon {
  description: string;
  goodsId: string;
  goodsName: string;
  goodsType: string;
  isVisible: boolean;
  saleAmount: 1000 | 3000 | 5000 | 10000;
  saleStatus: "20" | "50";
  trainRegion: string;
}

export interface BookerInfo {
  email: string;
  name: string;
  phone: string;
  birthDate?: string | null;
  agencyCode?: string;
}

type Passengers = (Omit<BookerInfo, "email" | "phone"> & {
  birthDate: string;
  seat: Required<SeatDetails>;
})[];

export interface ReservationInput {
  selectedCoupon: Coupon | undefined;
  bookerInfo: BookerInfo;
  passengerDetails: Passengers; // 승객 정보
  schedule: ScheduleType; // 스케줄 정보
  trainParams: TrainParams; // 예약 관련 정보
}

export const createReservationData = ({
  selectedCoupon,
  bookerInfo,
  passengerDetails, // 승객 정보
  schedule, // 스케줄 정보
  trainParams, // 예약 관련 정보
}: ReservationInput): ReservationData => {
  const adultsCount = getAdultsNumber();
  const childrenCount = getChildrenNumber();

  // 승객들의 이름, 차량 번호, 좌석 번호, 생년월일 추출
  const gdUtlPsNm: string = passengerDetails.map((p) => p.name).join(",");
  const scarNos: string = passengerDetails.map((p) => p.seat.scarNo).join(",");
  const seatNos: string = passengerDetails.map((p) => p.seat.seatNo).join(",");
  const btdts: string = passengerDetails
    .map((p) => p.birthDate)
    .join(",")
    .replaceAll("-", "");

  // 예약 데이터 객체 구성
  const result: ReservationData = {
    gdNo: schedule.gdNo, // 상품번호
    gdConsGpSqno: schedule.gdConsGpSqno, // 상품 구성그룹 일련번호
    gdConsItmId: schedule.gdConsItmId, // 상품 구성품목ID
    gdItmId: schedule.gdItmId, // 상품품목ID

    trnNo: schedule.trnNo, // 열차번호
    trnGpCd: schedule.trnGpCd, // 열차 그룹코드
    runDt: schedule.runDt, // 운행일자
    dptRsStnCd: schedule.dptRsStnCd, // 출발예발역코드
    dptDt: schedule.dptDt, // 출발일자
    dptTm: schedule.dptTm, // 출발시각
    arvRsStnCd: schedule.arvRsStnCd, // 도착예발역토드
    arvDt: schedule.arvDt, // 도착일자
    arvTm: schedule.arvTm, // 도착시각

    rsvPsNm: bookerInfo.name, // 예약자 이름
    emailAdr: bookerInfo.email, // 예약자 이메일
    hndyTeln: bookerInfo.phone, // 예약자 전화번호
    rsvPsBtDt: bookerInfo?.birthDate || "", // 예약자 생년월일

    prnbCnt: passengerDetails.length, // 승객 수
    gdUtlPsNm: gdUtlPsNm, // 승객 이름
    btdt: btdts, // 승객 생년월일
    tripPrnb1: adultsCount, // 성인 승객 수
    tripPrnb3: childrenCount, // 어린이 승객 수

    trnCnt: trainParams.trnCnt || "1", // 열차 수 (기본값 설정)
    trnPsrmClCd: trainParams.trnPsrmClCd || "1", // 열차 좌석 등급 코드 (기본값 설정  - "1" 일반식)
    seatAppFlg: "Y", // trainParams.seatAppFlg || "N", // 좌석 지정 플래그 ( "Y" | "N" ) - 바로 예약 버튼 누를 때 좌석 랜덤 지정됨 -> "Y" 로 고정해야 바로 예약인 경우도 좌석이 변경되지 않음
    scarNo: scarNos, // 호차 번호 목록
    seatNo: seatNos, // 좌석 번호 목록

    // goodsId: selectedCoupon?.goodsId, // "LA010022008", // 상품 ID (고정값 또는 설정에서 가져옴)
    agencyCode: bookerInfo.agencyCode, // hanpass 대리점 코드
  };

  if (selectedCoupon) {
    result.goodsId = selectedCoupon?.goodsId;
  }

  return result;
};
