import { useEffect } from 'react';

/**
 * Deprecated - 현재 point park 미사용으로 사용하지 않는 컴포넌트
 * Point park 관련 iframe를 닫는 컴포넌트
 *
 * This page is to complete a Point Park payment and close.
 * It reloads the top window to return to the previous page.
 */
const PointParkComplete = () => {
  useEffect(() => {
    const iframe = window.top?.document.querySelector("iframe");
    iframe?.parentElement?.setAttribute("style", "display:none");
    window.top?.location.reload();
  }, []);

  return <></>;
};

export default PointParkComplete;

