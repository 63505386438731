/**
 * Lotte Card travel pack URL
 *
 * The URL for the travel pack page of Lotte Card
 *
 * The URL is determined by the environment variable `REACT_APP_ENVIRONMENT`
 */
let TRAVELPACK_LINK: string;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "prod":
    TRAVELPACK_LINK = "https://m.lottecard.co.kr/app/LPPAYCN_V100.lc";
    break;
  case "dev":
    TRAVELPACK_LINK = "https://dmo2.lottecard.co.kr/app/LPPAYCN_V100.lc";
    break;
  default:
    TRAVELPACK_LINK = "https://m.lottecard.co.kr/app/LPPAYCN_V100.lc";
    break;
}

export default TRAVELPACK_LINK;
