import React from "react";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface StationListSectionHeaderProps {
  id: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const StationListSectionHeader = ({
  id,
  onClick,
}: StationListSectionHeaderProps) => {
  const intl = useIntl();

  return (
    <Wrapper direction="row" justifyContent="space-between" alignItems="center">
      <h4 className="font-bold">
        {intl.formatMessage({ id: `stationSelectionPopup.${id}` })}
      </h4>
      {onClick && (
        <IconButton onClick={onClick} size="small" sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      )}
    </Wrapper>
  );
};

export default StationListSectionHeader;

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.tertiary.light,
  // backgroundColor: "#3376F6",
  // color: "#ffffff",

  "& svg": {
    color: theme.palette.tertiary.light,
  },

  padding: 8,
  marginBottom: 8,

  h4: {
    paddingLeft: 8,
  },
}));
