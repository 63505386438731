// import { ProviderUtils } from "./providerUtils";

// const providerTWPrefixes = ProviderUtils.prodProviderList
//   .map((Provider) => Provider.twPrefix)
//   .filter((twPrefix) => twPrefix);

// console.log("providerTWPrefixes", providerTWPrefixes);

/**
 * Generate a list of tailwind utility classes with different prefixes.
 * @param {string} twUtility - tailwind utility (e.g. text, bg, border, etc.)
 * @param {string} twValue - tailwind value (e.g. sm, md, lg, etc.)
 * @returns {string} - a string of tailwind classes
 * @example
 * generateTWClasses('bg', 'primary-light')
 *  returns 'bg-primary-light bc:bg-bc-primary-light lotte:bg-lotte-primary-light hana:bg-hana-primary-light gme:bg-gme-primary-light redtable:bg-redtable-primary-light'
 */
// export const generateTWClasses = (twUtility: string, twValue: string) => {
//   const baseTWClass = `${twUtility}-${twValue}`;

//   const twClasses = providerTWPrefixes.map((twPrefix) => {
//     return `${twPrefix}:${twUtility}-${twPrefix}-${twValue}`;
//   });

//   const finalClasses = `${baseTWClass} ${twClasses.join(" ")}`;

//   return finalClasses;
// };

export const providerClasses = {
  "bg-primary":
    "bg-primary bc:bg-bc-primary lotte:bg-lotte-primary hana:bg-hana-primary gme:bg-gme-primary redtable:bg-redtable-primary",
  "bg-primary-light":
    "bg-primary-light bc:bg-bc-primary-light lotte:bg-lotte-primary-light hana:bg-hana-primary-light gme:bg-gme-primary-light redtable:bg-redtable-primary-light",
  "bg-secondary":
    "bg-secondary bc:bg-bc-secondary lotte:bg-lotte-secondary hana:bg-hana-secondary gme:bg-gme-secondary redtable:bg-redtable-secondary",
  "bg-secondary-light":
    "bg-secondary-light bc:bg-bc-secondary-light lotte:bg-lotte-secondary-light hana:bg-hana-secondary-light gme:bg-gme-secondary-light redtable:bg-redtable-secondary-light",
  "bg-banner":
    "bg-banner-bg bc:bg-bc-banner-bg lotte:bg-lotte-banner-bg hana:bg-hana-banner-bg gme:bg-gme-banner-bg redtable:bg-redtable-banner-bg",

  "text-primary":
    "text-primary bc:text-bc-primary lotte:text-lotte-primary hana:text-hana-primary gme:text-gme-primary redtable:text-redtable-primary",
  "text-primary-light":
    "text-primary-light bc:text-bc-primary-light lotte:text-lotte-primary-light hana:text-hana-primary-light gme:text-gme-primary-light redtable:text-redtable-primary-light",
  "text-secondary":
    "text-secondary bc:text-bc-secondary lotte:text-lotte-secondary hana:text-hana-secondary gme:text-gme-secondary redtable:text-redtable-secondary",
};
