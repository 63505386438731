import { FormattedMessage, useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import AlarmIcon from "@mui/icons-material/Alarm";
import ForumIcon from "@mui/icons-material/Forum";
import useLanguages from "hooks/useLanguages";
import CardLayout from "components/layout/CardLayout";

const CustomerCenterPage = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  return (
    <Layout text={intl.formatMessage({ id: "cs.title" })}>
      <CardLayout className="my-4 py-8">
        <div className="space-y-8 text-center">
          <div>
            <ForumIcon fontSize="large" />
            <p className="mb-2 text-2xl">
              <FormattedMessage id="cs.message" />
            </p>
            <p>{isKorean ? "이메일" : "Email"}: help@hcclab.com</p>
            <p>{isKorean ? "전화번호" : "Tel"}: 02-568-1220</p>
          </div>
          <div>
            <AlarmIcon fontSize="large" />
            <p>
              <FormattedMessage id="cs.weekday" /> 09:00~18:00
            </p>
            <p>
              <FormattedMessage id="cs.lunch" /> 12:00~13:00
            </p>
            <p>
              <FormattedMessage id="cs.holiday" />
            </p>
          </div>
          {/* <Button
            onClick={goToNotice}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            >
            {intl.formatMessage({ id: "cs.notice" })}
          </Button> */}
        </div>
      </CardLayout>
    </Layout>
  );
};

export default CustomerCenterPage;
