import React, { useCallback } from "react";
import AuthService from "services/authService";
import CostDetailsSection from "./CostDetails";
import TrainInfoSection from "./TrainInfos";
import { ScheduleType } from "types/scheduleType";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardLayout from "components/layout/CardLayout";

interface ScheduleItemProps {
  schedule: ScheduleType;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // expanded: boolean;
  // onToggleExpand: () => void;
}

const ScheduleItem = ({
  schedule,
  setModalVisible,
  // expanded,
  // onToggleExpand,
}: ScheduleItemProps) => {
  const isLoggedIn = useCallback(async () => {
    if (!(await AuthService.isLoggedIn())) {
      setModalVisible(true);
      return false;
    }
    return true;
  }, [setModalVisible]);

  // 요금조회 버튼 클릭 핸들러
  // const handleToggleDetails = useCallback(async () => {
  //   onToggleExpand();
  // }, [onToggleExpand]);

  const isSoldOut = schedule.gnrmRsvPsbFlg === "N";

  const getAdditionalInfo = () => {
    if (isSoldOut) {
      return;
    }

    // if (!expanded) {
    //   return (
    //     !isSoldOut && (
    //       <LoadingSpinner className="h-[88px]" />

    // <div className="flex w-full justify-end">
    //   <CustomButton
    //     // onClick={handleToggleDetails}
    //     variant="text"
    //     size="small"
    //     fullWidth={false}
    //     className="!p-0"
    //   >
    //     {/* 요금 조회 */}
    //     {intl.formatMessage({
    //       id: "fareInquiry.inquiryPrice",
    //       defaultMessage: "Inquiry",
    //     })}
    //     <NavigateNextIcon fontSize="small" />
    //   </CustomButton>
    // </div>
    //     )
    //   );
    // }

    return (
      // <Collapse
      //   // in={expanded}
      //   in={true}
      //   className="w-full"
      // >
      <div className="relative mt-2 flex w-full">
        <CostDetailsSection schedule={schedule} isLoggedIn={isLoggedIn} />
      </div>
      // </Collapse>
    );
  };

  return (
    <CardLayout additionalInfo={getAdditionalInfo()} className="w-full p-3">
      <TrainInfoSection schedule={schedule} />
    </CardLayout>
  );
};

export default ScheduleItem;
