import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { HanpassCouponInterface } from "services/userService";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";

interface HanpassBottomSheetProps {
  coupons: HanpassCouponInterface[];
  setBottomSheetVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCoupon: React.Dispatch<
    React.SetStateAction<HanpassCouponInterface | null | undefined>
  >;
}

const HanpassBottomSheet = ({
  coupons,
  setBottomSheetVisible,
  setSelectedCoupon,
}: HanpassBottomSheetProps) => {
  const theme = useTheme();

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  });

  const BottomSheetHeader = ({
    position,
  }: {
    position: "fixed" | "relative";
  }) => {
    return (
      <Stack
        sx={{
          visibility: position === "fixed" ? "visible" : "hidden",
          width: "100%",
          position,
          px: position === "fixed" ? 2 : 0,
          pt: 1,
          bgcolor: theme.palette.white.main,
          borderRadius: 1,
        }}
      >
        <Box alignSelf="end" onClick={() => setBottomSheetVisible(false)}>
          <CloseIcon />
        </Box>
        <Typography
          alignSelf="start"
          variant="h6"
          sx={{ fontWeight: "bold", lineHeight: 0.5 }}
        >
          {/* {intl.formatMessage({ id: "payment.paymentMethodSelect" })} */}
          사용할 쿠폰을 선택해주세요.
        </Typography>
        <Typography alignSelf="end" variant="body2" sx={{ fontWeight: "bold" }}>
          {/* {intl.formatMessage({ id: "payment.paymentMethodSelect" })} */}
          쿠폰 수{" "}
          <Typography
            variant="h6"
            component="span"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            {coupons.length}
          </Typography>
          장
        </Typography>
        <Divider></Divider>
      </Stack>
    );
  };

  const selectCoupon = (coupon: HanpassCouponInterface | undefined) => {
    setSelectedCoupon(coupon);
    setBottomSheetVisible(false);
  };

  return (
    <>
      <CardLayout
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          maxHeight: "80vh",
          width: "100%",
          borderEndEndRadius: 0,
          borderEndStartRadius: 0,
          zIndex: 10000,
          overflowY: "scroll",
        }}
        className="pt-0"
      >
        <BottomSheetHeader position="fixed" />
        <BottomSheetHeader position="relative" />
        <Stack gap={1} sx={{ width: "100%" }}>
          {coupons.map((coupon) => {
            return (
              <div
                key={coupon.memberCouponSeq}
                onClick={() => selectCoupon(coupon)}
              >
                <HanpassCoupon coupon={coupon} />
              </div>
            );
          })}
          <Box
            onClick={() => {
              selectCoupon(undefined);
            }}
            sx={{
              width: "100%",
              bgcolor: theme.palette.grey[200],
              borderRadius: 0.5,
              p: 0.7,
            }}
          >
            <CardLayout
              style={{
                width: "100%",
                borderRadius: 0.5,
              }}
              className="bg-white"
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", width: "100%", textAlign: "start" }}
              >
                쿠폰 적용안함
              </Typography>
            </CardLayout>
          </Box>
        </Stack>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            mt: 1,
            display: "block",
            textAlign: "start",
            width: "100%",
            wordBreak: "keep-all",
          }}
        >
          * 1회 결제 시 1개의 쿠폰만 사용 가능합니다. <br />
          * 쿠폰이 선택되지 않은 경우 자동 환불되지 않습니다. <br />
          * 일부 좌석, 시간, 노선 쿠폰은 발매되지 않습니다. <br />* 임의로
          쿠폰을 정해진 수량에 따라 선착순 마감될 수 있습니다.
        </Typography>
      </CardLayout>
      <Box
        sx={{
          bgcolor: "#2228",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 2000,
        }}
      />
    </>
  );
};

export default HanpassBottomSheet;

const HanpassCoupon = ({
  coupon: {
    couponDescription: name,
    couponValue: amount,
    expiredDate,
    couponType,
  },
}: {
  coupon: HanpassCouponInterface;
}) => {
  const theme = useTheme();
  const formatPrice = useFormatPrice();

  const getAdditionalInfo = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%" }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          만료일: {expiredDate.replace("T", " ")}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          사용가능
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.grey[200],
        borderRadius: 0.5,
        p: 0.7,
      }}
    >
      <CardLayout
        additionalInfo={getAdditionalInfo()}
        style={{
          borderRadius: 0.5,
        }}
        className="bg-white p-2"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ width: "100%", px: 1 }}
        >
          <CardGiftcardIcon
            fontSize="large"
            color="warning"
            sx={{ fontSize: "3rem" }}
          />
          <Stack>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {name}
            </Typography>
            {couponType !== "FREE" && (
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", textAlign: "start" }}
              >
                {couponType === "AMOUNT"
                  ? formatPrice(amount)
                  : `${amount}% Discount`}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardLayout>
    </Box>
  );
};
