import BookingInfos from "../../components/booking_info/BookingInfos";
import QRCode from "components/QRCode";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useNavigate } from "react-router-dom";
import TickerNotice from "pages/tickets/components/TickerNotice";
import type { OrderType } from "types/orderType";
import { useEffect, useState } from "react";
import PaymentService from "services/paymentService";
import LoadingSpinner from "components/LoadingSpinner";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

interface TicketProps {
  reservationDetails?: OrderType;
}

const Ticket = ({ reservationDetails: order }: TicketProps) => {
  const navigate = useNavigate();

  const [reservationDetails, setReservationDetails] = useState<
    OrderType | undefined
  >(undefined);

  // Fetch reservation details from server
  useEffect(() => {
    if (order) {
      const orderId = order.orderId;
      PaymentService.reserveDetail(Number(orderId))
        .then((data) => {
          setReservationDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
          navigate(-1);
        });
    }
  }, [navigate, order]);

  const state = useLocation().state ?? reservationDetails;
  const productType = state.productType;
  const voucher = state.travelGoods?.voucher[0];

  // const ticketType = voucher?.type;
  const ticketUrl =
    voucher?.voucherUrl ??
    voucher?.pdfUrl ??
    voucher?.qrcodeUrl ??
    voucher?.code;

  return !state ? (
    <LoadingSpinner className="h-1/2" />
  ) : productType === "TRAIN" ? (
    state.orderStatus === "COMPLETE" ? (
      <div
        className={clsx(
          "mb-4 mt-4 break-keep px-0",
          reservationDetails && "mt-0"
        )}
      >
        <BookingInfos
          order={state}
          qrCode={
            <QRCode
              src={`data:image/png;base64,${state?.trainList[0].qrCodeImage}`}
            />
          }
        />
        <TickerNotice />
      </div>
    ) : (
      <div className="my-12 flex min-h-[50dvh] flex-col items-center justify-center gap-2 text-center">
        <CancelIcon className="!text-[120px] text-error" />
        <p className="max-w-[280px] break-keep text-xl font-bold">
          <FormattedMessage id="orders.canceledTrainTicket" />
        </p>
      </div>
    )
  ) : (
    <iframe
      src={ticketUrl}
      title="ticket"
      className="absolute left-0 h-screen w-screen"
    />
  );
};

export default Ticket;
