import { List, ListItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";
import CardLayout from "components/layout/CardLayout";
import clsx from "clsx";

const BookingGuidelines = () => {
  const { isKorean } = useLanguages();

  const guidelines = [
    // <>
    //   <FormattedMessage id="booking.noSoloTrainReservation" />{" "}
    //   <FormattedMessage id="booking.vouchersDelivery" />
    // </>,
    // <FormattedMessage id="booking.discountForAdultsOnly" />,
    // <FormattedMessage id="booking.expectedPointRates" />,
    <FormattedMessage id="booking.optionProducts" />,
    <FormattedMessage id="booking.cancellationPolicy" />,
    <FormattedMessage id="booking.noChangeAfterReservation" />,
    <FormattedMessage id="booking.generalSeatReservation" />,
    <FormattedMessage id="booking.differentLook" />,
    <FormattedMessage id="booking.realTimeReservations" />,
    <FormattedMessage id="booking.purchaseLimit" />,
    <FormattedMessage id="booking.notProvidedFeatures" />,
    <FormattedMessage id="booking.reservationRestrictions" />,
  ];

  const isPayboocKorean = isKorean && ProviderUtils.isPaybooc;

  return (
    <CardLayout>
      <p className="mb-2 text-lg font-bold">
        <FormattedMessage id="booking.mustReadBeforePurchase" />
      </p>
      <List className="!p-0">
        {guidelines.map((text, index) => (
          <ListItem key={index} className="!p-1">
            <div className="flex">
              <p
                className="me-2 leading-4"
                // style={{ marginInlineEnd: "8px" }}
              >
                •
              </p>
              <p
                className={clsx(`text-xs text-text-secondary`, {
                  "font-bold text-text-primary underline":
                    index < (isPayboocKorean ? 4 : 3),
                })}
              >
                {text}
              </p>
            </div>
          </ListItem>
        ))}
      </List>
    </CardLayout>
  );
};

export default BookingGuidelines;
