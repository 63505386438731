import LachaCardLogo from "assets/images/logo/lacha_card_logo.png";
import LachaCardImage from "assets/images/lacha_card_image.png";
import StarIcon from "@mui/icons-material/Star";
import CardLogo from "./CardLogo";
import { CardInterface } from "app/cardsSlice";
import { FormattedMessage } from "react-intl";
import CardLayout from "components/layout/CardLayout";
import clsx from "clsx";

interface CardProps {
  cardNumber: string;
  expirationDate?: string;
  isMainCard?: CardInterface["rpstCrdYn"];
  isDisabled?: boolean;
  isRegister?: boolean;
}

export const formatCardNumber = (cardNumber: string, divider = " ") => {
  const cn1 = cardNumber.slice(0, 4);
  const cn2 = cardNumber.slice(4, 8);
  const cn3 = cardNumber.slice(8, 12);
  const cn4 = cardNumber.slice(12, 16);

  return `${cn1}${divider}${cn2}${divider}${cn3}${divider}${cn4}`;
};

const Card = ({
  cardNumber,
  expirationDate,
  isMainCard,
  isDisabled = false,
  isRegister = false,
}: CardProps) => {
  return (
    <CardLayout
      className={clsx("mx-1 select-none bg-[#4A494A] text-white", {
        "opacity-50": isDisabled,
      })}
    >
      <div className="relative grid h-[calc((100vw_-_32px)/2.1)] max-h-60 min-h-32 w-full grid-rows-3">
        <CardLogo src={LachaCardLogo} alt="LACHA Logo" />
        <img
          src={LachaCardImage}
          alt="LACHA Card"
          className="absolute -bottom-5 -right-5 h-[120%]"
        />
        <div className="content-center">
          {isRegister && <p className="text-start text-xs">CARD No.</p>}
          <p className="relative z-10 text-xl font-bold">
            {formatCardNumber(cardNumber)}
          </p>
        </div>
        {isMainCard === "Y" && (
          <div className="flex items-end gap-1">
            <StarIcon color="warning" fontSize="small" />
            <p className="text-sm font-bold">
              <FormattedMessage id="card.default" />
            </p>
          </div>
        )}
        {isRegister && (
          <div className="self-end">
            <p className="text-start text-xs">VALID THRU</p>
            <p className="text-start font-bold">{expirationDate}</p>
          </div>
        )}
      </div>
    </CardLayout>
  );
};

export default Card;
