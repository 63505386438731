import InfoIcon from "@mui/icons-material/Info";
import RemoveIcon from "@mui/icons-material/Remove";
import { useIntl } from "react-intl";
import { providerClasses } from "utils/tailwindUtils";

const TickerNotice = () => {
  const intl = useIntl();

  return (
    <div className="mt-4 px-3" style={{ paddingInlineEnd: 1 }}>
      <div
        className={`flex text-sm font-bold ${providerClasses["text-primary"]}`}
      >
        <div className="flex items-center justify-center self-st">
          <InfoIcon className="!h-5" />
        </div>
        {intl.formatMessage({ id: "orders.caution1" })}
      </div>
      <div className="space-y-1 p-1">
        <p className="flex items-center text-xs">
          <div className="flex items-center justify-center self-start">
            <RemoveIcon className="!h-5 !w-3" sx={{ marginInlineEnd: 0.5 }} />
          </div>
          {intl.formatMessage({ id: "orders.caution2" })}
        </p>
        <p className="flex items-center text-xs">
          <div className="flex items-center justify-center self-start">
            <RemoveIcon className="!h-5 !w-3" sx={{ marginInlineEnd: 0.5 }} />
          </div>
          {intl.formatMessage({ id: "orders.caution3" })}
        </p>
        <div className="flex items-center">
          <div className="flex items-center justify-center self-start">
            <RemoveIcon className="!h-5 !w-3" sx={{ marginInlineEnd: 0.5 }} />
          </div>
          <div className="flex flex-col">
            <p
              className={`${providerClasses["text-primary"]} text-sm font-bold`}
            >
              {`${intl.formatMessage({
                id: "orders.contact",
              })} help@hcclab.com `}
            </p>
            <p className="text-xs">
              {`(${intl.formatMessage({
                id: "cs.weekday",
              })} 09:00~17:00 / ${intl.formatMessage({
                id: "cs.holiday",
              })})`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TickerNotice;
