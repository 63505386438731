import { Container } from "@mui/system";
import Layout from "components/layout/Layout";
import TransactionList from "./components/TransactionList";
import { useCallback, useEffect, useState } from "react";
import usePeriodFilter from "hooks/usePeriodFilter";
import CardService, { TransactionInterface } from "services/cardService";
import { useParams } from "react-router-dom";
import { NumericString } from "types/numericString";
import { FormattedMessage, useIntl } from "react-intl";
import { calculateCurrentDate, type DateType } from "utils/dateUtils";
import { TextField } from "@mui/material";
import useLanguages from "hooks/useLanguages";
import CardLayout from "components/layout/CardLayout";
import useDebounce from "hooks/useDebounce";
import CustomButton from "components/button/CustomButton";

const CardTransactions = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const debounce = useDebounce(500);
  const { fromDate, setFromDate, toDate, setToDate } = usePeriodFilter("1");

  const { cardNumber } = useParams() as { cardNumber: NumericString };
  const [transactionList, setTransactionList] = useState<
    TransactionInterface[]
  >([]);

  // const theme = useTheme();
  // const [searchTerm, setSearchTerm] = useState("");

  // const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleSearch = () => {
  //   // TODO: Filter transactions results based on searchTerm
  //   console.log(searchTerm);
  // };

  // const setFromDate = (value: string, type: "from" | "to") => {};

  /**
   * Sets the date for either the "from" or "to" date range.
   *
   * If the type is "from", it updates the starting date and ensures that the "to" date
   * is not more than 30 days ahead or behind the "from" date. If the "to" date is outside
   * this range, it adjusts the "to" date to be exactly 30 days after the "from" date.
   *
   * If the type is "to", it updates the ending date and ensures that the "from" date
   * is not more than 30 days before or after the "to" date. If the "from" date is outside
   * this range, it adjusts the "from" date to be exactly 30 days before the "to" date.
   *
   * @param {string} value - The date string to set, in the format "YYYY-MM-DD".
   * @param {"from" | "to"} type - Specifies whether to set the "from" or "to" date.
   */

  const setDate = (value: string, type: "from" | "to") => {
    const newDate = new Date(value);

    if (type === "from") {
      setFromDate(value as DateType);
      const current = new Date();
      const maxDate = new Date(newDate);
      maxDate.setDate(maxDate.getDate() + 30);

      const daysDiff =
        (new Date(toDate).valueOf() - newDate.valueOf()) /
        (1000 * 60 * 60 * 24);

      if (daysDiff > 30 || daysDiff < 0) {
        // setToDate(maxDate.toISOString().split("T")[0]);
        setToDate(
          (current > maxDate
            ? maxDate.toISOString().split("T")[0]
            : current.toISOString().split("T")[0]) as DateType
        );
      }
    } else {
      setToDate(value as DateType);
      const minDate = new Date(newDate);
      minDate.setDate(minDate.getDate() - 30);

      const daysDiff =
        (newDate.valueOf() - new Date(fromDate).valueOf()) /
        (1000 * 60 * 60 * 24);

      if (daysDiff > 30 || daysDiff < 0) {
        setFromDate(minDate.toISOString().split("T")[0] as DateType);
      }
    }
  };

  const getTransactionList = useCallback(async () => {
    const { data } = await CardService.getTransactionList(
      cardNumber,
      fromDate.replace(/-/g, "") as DateType,
      toDate.replace(/-/g, "") as DateType
    );

    if (data) {
      setTransactionList(data.list);
    }
  }, [cardNumber, fromDate, toDate]);

  useEffect(() => {
    if (cardNumber) {
      debounce(getTransactionList);
    }
  }, [cardNumber, debounce, getTransactionList]);

  return (
    <Layout text={intl.formatMessage({ id: "card.title" }, { cardNumber })}>
      {/* Search Bar */}
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ width: "98%", mx: "auto" }}
      >
        <TextField
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          color="primary"
          sx={{
            width: "100%",
            "& .MuiInputBase-input": {
              py: 0,
              height: "40px",
              borderRadius: "16px",
              bgcolor: theme.palette.white.main,
            },
          }}
        />
        <CustomButton
          id="stationSelectionPopup.searchButtonText"
          size="medium"
          fullWidth={false}
          onClick={handleSearch}
        />
      </Stack> */}
      <CardLayout className="mt-4">
        <p className="font-bold">기간 조회</p>
        <p className="text-sm font-normal text-text-secondary">
          (최대 30일 동안의 내역만 조회 가능합니다.)
        </p>
        <div className="my-4 flex gap-2">
          <TextField
            label={isKorean ? "부터" : "from"}
            type="date"
            value={fromDate}
            onChange={(e) => setDate(e.target.value, "from")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                max: calculateCurrentDate(),
                // min: calculateDateBeforeOneMonth(new Date(toDate)),
              },
            }}
            className="w-full bg-white"
          />
          <TextField
            label={isKorean ? "까지" : "to"}
            type="date"
            value={toDate}
            onChange={(e) => setDate(e.target.value, "to")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { max: calculateCurrentDate() } }}
            className="w-full bg-white"
          />
        </div>
        <CustomButton onClick={getTransactionList}>조회</CustomButton>
      </CardLayout>

      <Container maxWidth="sm" sx={{ width: "100%", mt: 1, px: 0 }}>
        <CardLayout>
          <p className="mb-2 text-xl font-bold">
            <FormattedMessage id="card.transactions" />
          </p>

          <TransactionList transactions={transactionList} />
        </CardLayout>
      </Container>
    </Layout>
  );
};

export default CardTransactions;
