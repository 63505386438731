import { Typography } from "@mui/material";
import Arrow from "components/Arrow";
import StationInfo from "components/booking_info/StationInfo";
import CustomButton from "components/button/CustomButton";
import TrainLogo from "components/TrainLogo";
import CardLayout from "components/layout/CardLayout";
import React from "react";
import { useIntl } from "react-intl";

interface StationDetails {
  departure: string;
  arrival: string;
  startDate: string;
  endDate: string;
}

interface TravelDetails {
  trainNumber: string;
  duration: React.ReactElement;
}

interface TicketContentProps {
  stationDetails: StationDetails;
  travelDetails: TravelDetails;
  ticketHeader: React.ReactNode;
  ticketAdditionalInfo: React.ReactNode;
}

const TicketContent = ({
  stationDetails,
  travelDetails,
  ticketHeader,
  ticketAdditionalInfo,
}: TicketContentProps) => {
  const intl = useIntl();

  return (
    <>
      <CustomButton
        size="medium"
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        {intl.formatMessage({ id: "orders.trainTicket" })}
      </CustomButton>
      <CardLayout
        header={ticketHeader}
        additionalInfo={ticketAdditionalInfo}
        className="rounded-t-none"
      >
        <div className="flex w-full items-center justify-between gap-2">
          <TrainLogo size="large" />
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {travelDetails.trainNumber}
          </Typography>
        </div>
        {/* Departure and Arrival Stations */}
        <div className="flex w-full items-center justify-center gap-4">
          <StationInfo status="DEPARTURE" detail={stationDetails} />
          <Arrow />
          <StationInfo status="ARRIVAL" detail={stationDetails} />
        </div>
        <p className="mb-2 text-center">{travelDetails.duration}</p>
      </CardLayout>
    </>
  );
};

export default TicketContent;
