import { Skeleton } from "@mui/material";
import clsx from "clsx";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { providerClasses } from "utils/tailwindUtils";
import { TravelGood } from "services/travelService";

// export interface ActivityThumbnailType {
//   goodsId: number;
//   goodsName: string;
//   thumbnailPath: string;
//   salePrice: number;
//   marketPrice: number
// }

interface ActivityThumbnailProps {
  activity: TravelGood;
  direction: "row" | "column";
}

const ActivityThumbnail = ({ activity, direction }: ActivityThumbnailProps) => {
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  const isDiscounted =
    activity.salePrice && activity.marketPrice
      ? activity.salePrice < activity.marketPrice
      : false;

  return (
    <CardLayout className="rounded-none p-0 active:scale-[1.02]">
      <section
        className={clsx(
          "flex",
          direction === "column" && "flex-col"
          // direction === "row" && "grid grid-cols-12"
        )}
        onClick={() => navigate(`/activity/${activity.goodsId}`)}
      >
        {/*        <section
          className={clsx(
            "relative flex h-[calc(1)] w-full items-center",
            // "relative col-span-5 flex h-[120px] items-center justify-center overflow-hidden",
            // {
            //   "max-h-32 min-h-28": direction === "column",
            //   "max-h-[120px] min-h-28": direction === "row",
            // }
            {
              "justify-center": direction === "column",
              "justify-start": direction === "row",
            }
          )}
        >
         <ThumbnailImage
            src="https://qnimg.zowoyoo.com/img/442048/1653445259709.jpg"
            // src={activity.thumbnailPath}
            alt={activity.goodsName}
            direction={direction}
          /> 
           <LowestPriceBadge direction={direction} /> 
        </section> */}

        <ThumbnailImage
          // src="https://qnimg.zowoyoo.com/img/442048/1653445259709.jpg"
          src={activity.thumbnailPath}
          alt={activity.goodsName}
          direction={direction}
        />
        <section
          className={clsx(
            "flex w-full flex-col justify-between gap-4 p-2 pl-3 text-start",
            direction === "row" && "h-[108px]"
          )}
        >
          <h6
            className="overflow-hidden text-ellipsis break-normal text-[14px] font-bold tracking-tight"
            style={{
              display: "-webkit-box" /* Required for line-clamp */,
              WebkitBoxOrient: "vertical" /* Required for line-clamp */,
              WebkitLineClamp: 3 /* Set the maximum number of lines to 2 */,

              /* Both of the following are required for text-overflow */
              // textOverflow: "ellipsis",
              // overflow: "hidden",
            }}
          >
            {activity.goodsName}
          </h6>

          <div className="flex flex-col justify-end self-end">
            {isDiscounted && (
              <p
                className={`self-end break-keep pr-1 text-end text-[13px] font-bold text-text-secondary line-through`}
              >
                {formatPrice(activity.marketPrice)}
              </p>
            )}
            {activity.salePrice && (
              <p
                className={`self-end break-keep pr-1 text-sm font-bold ${providerClasses["text-secondary"]}`}
              >
                {formatPrice(activity.salePrice)} ~
              </p>
            )}
          </div>
        </section>
      </section>
    </CardLayout>
  );
};

export default ActivityThumbnail;

interface ThumbnailImageProps {
  src: string;
  alt: string;
  direction: "row" | "column";
}

const ThumbnailImage = ({ src, alt, direction }: ThumbnailImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={clsx(`!h-full`, !isLoading && "opacity-0")}
      />
      <img
        src={src}
        alt={alt}
        // style={{
        //   backgroundSize: "fill",
        //   backgroundPosition: "center",
        // }}
        className={clsx("aspect-[225/142] self-center object-fill opacity-0", {
          "h-[108px]": direction === "row",
          "opacity-100": !isLoading,
        })}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

// interface MarkProps {
//   direction: "row" | "column";
// }

// const LowestPriceBadge = ({ direction }: MarkProps) => {
//   return (
//     <div
//       className="absolute -right-1 bottom-1 z-50"
//       //  className="absolute -left-3 -top-[14px] z-50"
//     >
//       {/* <BookmarkIcon className="!text-[80px] text-orange-300" /> */}
//       <Chip
//         label="국내 최저가"
//         size="small"
//         className="mr-2 !rounded-md !bg-[#FFDBC3] px-0 py-3 font-bold !text-[#FF4C18]"
//       />
//       {/* <p className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-60%] break-keep rounded-lg bg-[#FFDBC3] p-1 text-center text-xs font-bold leading-4 text-[#FF4C18]">
//         국내 최저가
//       </p> */}
//     </div>
//   );
// };
