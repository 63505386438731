import DateWrapper from "components/layout/DateWrapper";
import { formatDateToISOString } from "utils/formatUtils";
import { useAppSelector } from "app/hooks";
import CardLayout from "components/layout/CardLayout";

const DateRange = () => {
  const {
    filterSettings: { fromDate, toDate },
  } = useAppSelector((state) => state.myOrders);
  // 날짜 범위 상태
  const dateRange = {
    start: formatDateToISOString(fromDate ? new Date(fromDate) : new Date()),
    end: formatDateToISOString(toDate ? new Date(toDate) : new Date()),
  };

  return (
    <CardLayout className="rounded-t-none p-0">
      <DateWrapper>
        <p>
          {dateRange.start} ~ {dateRange.end}
        </p>
      </DateWrapper>
    </CardLayout>
  );
};

export default DateRange;
