import React, { useEffect, useRef } from "react";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { ProviderUtils } from "utils/providerUtils";
import CloseIcon from "@mui/icons-material/Close";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import LogoutButton from "components/button/LogoutButton";
import { useMyInfoQuery } from "app/apiSlice";
import ReservationListIcon from "components/hanacard/ReservationListIcon";
import LogoImage from "./LogoImage";

interface LayoutProps {
  children: React.ReactNode;
  text?: string | React.ReactElement;
  onBack?: () => void;
  footer?: boolean;
}

const Layout = ({ children, text, onBack, footer = true }: LayoutProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navBar = useRef<HTMLDivElement>(null);
  const { data: user } = useMyInfoQuery();

  const isJTRPage = pathname.startsWith("/activity");
  const isJTRHome = pathname === "/activity";

  // My예약 페이지에서 뒤로 가기 시, Activity Home으로 redirect하도록 설정하기 위해 state 전달
  const handleJTRMenuClick = () => {
    navigate("user-settings", {
      state: {
        isJTRPage,
      },
    });
  };

  useEffect(() => {
    const id = setTimeout(() => {
      navBar.current?.scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    }, 0);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <header className="flex min-h-[calc(100vh_-_env(safe-area-inset-top))] flex-col justify-between overflow-hidden">
      <AppBar
        ref={navBar}
        className="fixed !bg-white !shadow-md"
        sx={{
          height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,
          paddingTop: `env(safe-area-inset-top)`,
        }}
      >
        <Toolbar
          variant="dense"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isJTRHome && ProviderUtils.isHanacard ? (
            <LogoImage />
          ) : (
            <IconButton
              onClick={onBack ? onBack : () => navigate(-1)}
              size="small"
              edge="start"
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <h1 className="grow-1 absolute left-1/2 -translate-x-1/2 text-center font-bold text-black">
            {text}
          </h1>
          {ProviderUtils.isCard && user && (
            <div className="absolute right-6 flex items-center gap-2 text-black">
              <LogoutButton size="small" icon={false} />
              <LanguageSelectIcon type="ALL" />
            </div>
          )}
          {/* {ProviderUtils.darkModeAvailable && <ThemeToggle />} */}
          {ProviderUtils.isLottecard &&
            window.location.pathname === "/order-history" && (
              <div className="text-black" onClick={closeWebview}>
                <CloseIcon />
              </div>
            )}
          {isJTRPage && (
            <section className="flex items-center gap-2 [&>*]:cursor-pointer">
              <LanguageSelectIcon />
              {/* {ProviderUtils.isHanacard ? ( */}
              <ReservationListIcon handleMenuClick={handleJTRMenuClick} />
              {/* ) : (
                <Menu
                  aria-label="menu"
                  onClick={handleMenuClick}
                  className="text-black"
                />
              )} */}
            </section>
          )}
        </Toolbar>
      </AppBar>

      <main className="m-auto mt-8 w-full max-w-lg p-4">{children}</main>
      {footer && <Footer />}
    </header>
  );
};

export default Layout;
