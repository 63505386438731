import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "components/layout/Layout";
import AuthService from "services/authService";
import BasicInfoUpdate from "./BasicInfoUpdate";
import ConsentInfoUpdate from "./ConsentInfoUpdate";
import { useIntl } from "react-intl";
import DeleteAccountButton from "components/button/DeleteAccountButton";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";
import useModalSheet from "hooks/overlay/useModalSheet";
import { useMyInfoMutation, useMyInfoQuery } from "app/apiSlice";
import useReduxUtils from "hooks/useReduxUtils";

export interface InfoUpdateProps {
  userInfo: {
    name: string;
    phone: string;
    birthDate: string;
    isReceivingEmail: boolean;
    isReceivingSms: boolean;
    isReceivingTalk: boolean;
    password?: string;
    currentPassword?: string;
    confirmPassword?: string;
  };
  setUserInfo: React.Dispatch<
    React.SetStateAction<InfoUpdateProps["userInfo"]>
  >;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonalInfoFormPage = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const { resetStore } = useReduxUtils({ retainToken: false });

  const [updateMyInfo, result] = useMyInfoMutation();
  const { data: user } = useMyInfoQuery();
  const {
    name = "",
    phone = "",
    birthDate = "",
    isReceivingEmail = false,
    isReceivingSms = false,
    isReceivingTalk = false,
  } = user ?? {};

  const [userInfo, setUserInfo] = useState({
    name,
    phone,
    birthDate: birthDate ?? "",
    isReceivingEmail,
    isReceivingSms,
    isReceivingTalk,
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const handleModalClose = () => {
    // dispatch(updateUser(userInfo));
    updateMyInfo(userInfo);
  };
  const [Modal, setModalVisible] = useModalSheet({
    callbackOk: handleModalClose,
  });

  const handleDeleteAccount = async () => {
    let conf = window.confirm(
      isKorean
        ? "정말 회원 탈퇴하시겠습니까?"
        : "Are you sure you want to delete your account?"
    );
    if (!conf) {
      return;
    }
    await AuthService.withdraw();
    resetStore();
    // dispatch(deleteUser());
    // dispatch(deleteReservation());
    // dispatch(deleteMyOrders());
    navigate("/");
  };

  const tabLabels = [
    intl.formatMessage({ id: "user.info" }),
    intl.formatMessage({ id: "user.consent" }),
  ];

  return (
    <>
      <Layout
        text={intl.formatMessage({ id: "user.info.change" })}
        onBack={() => navigate(-1)}
      >
        <div className="border-b-[1px] border-gray-300">
          <Tabs
            value={selectedTab}
            onChange={(_e, newValue) => setSelectedTab(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            role="navigation"
          >
            {tabLabels.map((label, index) => (
              <Tab key={index} label={label} sx={{ fontWeight: "bold" }} />
            ))}
          </Tabs>
        </div>

        <div
          className="mb-4 ms-0 mt-6 break-keep rounded-xl border border-gray-200 bg-white p-4 shadow-xl"
          // sx={{
          //   mt: 3,
          //   mb: 2,
          //   p: 2,
          //   marginInlineStart: 0,
          //   wordBreak: "keep-all",
          // }}
        >
          {selectedTab === 0 && (
            <BasicInfoUpdate
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setModalVisible={setModalVisible}
            />
          )}
          {selectedTab === 1 && (
            <ConsentInfoUpdate
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        {selectedTab === 0 && ProviderUtils.loginAvailable && (
          <DeleteAccountButton onClick={handleDeleteAccount} />
        )}
      </Layout>

      <Modal modal>
        <p className="text-xl">
          {intl.formatMessage({ id: "user.save.complete" })}
        </p>
      </Modal>
    </>
  );
};

export default PersonalInfoFormPage;
