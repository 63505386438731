import { TextField } from '@mui/material';
import { MutableRefObject, useState } from 'react';
import { useIntl } from 'react-intl';

interface AmountInputProps {
  amountRef: MutableRefObject<number>;
}

/**
 * 금액 입력 input UI
 *  */
const AmountInput = ({ amountRef }: AmountInputProps) => {
  const intl = useIntl();
  const [amount, setAmount] = useState("0");

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!Number(value)) {
      return;
    }

    amountRef.current = Number(value);
    setAmount(`${Number(value)}`);
  };

  return (
    <TextField
      fullWidth
      name="amount"
      value={amount}
      onChange={handleChangeAmount}
      InputProps={{
        inputProps: {
          min: 0,
          // max: balance,
        },
        sx: {
          height: "48px",

          "& .MuiInputBase-input": {
            textAlign: "end",
          },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
            margin: 0.3,
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0.3,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0.3,
          },
          "& input[type=text]": {
            "-moz-appearance": "textfield",
            margin: 1,
          },
          "& input[type=text]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 1,
          },
          "& input[type=text]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 1,
          },
        },
        endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
      }}
      placeholder="0"
    />
  );
};

export default AmountInput;
