export type DateType = `${number}-${number}-${number}`;

export const calculateCurrentDate = (): DateType => {
  return new Date().toISOString().split("T")[0] as DateType;
  // const date = new Date();
  // return `${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
};

export const calculateDateBeforeOneMonth = (
  date: Date = new Date()
): DateType => {
  date.setDate(date.getDate() - 31);
  return date.toISOString().split("T")[0] as DateType;
};

export const calculateDateBeforeOneYear = (): string => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString().split("T")[0];
};

export const getCurrentDateString = (): DateType => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}` as DateType;
};

/**
 * Returns a date string in the format "YYYYMMDD" that is 'n' months before the current date.
 * If 'n' is not provided or is 0, 1 month before the current date is returned.
 * If 'n' is greater than 12, 1 month before the current date is returned.
 *
 * @param {number | string} [n=1] - The number of months before the current date.
 * @returns {string} - A date string in the format "YYYYMMDD".
 */
export const getDateStringNMonthAgo = (n: number | string): DateType => {
  const monthNumber = Number(n) ? Number(n) : 1;
  const monthDiff = monthNumber > 12 || monthNumber < 1 ? 1 : monthNumber;

  const date = new Date();
  date.setMonth(date.getMonth() - monthDiff);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}` as DateType;
};

/**
 * Takes a date string in the format "YYYYMMDDHHMMSS" and returns a new string
 * in the format "YYYY-MM-DD HH:MM:SS"
 *
 * @param {string} date - The date string to parse
 * @returns {FormattedDateTimeString} The formatted date string
 */

type FormattedDateTimeString =
  `20${number}-${number}-${number} ${number}:${number}:${number}`;

export const formatDateTimeFromString = (
  date: string
): FormattedDateTimeString => {
  const [year, month, day, hour, minute, second] = date.match(/\d{2}/g) || [];

  const dateString = `20${year}.${month}.${day} ${hour}:${minute}:${second}`;
  return dateString as FormattedDateTimeString;
};
