import { SelectChangeEvent } from "@material-ui/core";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import { FormattedMessage } from "react-intl";

interface CardInfoProps {
  cardNumbers: [string, string, string, string];
  expirationDate: [string, string]; // [month, year]
  CVC: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeExpirationDate: (e: SelectChangeEvent<string>) => void;
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardInfo = ({
  cardNumbers,
  expirationDate,
  CVC,
  handleChange,
  handleChangeExpirationDate,
  setIsFlipped,
}: CardInfoProps) => {
  return (
    <CardLayout>
      <Grid
        container
        justifyContent="space-between"
        gap={2}
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12}>
          <div className="space-y-1">
            <label>
              <p className="font-bold">
                <FormattedMessage id="card.number" />
              </p>
            </label>
            <div className="flex gap-2">
              <TextField
                fullWidth
                type="text"
                name="cardNumber1"
                value={cardNumbers[0]}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    maxLength: 4,
                    sx: {
                      textAlign: "center",
                      p: 0,
                      height: 32,
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                type="password"
                name="cardNumber2"
                value={cardNumbers[1]}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    maxLength: 4,
                    sx: {
                      textAlign: "center",
                      p: 0,
                      height: 32,
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                type="password"
                name="cardNumber3"
                value={cardNumbers[2]}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    maxLength: 4,
                    sx: {
                      textAlign: "center",
                      p: 0,
                      height: 32,
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                type="text"
                name="cardNumber4"
                value={cardNumbers[3]}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    maxLength: 4,
                    sx: {
                      textAlign: "center",
                      p: 0,
                      height: 32,
                    },
                  },
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={8}>
          <div className="space-y-1">
            <label>
              <p className="font-bold">
                <FormattedMessage id="card.expiry" />
              </p>
              <div className="flex items-center gap-2">
                <FormControl fullWidth>
                  <Select
                    name="expirationDateMonth"
                    value={expirationDate[0]}
                    onChange={handleChangeExpirationDate}
                    displayEmpty
                    sx={{
                      height: 32,
                      textAlign: "center",
                    }}
                    IconComponent={() => null}
                    inputProps={{ sx: { p: "0 !important" } }}
                  >
                    <MenuItem value="" dense selected divider disabled>
                      <Typography color="text.secondary">Month</Typography>
                    </MenuItem>
                    {Array.from({ length: 12 }, (_, index) => (
                      <MenuItem
                        key={index}
                        value={String(index + 1).padStart(2, "0")}
                        dense
                      >
                        {String(index + 1).padStart(2, "0")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  /
                </Typography>
                <FormControl fullWidth>
                  <Select
                    name="expirationDateYear"
                    value={expirationDate[1]}
                    onChange={handleChangeExpirationDate}
                    displayEmpty
                    sx={{
                      height: 32,
                      textAlign: "center",
                    }}
                    IconComponent={() => null}
                    inputProps={{ sx: { p: "0 !important" } }}
                  >
                    <MenuItem value="" dense selected divider disabled>
                      <Typography color="text.secondary">Year</Typography>
                    </MenuItem>
                    {Array.from({ length: 20 }, (_, index) => {
                      const currentYear = new Date().getFullYear();
                      return (
                        <MenuItem
                          key={index}
                          value={String(currentYear + index - 2000)}
                          dense
                        >
                          {currentYear + index}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} onClick={(e) => e.stopPropagation()}>
          <div className="space-y-1">
            <label>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                CVC
              </Typography>
              <TextField
                fullWidth
                type="text"
                name="cvc"
                value={CVC}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    maxLength: 3,
                    sx: {
                      textAlign: "center",
                      p: 0,
                      height: 32,
                    },
                  },
                }}
                onFocus={() => {
                  setIsFlipped(true);
                }}
                onBlur={() => {
                  setIsFlipped(false);
                }}
              />
            </label>
          </div>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default CardInfo;
