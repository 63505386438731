import React from "react";
import { Menu, MenuItem } from "@mui/material";
import AuthService from "services/authService";
import { LanguageCodeType, languageCodes } from "const/languageCodes";
import { changeLanguage } from "app/userSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { logout } from "app/actionCreators";
import { StorageUtils } from "utils/storageUtils";
import { useMyInfoMutation } from "app/apiSlice";
import { ProviderUtils } from "utils/providerUtils";

interface LanguageSelectorProps {
  anchorEl: SVGSVGElement | null;
  handleClose: () => void;
  type?: "ALL" | "ENG";
}

const LanguageSelector = ({
  anchorEl,
  handleClose,
  type = "ALL",
}: LanguageSelectorProps) => {
  // redux state
  const dispatch = useAppDispatch();
  const { userId, language: currentLanguage } = useAppSelector(
    (state) => state.user
  );

  // const { data: user } = useMyInfoQuery();
  // const userId = user?.userId;
  // const currentLanguage = user?.language;

  const [updateUser, { error }] = useMyInfoMutation();

  const languageOptions =
    type === "ALL"
      ? languageCodes
      : ([
          { code: "en", name: "English" },
          { code: "ko", name: "한국어" },
        ] as const);

  // Action
  const handleLanguageChange = async (language: LanguageCodeType) => {
    // 페이지 언어 설정 변경
    handleClose();
    const updatedUserInfo = {
      // ...user, // assuming 'user' contains the current user's information
      language,
    };
    if (userId) {
      if (await AuthService.isLoggedIn()) {
        updateUser(updatedUserInfo);

        if (error) {
          console.error("Error updating language", error);
        }

        // UserService.update(updatedUserInfo)
        //   .then((response) => {
        //     console.log("Language updated successfully", response);
        //   })
        //   .catch((error) => {
        //     console.error("Error updating language", error);
        //   });
      } else {
        dispatch(logout());
      }
    }

    StorageUtils.Language.set(language);
    dispatch(changeLanguage(language));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={`z-[100000] ${ProviderUtils.twPrefix}`}
    >
      {languageOptions.map((language): React.ReactElement => {
        const isSelected = language.code === currentLanguage;
        return (
          <MenuItem
            key={language.code}
            onClick={() => handleLanguageChange(language.code)}
            autoFocus={isSelected}
            sx={
              isSelected
                ? {
                    color: "primary.main",
                    fontWeight: "bold",
                    bgcolor: "primary.light",
                  }
                : {}
            }
            // className={clsx(
            //   "",
            //   isSelected &&
            //     `!font-bold ${providerClasses["bg-primary-light"]} ${providerClasses["text-primary"]}`
            // )}
          >
            {language.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default LanguageSelector;
