import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import reservationReducer from "./reservationSlice";
import myOrdersReducer from "./myOrdersSlice";
import paymentReducer from "./paymentSlice";
import themeReducer from "./themeSlice";
import cardsReducer from "./cardsSlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./apiSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["myOrders"],
};

const myOrdersPersistConfig = {
  key: "myOrders",
  storage,
  whitelist: ["filterSettings"],
};

const appReducer = combineReducers({
  user: userReducer,
  reservation: reservationReducer,
  // myOrders: myOrdersReducer,
  myOrders: persistReducer(myOrdersPersistConfig, myOrdersReducer),
  payment: paymentReducer,
  theme: themeReducer,
  cards: cardsReducer,
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: Action
): ReturnType<typeof appReducer> => {
  if (action.type === "USER_LOGOUT") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    storage.removeItem("persist:myOrders");

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      // Adding the api middleware enables caching, invalidation, polling,
      // and other useful features of `rtk-query`.
      .concat(apiSlice.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

