import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
// import api from "./trainService";
// axios 인터셉터 설정
// const API_TOKEN_URL = "https://devfo.hcclab.com";
// "http://13.209.124.131:8081";
// "http://localhost:8081"; // Replace with your actual API auth URL
const tokenApi = axios.create({
  baseURL: apiBaseUrl,
});
// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     console.log(error);

//     // 401 오류 및 토큰 만료 메시지 확인
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       error.response.data.errorMessage === "ACCESS_TOKEN_EXPIRED" &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         const newAccessToken = await TokenService.refreshAccessToken(); // 리프레시 토큰으로 새 액세스 토큰 요청
//         console.log(newAccessToken);
//         TokenService.setAccessToken(newAccessToken); // 새 토큰 저장

//         // 원래 요청 헤더에 새 토큰 설정
//         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//         return axios(originalRequest); // 원래 요청 재시도
//       } catch (refreshError) {
//         console.error("Token refresh failed:", refreshError);
//         return Promise.reject(refreshError);
//       }
//     }

//     return Promise.reject(error);
//   }
// );
export const ACCESS_TOKEN = "lachaAccessToken";

class TokenService {
  // 액세스 토큰을 로컬 스토리지에 저장
  static setAccessToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  // 로컬 스토리지에서 액세스 토큰 가져오기
  static getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  // 액세스 토큰을 로컬 스토리지에서 삭제
  static removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  // 리프레시 토큰을 사용하여 액세스 토큰 재발급 요청
  static async refreshAccessToken(): Promise<string> {
    try {
      const response: AxiosResponse<{ accessToken: string }> =
        await tokenApi.post(
          "/user/refresh",
          {},
          {
            withCredentials: true, // httpOnly 쿠키를 사용하기 위함
          }
        );
      return response.data.accessToken;
    } catch (error) {
      this.removeAccessToken();
      console.error("Error refreshing access token:", error);
      throw error;
    }
  }

  static async handleAccessTokenExpired(
    error: AxiosError<{ errorMessage: string }>,
    originalRequest: AxiosRequestConfig & { _retry: boolean }
  ) {
    console.log("redux refresh error", error);
    // 401 오류 및 토큰 만료 메시지 확인
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.errorMessage === "ACCESS_TOKEN_EXPIRED" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await this.refreshAccessToken(); // 리프레시 토큰으로 새 액세스 토큰 요청
        this.setAccessToken(newAccessToken); // 새 토큰 저장
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${newAccessToken}`;

        // 원래 요청 헤더에 새 토큰 설정
        // originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        originalRequest.headers = {
          Authorization: `Bearer ${newAccessToken}`,
        };
        return axios(originalRequest); // 원래 요청 재시도
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem(ACCESS_TOKEN);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }

  // 권한 체크 및 필요시 액세스 토큰 재발급
  // static async checkAndRefreshToken() {
  //   const accessToken = this.getAccessToken();

  //   if (!accessToken) {
  //     return await this.refreshAccessToken();
  //   }

  //   return accessToken;
  // }
}

export default TokenService;
