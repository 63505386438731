import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const DateWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col items-center gap-2 p-2 [&_button]:mx-4 [&_button]:px-2 [&_button]:text-red-500">
      <CalendarMonthIcon fontSize="large" />
      {children}
    </div>
  );
};

export default DateWrapper;
