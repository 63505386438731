import hanacardMyReservationButton from "assets/images/hanacard_button.png";

interface ReservationListIconProps {
  handleMenuClick: () => void;
}

const ReservationListIcon = ({ handleMenuClick }: ReservationListIconProps) => {
  return (
    <div onClick={handleMenuClick}>
      <div className="absolute -right-[3px] -top-[3px] rounded-[4px] bg-[#F14A77] px-2 pb-0.5 pt-1 text-white before:absolute before:right-[10px] before:top-[10px] before:-z-10 before:rotate-[7deg] before:border-b-[6px] before:border-l-[5px] before:border-r-[5px] before:border-t-[6px] before:border-solid before:border-b-transparent before:border-l-[#F14A77] before:border-r-transparent before:border-t-[#F14A77] before:content-['']">
        <p className="text-[0.65rem] font-bold leading-none">예약내역</p>
      </div>
      <img
        src={hanacardMyReservationButton}
        alt="hanacard my reservation"
        className="h-10 rounded-2xl"
      />
    </div>
  );
};

export default ReservationListIcon;
