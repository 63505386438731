import { useIntl } from "react-intl";

interface TicketAdditionalInfoProps {
  passengerDetails: string;
  seatDetails: string;
}

const TicketAdditionalInfo = ({
  passengerDetails,
  seatDetails,
}: TicketAdditionalInfoProps) => {
  const intl = useIntl();

  return (
    <div className="mt-2 w-full space-y-2">
      {/* Passenger Details */}
      <div className="flex justify-between">
        <p className="text-sm font-bold">
          {intl.formatMessage({ id: "booking.passengers" })}
        </p>

        <p className="text-sm">{passengerDetails}</p>
      </div>

      {/* Seat Details */}
      <div className="flex justify-between">
        <p className="text-sm font-bold">
          {intl.formatMessage({ id: "booking.seats" })}
        </p>
        <p className="text-sm">{seatDetails}</p>
      </div>
    </div>
  );
};

export default TicketAdditionalInfo;
