import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PaymentMethod } from "const/paymentMethod";
import { ProviderUtils } from "utils/providerUtils";
import CardLayout from "components/layout/CardLayout";

interface PaymentMethodSectionProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
}

const PaymentMethodSection = ({
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodSectionProps) => {
  const handlePaymentMethodChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newPaymentMethod: PaymentMethod
  ) => {
    if (newPaymentMethod !== null) {
      setPaymentMethod(newPaymentMethod);
    }
  };

  const getHeader = () => {
    return (
      <p className="font-bold">
        <FormattedMessage id="payment.paymentMethodSelect" />
      </p>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <ToggleButtonGroup
        color="secondary"
        value={paymentMethod}
        exclusive
        onChange={handlePaymentMethodChange}
        size="small"
        fullWidth
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          wordBreak: "keep-all",

          "& > .MuiButtonBase-root.Mui-selected , .Mui-selected:hover": {
            bgcolor: ProviderUtils.isHanacard ? "#fff" : "normal",
          },
        }}
      >
        {ProviderUtils.isHanpass && (
          <ToggleButton
            value={PaymentMethod.HANPASS}
            aria-label="hanpass wallet"
          >
            <FormattedMessage id="payment.hanpassWallet" />
          </ToggleButton>
        )}
        {ProviderUtils.isGME && (
          <ToggleButton value={PaymentMethod.GME} aria-label="GME pay">
            <FormattedMessage id="payment.gmePay" />
          </ToggleButton>
        )}
        {/* 개인카드 */}
        {!ProviderUtils.isMame && (
          <ToggleButton value={PaymentMethod.CREDIT} aria-label="credit card">
            <FormattedMessage id="payment.creditCard" />
          </ToggleButton>
        )}
        {/* 라차 카드 */}
        {ProviderUtils.isRedTable && (
          <ToggleButton
            value={PaymentMethod.KTC}
            aria-label="Korea Travel card"
          >
            <FormattedMessage id="payment.koreaTravelCard" />
          </ToggleButton>
        )}
        {/* 법인카드 */}
        {/* {!ProviderUtils.isLottecard && !ProviderUtils.isGME && (
          <ToggleButton
            value={PaymentMethod.CORPORATE}
            aria-label="corporate card"
          >
            {isKorean ? "법인카드" : "Corporate Card"}
          </ToggleButton>
        )} */}
        {ProviderUtils.isMame && (
          <ToggleButton
            value={PaymentMethod.OVERSEAS}
            aria-label="Overseas card"
          >
            <FormattedMessage id="payment.overseasCard" />
          </ToggleButton>
        )}
        {ProviderUtils.isDev && [
          <ToggleButton
            key="transfer"
            value={PaymentMethod.TRANSFER}
            aria-label="Transfer"
          >
            <FormattedMessage id="payment.transfer" />
          </ToggleButton>,
          <ToggleButton
            key="phone"
            value={PaymentMethod.PHONE}
            aria-label="Phone"
          >
            <FormattedMessage id="payment.phone" />
          </ToggleButton>,
          <ToggleButton
            key="inicis"
            value={PaymentMethod.INICIS}
            aria-label="Inicis"
          >
            Inicis
          </ToggleButton>,
        ]}
      </ToggleButtonGroup>
    </CardLayout>
  );
};

export default PaymentMethodSection;
