import { Divider } from "@mui/material";
import { useMyInfoQuery } from "app/apiSlice";
import useFetchPoint from "hooks/useFetchPoint";
import useLanguages from "hooks/useLanguages";
import { providerClasses } from "utils/tailwindUtils";

const HomePoint = () => {
  const point = useFetchPoint();
  const { data: userInfo } = useMyInfoQuery();
  const { isKorean } = useLanguages();

  return (
    userInfo?.userId && (
      <div className="w-full space-y-2 [&>*]:px-2">
        <p className="font-bold text-text-secondary">
          {isKorean ? "보유 라차 적립금" : "Lacha Reward"}:{" "}
        </p>
        <div className="flex justify-end gap-1">
          <p
            className={`${providerClasses["text-primary"]} text-2xl font-bold leading-5`}
          >
            {point?.toLocaleString()}
          </p>
          <p className="font-bold text-text-secondary">
            {isKorean ? "원" : "KRW"}
          </p>
        </div>
        <Divider sx={{ bgcolor: "primary.main", height: "2px" }} />
      </div>
    )
  );
};

export default HomePoint;
