import { useIntl } from "react-intl";

const Instructions = () => {
  const intl = useIntl();

  // Split the text by line breaks and create list items
  const text = intl.formatMessage({ id: "seatSelection.howToSelectSeats" });
  const textLines = text.split("\n").map((line, index) => (
    <div className="px-0 py-1">
      <div className="flex items-start">
        <span className="me-2">•</span>
        <p className="text-sm">{line}</p>
      </div>
    </div>
  ));

  return <div className="m-4 p-0">{textLines}</div>;
};

export default Instructions;
