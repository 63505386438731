import React, { useState } from "react";
import {
  calculateCurrentDate,
  calculateDateBeforeOneMonth,
  type DateType,
} from "utils/dateUtils";

const defaultFromDate = calculateDateBeforeOneMonth();
const defaultToDate = calculateCurrentDate();

const usePeriodFilter = (
  initialPeriod: string,
  initialFromDate = defaultFromDate,
  initialToDate = defaultToDate
) => {
  const [period, setPeriod] = useState(initialPeriod);
  const [fromDate, setFromDate] = useState<DateType>(initialFromDate);
  const [toDate, setToDate] = useState<DateType>(initialToDate);

  const handlePeriodChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPeriod(newValue);
    if (newValue !== "custom") {
      const currentDate = new Date();
      const pastDate = new Date();
      pastDate.setMonth(currentDate.getMonth() - Number(newValue));
      // console.log(pastDate.toISOString().split("T")[0]);
      setFromDate(pastDate.toISOString().split("T")[0] as DateType);
      setToDate(currentDate.toISOString().split("T")[0] as DateType);
      // console.log("기간 설정:", newValue, "개월");
    }
  };

  return {
    period,
    handlePeriodChange,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  };
};

export default usePeriodFilter;
