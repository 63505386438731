import { useGetCurrencyQuery } from "app/apiSlice";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CurrencyType } from "services/trainService";
import { ProviderUtils } from "utils/providerUtils";
import { StorageUtils } from "utils/storageUtils";

const getInitialCurrencyRate = () => {
  const key = ProviderUtils.isDollarProvider ? "USD" : "JPY";

  return Number(StorageUtils.CurrencyRate.get(key) ?? 1);
};

const useFormatPrice = () => {
  const intl = useIntl();
  const koreanWon: string = intl.formatMessage({ id: "payment.koreanWon" });

  const key = ProviderUtils.isDollarProvider ? "USD" : "JPY";
  const { data: currency } = useGetCurrencyQuery(key);

  const [currencyRate, setCurrencyRate] = useState(getInitialCurrencyRate);
  const getCurrencyRate = useCallback(
    async (code: CurrencyType) => {
      StorageUtils.CurrencyRate.set(code, currency?.rate || 1);
      setCurrencyRate(currency?.rate || 1);
      return;
    },
    [currency?.rate]
  );

  useEffect(() => {
    if (
      currencyRate === 1 &&
      (ProviderUtils.isDollarProvider || ProviderUtils.isMame)
    ) {
      const key = ProviderUtils.isDollarProvider ? "USD" : "JPY";
      getCurrencyRate(key);
    }
  }, [currency?.rate, currencyRate, getCurrencyRate]);

  const formatPrice = (price?: number | string): string => {
    if (ProviderUtils.isDollarProvider) {
      return `$${Number(
        (Number(price ?? 0) / currencyRate).toFixed(2)
      ).toLocaleString("en-US")}`;
    }

    if (ProviderUtils.isMame) {
      return `${Math.ceil(Number(price ?? 0) / currencyRate).toLocaleString(
        "ja-JP"
      )} JPY`;
    }

    return `${Number(price ?? 0).toLocaleString("ko-KR")} ${koreanWon}`;
  };

  return formatPrice;
};

export default useFormatPrice;
