import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FormType } from "hooks/useInputs";
import useLanguages from "hooks/useLanguages";
import CardLayout from "components/layout/CardLayout";
import { useState } from "react";
import { useIntl } from "react-intl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface CardSignUpProps {
  form: FormType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validState: {
    name: boolean;
    email: boolean;
    cardBirthDate: boolean;
    cardPassword: boolean;
  };
}

const CardSignUp = ({ form, handleChange, validState }: CardSignUpProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <CardLayout>
      {/* 처음 등록 시 유저 정보  */}
      <div className="space-y-2">
        <TextField
          fullWidth
          name="name"
          label={intl.formatMessage({ id: "signup.name" })}
          value={form.name}
          onChange={handleChange}
          autoComplete="off"
          error={!validState.name}
          helperText={
            !validState.name
              ? intl.formatMessage({ id: "signup.enterName" })
              : undefined
          }
        />
        {/* 이메일 */}
        <TextField
          fullWidth
          name="email"
          label={intl.formatMessage({ id: "signup.email" })}
          value={form.email}
          onChange={handleChange}
          autoComplete="off"
          error={!validState.email}
          helperText={
            !validState.email
              ? intl.formatMessage({ id: "signup.emailInvalid" })
              : undefined
          }
        />
        {/* 생년월일 */}
        <TextField
          fullWidth
          name="cardBirthDate"
          label={
            intl.formatMessage({ id: "booking.dateOfBirth" }) + " (YYMMDD)"
          }
          value={form.cardBirthDate}
          onChange={handleChange}
          autoComplete="off"
          error={!validState.cardBirthDate}
          helperText={
            !validState.cardBirthDate
              ? intl.formatMessage({ id: "booking.invalidBirthDate" })
              : undefined
          }
        />
        {/* 카드 비밀번호 */}
        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          label={
            isKorean
              ? "카드 비밀번호 (6자리 숫자)"
              : "Card Password (6 digits number)"
          }
          name="cardPassword"
          value={form.cardPassword}
          onChange={handleChange}
          autoComplete="off"
          error={!validState.cardPassword}
          helperText={
            !validState.cardPassword
              ? intl.formatMessage({ id: "signup.passwordInvalid1" })
              : undefined
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  tabIndex={-1}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </CardLayout>
  );
};

export default CardSignUp;
