import CustomButton from 'components/button/CustomButton';
import useFormatPrice from 'hooks/useFormatPrice';
import { FormattedMessage, useIntl } from 'react-intl';
import { VAccountInfo } from 'services/cardService';
import { formatDateTimeFromString } from 'utils/dateUtils';

interface VAccountContentProps {
  VAccountInfo: VAccountInfo;
}

/**
 *  가상계좌 정보 UI
 *  */
const VAccountContent = ({ VAccountInfo }: VAccountContentProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();

  return (
    <div className="mb-0 w-full p-4 text-start">
      <p className="mb-6">
        <FormattedMessage id="card.virtualAccountNotice" />
        <br />
        <span className="text-sm text-red-500">
          <FormattedMessage id="card.virtualAccountFeeNotice" />
        </span>
      </p>

      <div className="space-y-2">
        <div>
          <FormattedMessage id="card.virtualAccount.account" />
          <br />
          <div className="flex items-center gap-4">
            <span className="font-bold">
              {VAccountInfo?.VbankBankName} {VAccountInfo?.VbankNum}
            </span>
            <div className="w-24">
              <CustomButton
                size="small"
                variant="outlined"
                id="card.copy"
                onClick={() =>
                  navigator.clipboard
                    .writeText(VAccountInfo?.VbankNum)
                    .then(() =>
                      alert(intl.formatMessage({ id: "card.copyComplete" }))
                    )
                }
              />
            </div>
          </div>
        </div>
        <p>
          <FormattedMessage id="card.virtualAccount.amount" />
          <br />
          <span className="font-bold">{formatPrice(VAccountInfo?.Amt)}</span>
        </p>
        <p>
          <FormattedMessage id="card.virtualAccount.expiry" />
          <br />
          <span className="font-bold">
            {formatDateTimeFromString(
              `${VAccountInfo?.VbankExpDate.slice(2)}${VAccountInfo?.VbankExpTime}`
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default VAccountContent;
