import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import CustomButton from "components/button/CustomButton";
import { useIntl } from "react-intl";
import { InfoUpdateProps } from "./PersonalInfoFormPage";
import { isAxiosError } from "axios";
import UserService from "services/userService";

interface ConsentInfo {
  isReceivingEmail: boolean;
  isReceivingSms: boolean;
  isReceivingTalk: boolean;
}

const ConsentInfoUpdate = ({
  userInfo,
  setUserInfo,
  setModalVisible,
}: InfoUpdateProps) => {
  const intl = useIntl();
  const handleConsentChange = (name: keyof ConsentInfo) => {
    setUserInfo((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleSubmit = async () => {
    try {
      const updatedUserInfo = {
        isReceivingEmail: userInfo.isReceivingEmail,
        isReceivingSms: userInfo.isReceivingSms,
        isReceivingTalk: userInfo.isReceivingTalk,
      };

      await UserService.update(updatedUserInfo);

      setModalVisible(true);
    } catch (error) {
      if (isAxiosError(error)) {
        alert(error.message);
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <p className="text-lg font-bold">
          {intl.formatMessage({ id: "signup.salesTitle2" })}
        </p>
        <p>{intl.formatMessage({ id: "signup.salesContent2" })}</p>
      </div>
      <div className="space-y-4">
        <FormGroup row sx={{ marginInlineStart: 16 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingEmail}
                onChange={() => handleConsentChange("isReceivingEmail")}
              />
            }
            label={intl.formatMessage({ id: "signup.email" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingTalk}
                onChange={() => handleConsentChange("isReceivingTalk")}
              />
            }
            label={intl.formatMessage({ id: "signup.alimTalk" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingSms}
                onChange={() => handleConsentChange("isReceivingSms")}
              />
            }
            label="Push"
          />
        </FormGroup>
        <CustomButton onClick={handleSubmit}>
          {" "}
          {intl.formatMessage({ id: "user.info.save" })}
        </CustomButton>
      </div>
    </div>
  );
};

export default ConsentInfoUpdate;
