import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import parse from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage, useIntl } from "react-intl";
import kakaoCoupon from "assets/images/kakao_coupon.webp";

interface KakoAccordionProps {
  descriptionKey?: string;
}

const KakaoAccordion = ({ descriptionKey }: KakoAccordionProps) => {
  const intl = useIntl();

  return (
    <Accordion
      disableGutters
      sx={{
        width: "100%",
        boxShadow: "none",
        "& .MuiButtonBase-root": { px: 0 },
        "& .MuiAccordionSummary-content": { my: 0 },
        bgcolor: "#0000",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="common.voucherUsage"
        id="common.voucherUsage"
        className="!min-h-8"
        // sx={{
        //   display: "flex",
        //   alignItems: "end",
        // }}
      >
        <p className="text-sm">
          <FormattedMessage id="common.voucherUsage" />
        </p>
      </AccordionSummary>

      <AccordionDetails>
        <img
          src={kakaoCoupon}
          alt="kakao_coupon"
          height={"480px"}
          style={{ borderRadius: "16px" }}
          onClick={() => window.open(kakaoCoupon)}
        />
        <Divider flexItem />

        {descriptionKey && (
          <div className="leading-3">
            {parse(`${intl.formatMessage({ id: descriptionKey })}`)}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default KakaoAccordion;
