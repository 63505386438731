import { useMyInfoQuery } from "app/apiSlice";
import HomePoint from "./HomePoint";
import TrainFlightTabs from "components/tabs/TrainFlightTabs";
import { ProviderUtils } from "utils/providerUtils";

const BookingFormHeader = () => {
  const { data: userInfo } = useMyInfoQuery();
  return userInfo && ProviderUtils.isHanacard ? (
    <HomePoint />
  ) : (
    <TrainFlightTabs value={0} />
  );
};

export default BookingFormHeader;
