import Layout from "components/layout/Layout";
import React, { ChangeEvent, memo, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActivityFilter, Criteria } from "services/travelService";
import ActivityThumbnail from "./components/ActivityThumbnail";
import ThumbnailSkeleton from "./components/skeleton/ThumbnailSkeleton";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { getURLSearchParams } from "utils/urlSearchParamsUtils";
import { SelectChangeEvent } from "@material-ui/core";
import { useGetActivityListQuery } from "app/apiSlice";
import { useIntl } from "react-intl";

const CategoryPage = () => {
  const intl = useIntl();
  const { category } = useParams(); //
  const navigate = useNavigate();
  const debounce = useDebounce(700);
  const [page, setPage] = useState(
    Number(getURLSearchParams().get("page")) || 1
  );

  const categoryTitleMap: Record<string, string> = useMemo(
    () => ({
      jr: intl.formatMessage({ id: "jtr.jr" }),
      usj: intl.formatMessage({ id: "jtr.usj" }),
      bus: intl.formatMessage({ id: "jtr.bus" }),

      // 지역별
      tokyo: intl.formatMessage({ id: "jtr.tokyo" }),
      kansai: intl.formatMessage({ id: "jtr.kansai" }),
      hokkaido: intl.formatMessage({ id: "jtr.hokkaido" }),
      okinawa: intl.formatMessage({ id: "jtr.okinawa" }),
      kyushu: intl.formatMessage({ id: "jtr.kyushu" }),
    }),
    [intl]
  );

  const params = getURLSearchParams();
  const keyword = params.get("keyword");
  const filter = params.get("filter") as ActivityFilter;
  const criteria: Criteria = {
    category: !category ? "" : category === "kansai" ? "osaka" : category,
    page: page - 1,
    // sortType: "reviewCount",
  };
  // RTK에서 debounce 적용하기 위해 debouncedKeyword 사용
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);

  if (filter) {
    criteria.sortType = filter;
  }

  if (debouncedKeyword) {
    criteria.keyword = debouncedKeyword;
  }

  const {
    data: travelGoods,
    // isLoading,
    isFetching,
  } = useGetActivityListQuery(criteria, {
    refetchOnMountOrArgChange: 3600, // 캐시 유지 시간을 1시간으로 설정
  });

  const handleFilter = (e: SelectChangeEvent<ActivityFilter>) => {
    const filter = e.target.value;

    // reset to first page when filter is changed
    params.set("page", "1");
    setPage(1);

    if (filter === "default") {
      params.delete("filter");
    } else {
      params.set("filter", filter);
    }
    navigate(`?${params.toString()}`, { replace: true });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    if (!keyword) {
      params.delete("keyword");
      navigate(`?${params.toString()}`);
      return;
    }

    params.set("keyword", keyword);
    navigate(`?${params.toString()}`, { replace: true });

    debounce(() => setDebouncedKeyword(keyword));
  };

  const handlePageChange = (_e: ChangeEvent<unknown>, value: number) => {
    const params = getURLSearchParams();
    params.set("page", value.toString());
    navigate(`?${params.toString()}`, { replace: true });
    setPage(value);
  };

  return (
    <Layout
      text={
        category
          ? categoryTitleMap[category]
          : intl.formatMessage({ id: "orders.search" })
      }
      onBack={() => navigate("/activity", { replace: true })}
    >
      <section className="grid grid-cols-12 gap-2 py-4">
        {/* 검색창 */}
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder={intl.formatMessage({ id: "jtr.search" })}
          className="col-span-8 rounded-lg bg-white"
          value={params.get("keyword") || ""}
          onChange={handleSearch}
        />

        {/* 필터 */}
        <FormControl size="small" className="col-span-4 self-end">
          <Select
            id="demo-select-small"
            value={filter || "default"}
            defaultValue={"default"}
            onChange={handleFilter}
            className="bg-white"
          >
            <MenuItem value="default">
              {intl.formatMessage({ id: "jtr.featured" })}
            </MenuItem>
            <MenuItem value="rating">
              {intl.formatMessage({ id: "jtr.ratings" })}
            </MenuItem>
            <MenuItem value="reviewCount">
              {intl.formatMessage({ id: "jtr.reviews" })}
            </MenuItem>
            <MenuItem value="sellCount">
              {intl.formatMessage({ id: "jtr.sales" })}
            </MenuItem>
            {/* <MenuItem value="price-low">낮은가격순</MenuItem> */}
          </Select>
        </FormControl>
      </section>

      <div className="flex min-h-[80vh] flex-col justify-between">
        <section className="grid grid-cols-1 gap-3">
          {isFetching ? (
            <ThumbnailSkeleton direction="row" />
          ) : travelGoods?.content.length === 0 ? (
            <div className="m-auto flex flex-col items-center space-y-4 text-text-secondary">
              <ProductionQuantityLimitsIcon className="mt-20 !text-[80px]" />
              <h2>{intl.formatMessage({ id: "jtr.noProducts" })}</h2>
            </div>
          ) : (
            travelGoods?.content.map((good, key) => (
              <ActivityThumbnail
                activity={good}
                direction="row"
                key={`${good.goodsId}-${key}`}
              />
            ))
          )}
        </section>

        <div className="mt-4 flex w-full justify-center">
          <Pagination
            count={travelGoods?.totalPages || 1}
            shape="rounded"
            color="primary"
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Layout>
  );
};

export default memo(CategoryPage);
