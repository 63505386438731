import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import CustomButton from "./CustomButton";
import CardLayout from "components/layout/CardLayout";

interface DeleteAccountButtonProps {
  onClick: () => Promise<void>;
}

const DeleteAccountButton = ({ onClick }: DeleteAccountButtonProps) => {
  return (
    <CardLayout className="p-0">
      <Accordion disableGutters className="before:hidden">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="user.delete"
          id="user.delete"
        >
          <p className="text-xl font-bold leading-8 text-gray-500">
            <FormattedMessage id="user.delete" />
          </p>
        </AccordionSummary>
        <AccordionDetails className="!pt-0">
          <Divider flexItem />
          <div className="mt-2 flex justify-end space-y-2">
            <CustomButton
              variant="outlined"
              color="error"
              size="small"
              fullWidth={false}
              onClick={onClick}
            >
              <FormattedMessage id="user.delete" />
            </CustomButton>
          </div>
        </AccordionDetails>
      </Accordion>
    </CardLayout>
  );
};

export default DeleteAccountButton;
