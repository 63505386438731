import clsx from 'clsx';
import { OptionType } from "pages/card/Sections/DepositWithdraw";
import { FormattedMessage } from "react-intl";

interface AmountWrapperProps {
  option: OptionType;
  children: React.ReactNode;
}

const AmountWrapper = ({ option, children }: AmountWrapperProps) => {
  return (
    <label
      className={clsx("w-full space-y-2", option === "DEPOSIT" && "mb-16")}
    >
      <p className="font-bold">
        <FormattedMessage id={`card.${option.toLowerCase()}Amount`} />
      </p>

      {children}
    </label>
  );
};

export default AmountWrapper;
