// QuickLinkItem.js
import useTranslateStation from "hooks/useTranslateStation";
import Arrow from "components/Arrow";
import TrainLogo from "components/TrainLogo";
import { LinkItem, SearchSection } from "./QuickLinks";
import { TrainResponse } from "services/trainService";

interface QuickLinkItemProps {
  link: TrainResponse | LinkItem;
  section: SearchSection;
  handleLinkClick: (link: QuickLinkItemProps["link"]) => void;
}

const QuickLinkItem = ({
  link,
  section,
  handleLinkClick,
}: QuickLinkItemProps) => {
  const translateStationName = useTranslateStation();
  let departure: string = "";
  let arrival: string = "";

  if (section === "popularRoutes") {
    [departure, arrival] = link.stationName.split(" → ");
  }

  return (
    <div
      className="flex items-center justify-start gap-2 px-2 py-1 [&>span]:m-0"
      onClick={() => handleLinkClick(link)}
    >
      <TrainLogo />

      {section === "popularRoutes" ? (
        <>
          <span>{translateStationName(departure)}</span>
          <Arrow />
          <span>{translateStationName(arrival)}</span>
        </>
      ) : (
        <span>{translateStationName(link.stationName)}</span>
      )}
    </div>
  );
};

export default QuickLinkItem;
