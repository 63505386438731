import styled from "styled-components";

const QRCode = ({ src }: { src: string }) => {
  return (
    <div className="flex items-center justify-center">
      <QRCodeImage src={src} />
    </div>
  );
};

export default QRCode;

const QRCodeImage = styled.div<{ src: string }>`
  background: center no-repeat ${(props) => `url(${props.src})`};
  /* height: min(40vw, 160px); */
  /* width: min(40vw, 160px); */
  height: 56px;
  width: 56px;
  background-size: 140%;
`;
