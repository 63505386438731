import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useTranslateStation from "hooks/useTranslateStation";
import { TicketDetail } from "./BookingInfos";
import { OrderType } from "types/orderType";

interface StationInfoProps {
  status: "DEPARTURE" | "ARRIVAL";
  detail: Pick<TicketDetail, "departure" | "arrival"> &
    Pick<OrderType, "startDate" | "endDate">;
}

const StationInfo = ({ status, detail }: StationInfoProps) => {
  // hooks
  const translateStation = useTranslateStation();
  const formatDateTime = useFormatDateToLocaleString();

  // data
  const { departure, arrival, startDate, endDate } = detail;

  return (
    <div>
      <p className="text-center font-bold max-[321px]:text-sm">
        {translateStation(status === "DEPARTURE" ? departure : arrival)}
      </p>
      <p className="text-center text-xl font-bold min-[321px]:text-2xl">
        {formatDateTime(status === "DEPARTURE" ? startDate : endDate, {
          hour: "2-digit",
          hour12: false,
          minute: "2-digit",
        })}
      </p>
    </div>
  );
};

export default StationInfo;
