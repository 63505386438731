import { Card, TextField } from "@mui/material";
import { useRegisterMutation } from "app/apiSlice";
import { isAxiosError } from "axios";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import useInputs from "hooks/useInputs";
import useValidate from "hooks/useValidate";
import {
  AgreementType,
  CommunicationType,
  initialCommunicationMethods,
} from "pages/signup-panel/RegistrationTab";
import ConsentForm from "pages/signup-panel/sections/ConsentForm";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import TokenService from "services/tokenService";
import { makeSignUpFormData } from "utils/signupUtils";
import { StorageUtils } from "utils/storageUtils";

const initialForm = {
  name: "",
  email: "",
  phone: "",
};

const initialAgreements = {
  termsOfService: false,
  privacyPolicy: false,
  // promotions: false,
  etc: false,
};

export type SsoName = "NAVER" | "KAKAO" | "LINE";

interface LocationState {
  userInfo: {
    firstName: string;
    phoneNumber: string;
    providerId: string;
    email: string;
  };
  ssoName: SsoName;
}

const OAuthAdditionalInfo = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [register, { data }] = useRegisterMutation();

  const { form, onChange } = useInputs(initialForm);
  const {
    userInfo: { firstName: name, phoneNumber: phone, providerId, email },
    ssoName,
  } = useLocation().state as LocationState;
  const initialValidState = {
    name: true,
    email: Boolean(email),
    phone: Boolean(phone),
  };
  const [validState, validate] = useValidate(initialValidState);

  // 사용자 동의를 관리하기 위한 상태
  const [agreements, setAgreements] = useState(initialAgreements);

  // 운영 정보 수신 방법 상태 관리
  const [communicationMethods, setCommunicationMethods] = useState(
    initialCommunicationMethods
  );

  // 동의 토글 변경을 처리하는 함수
  const handleAgreementChange = (name: keyof AgreementType) => {
    setAgreements((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const handleCommunicationChange = (method: keyof CommunicationType) => {
    setCommunicationMethods((prev) => ({
      ...prev,
      [method]: !prev[method],
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name !== "name") {
      validate(e);
    }
    onChange(e);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validState.name || !validState.email || !validState.phone) {
      alert("모든 필드를 채워주세요");
      return;
    }

    const revFormData = makeSignUpFormData(communicationMethods);

  revFormData.append("name", String(name || form.name));
    revFormData.append("email", String(email || form.email));
    revFormData.append("phone", String(phone || form.phone));

    revFormData.append("ssoId", String(providerId));
    revFormData.append("ssoName", ssoName);

    try {
      await register(revFormData);

      if (data?.accessToken) {
        TokenService.setAccessToken(data?.accessToken);

        // 사용자 정보
        // const userInfoResponse = await UserService.getMyInfo();
        // dispatch(fetchUser(userInfoResponse));

        StorageUtils.RecentLoginMethod.set(ssoName);

        if (StorageUtils.IsLoginModal.get()) {
          navigate("/search-results");
          return;
        }

        navigate("/");
      }
    } catch (error) {
      if (isAxiosError(error)) {
        alert("Social Login failed");
      }
    }
  };

  const isSubmitDisabled =
    !agreements.termsOfService || !agreements.privacyPolicy;

  return (
    <Layout text={"추가정보 입력"}>
      <Card sx={{ m: 1, p: 2 }}>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          <TextField
            fullWidth
            type="text"
            name="name"
            label={intl.formatMessage({ id: "signup.name" })}
            value={name || form.name}
            onChange={handleChange}
            autoComplete="off"
            disabled={Boolean(name)}
            required
          />

          <TextField
            type="text"
            name="email"
            label={intl.formatMessage({ id: "signup.email" })}
            value={email || form.email}
            onChange={handleChange}
            autoComplete="off"
            sx={{ width: "100%" }}
            error={!!form.email && !validState.email}
            helperText={
              form.email &&
              !validState.email && (
                <span>
                  <FormattedMessage id="signup.emailInvalid" />
                </span>
              )
            }
            disabled={Boolean(email)}
          />

          {/* 휴대폰 번호 */}
          <TextField
            fullWidth
            type="tel"
            name="phone"
            label={intl.formatMessage({ id: "signup.phone" })}
            value={phone || form.phone}
            onChange={handleChange}
            // autoComplete="off"
            error={!!form.phone && !validState.phone}
            helperText={
              form.phone &&
              !validState.phone && (
                <span>
                  <FormattedMessage id="signup.phoneInvalid" />
                </span>
              )
            }
            disabled={Boolean(phone)}
          />

          <ConsentForm
            agreements={agreements}
            onAgreementChange={handleAgreementChange}
            communicationMethods={communicationMethods}
            onCommunicationMethodsChange={handleCommunicationChange}
          />

          <CustomButton disabled={isSubmitDisabled}>
            {intl.formatMessage({ id: "user.signup" })}
          </CustomButton>
        </form>
      </Card>
    </Layout>
  );
};

export default OAuthAdditionalInfo;
