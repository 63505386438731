import { CostParams, SeatMapParams } from 'services/trainService';
import { ScheduleType } from 'types/scheduleType';
import { getAdultsNumber, getChildrenNumber } from './urlSearchParamsUtils';

export const getRequestData = (schedule: ScheduleType): SeatMapParams => {
  const requestData: SeatMapParams = {
    dptRsStnCd: schedule.dptRsStnCd, // 출발역 코드
    arvRsStnCd: schedule.arvRsStnCd, // 도착역 코드
    trnNo: schedule.trnNo, // 기차 번호
    gdNo: schedule.gdNo, // 상품 번호
    gdConsGpSqno: schedule.gdConsGpSqno, // 추가 정보
    gdConsItmId: schedule.gdConsItmId, // 추가 정보

    trnGpCd: schedule.trnGpCd, // 열차 그룹코드
    psrmClCd: "1", // 승객 클래스 코드
    runDt: schedule.runDt, // 운행 날짜
  };

  return requestData;
};

export const getCostParams = (schedule: ScheduleType): CostParams => {
  const costParams: CostParams = {
    dptRsStnCd: schedule.dptRsStnCd, // 출발역 코드
    arvRsStnCd: schedule.arvRsStnCd, // 도착역 코드
    trnNo: schedule.trnNo, // 기차 번호
    gdNo: schedule.gdNo, // 상품 번호 (static value)
    gdConsGpSqno: schedule.gdConsGpSqno, // 상품 구성 그룹 순번
    gdConsItmId: schedule.gdConsItmId, // 상품 구성 품목 ID

    gdItmId: schedule.gdItmId, // 상품 품목 ID
    utlDt: schedule.dptDt, // 이용 날짜 (format: YYYYMMDD)
    medDvCd: "02", // 매체 구분 코드 (static value)

    adultCount: getAdultsNumber(),
    childCount: getChildrenNumber(),
  };
  return costParams;
};

export const createTicketPriceForm = (schedule: ScheduleType) => {
  const formData = new FormData();

  formData.append("gdNo", schedule.gdNo);
  formData.append("gdConsGpSqno", schedule.gdConsGpSqno);
  formData.append("gdConsItmId", schedule.gdConsItmId);
  formData.append("gdItmId", schedule.gdItmId);
  formData.append("tripPrnb1", getAdultsNumber().toString());
  formData.append("tripPrnb3", getChildrenNumber().toString());
  formData.append("trnNo", schedule.trnNo);
  formData.append("runDt", schedule.runDt);
  formData.append("dptRsStnCd", schedule.dptRsStnCd);
  formData.append("arvRsStnCd", schedule.arvRsStnCd);
  formData.append("goodsId", "LA010022010");

  return formData;
};