import { useGetMyPointQuery } from "app/apiSlice";
import { useEffect } from "react";

const useFetchPoint = () => {
  // const user = useAppSelector((state) => state.user);
  // const [point, setPoint] = useState(user.point);
  const { data: point, refetch: refetchPoint } = useGetMyPointQuery();

  useEffect(() => {
    // const getPoint = async () => {
    //   const myPoint = await UserService.getPoint();
    //   dispatch(updatePoint(myPoint));
    //   setPoint(myPoint);
    // };

    // login 되어있으면 포인트 최신화
    // if (user.userId) {
    // getPoint();
    // }

    refetchPoint();
  }, [refetchPoint]);

  return point;
};

export default useFetchPoint;
