import { useNavigate } from "react-router-dom";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";
import ReservationListIcon from "components/hanacard/ReservationListIcon";
import { ProviderUtils } from "utils/providerUtils";
import { useEffect, useState } from "react";
import clsx from "clsx";
import MyCartIcon from "components/MyCartIcon";
import LogoImage from "./LogoImage";

const Header = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const handleMenuClick = () => {
    navigate("/user-settings");
  };

  useEffect(() => {
    const toggleHeader = () => {
      if (window.scrollY > HEADER_HEIGHT * 1.5) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    window.addEventListener("scroll", toggleHeader);

    return () => {
      window.removeEventListener("scroll", toggleHeader);
    };
  }, []);

  return (
    <header
      className={clsx(
        `fixed top-0 z-50 flex w-full items-center justify-between bg-white px-4 pt-[env(safe-area-inset-top)] shadow-[0_2px_4px_rgba(0,0,0,0.1)] transition duration-150 ease-in-out`,
        {
          "translate-y-0": visible,
          "translate-y-[-100%]": !visible,
        }
      )}
      style={{
        height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,
        direction: "ltr",
      }}
    >
      <section className="flex items-center justify-between gap-2">
        {/* Lotte Card에서만 보여주기 */}
        {ProviderUtils.isLottecard && (
          <IconButton
            onClick={closeWebview}
            size="small"
            edge="end"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <LogoImage />
      </section>
      <section className="flex items-center gap-2 [&>*]:cursor-pointer">
        {/* {ProviderUtils.darkModeAvailable && <ThemeToggle />} */}
        <LanguageSelectIcon />
        <MyCartIcon />
        {ProviderUtils.isHanacard ? (
          <ReservationListIcon handleMenuClick={handleMenuClick} />
        ) : (
          <Menu aria-label="menu" onClick={handleMenuClick} />
        )}
      </section>
    </header>
  );
};

export default Header;
