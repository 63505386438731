import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import { getContextualUrl } from "utils/navigation";

const MyCartIcon = () => {
  const navigate = useNavigate();

  return (
    <ShoppingCartIcon onClick={() => navigate(getContextualUrl("/cart"))} />
  );
};

export default MyCartIcon;
