export interface ConsentState {
  [K: string]: boolean | undefined;
}

/**
 * Make all options in the given consent state true.
 * @param obj The consent state to update.
 * @returns The updated consent state.
 */
export const makeAllOptionsTrue = <T extends ConsentState>(obj: T): T => {
  const newState = Object.fromEntries(
    Object.entries(obj).map(([key]) => [key, true])
  ) as T;
  return newState;
};
