import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Divider, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BookerInfo,
  Coupon,
  ReservationInput,
  createReservationData,
} from "utils/reservationUtils";
import PassengerInfoSection, {
  PassengerDetailField,
} from "./sections/PassengerInfoSection";
import CouponSection from "./sections/CouponSection";
import TravelInfoSection from "./sections/TravelInfoSection";
import PriceSummarySection from "./sections/PriceSummarySection";
import BookerInfoSection from "./sections/BookerInfoSection";
import LoadingSpinner from "components/LoadingSpinner";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import useValidate, { isFormValid } from "hooks/useValidate";
import {
  PassengerDetail,
  TrainParams,
  updateSearchParams,
} from "app/reservationSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ScheduleType } from "types/scheduleType";
import useModalSheet from "hooks/overlay/useModalSheet";
import { getTotalPassengersNumber } from "utils/urlSearchParamsUtils";
import BookingGuidelines from "pages/tickets/components/BookingGuidelines";
import { makeAllOptionsTrue } from "utils/consentUtils";
import useLanguages from "hooks/useLanguages";
import AgreementsSection from "./sections/AgreementsSection";
import {
  useGetRelatedGoodsQuery,
  useLazyGetTicketPriceQuery,
  useLazyMyInfoQuery,
  useMyInfoQuery,
  useReserveTicketMutation,
} from "app/apiSlice";
import PaymentService from "services/paymentService";
import { createTicketPriceForm } from "utils/trainCostUtils";
import { OrderType } from "types/orderType";

const initialAgreements = {
  term1: false,
  term2: false,
  term3: false,
  term4: false,
};

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

/**
 * Checks if a given forms are filled.
 * If the form is an array of passenger details, check if all passengers have a name and birth date.
 * If the form is a BookerInfo object, check if the booker has a name, phone number, email, and birth date
 *
 * @param forms - An array of PassengerDetail objects or a BookerInfo object.
 * @returns A boolean indicating if all forms are filled.
 */
const isFormFilled = (forms: PassengerDetail[] | BookerInfo): boolean => {
  let isFormFilled = false;

  if (forms instanceof Array) {
    isFormFilled = forms.every(
      (passenger) => passenger.name && passenger.birthDate
    );
  } else {
    isFormFilled = Boolean(
      forms.name && forms.phone && forms.email && forms.birthDate
    );
  }

  return isFormFilled;
};

/**
 * Converts a ReservationInput object into a FormData object.
 *
 * @param reservationInput - A ReservationInput object that has reservation data.
 * @returns A FormData object containing the reservation data.
 */
const createReservationFormData = (reservationInput: ReservationInput) => {
  const reservationData = createReservationData(reservationInput);
  const formData = new FormData();
  Object.entries(reservationData).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

/**
 * Generates an array of passenger details with initial values.
 * The initial passenger detail objects have the following properties:
 * - name: empty string
 * - birthDate: empty string
 * - seat: a seat object from the selected seats array
 *
 * @param reservation - The reservation state object.
 * @returns An array of passenger detail objects with initial values.
 */
const getInitialPassengerDetails = (
  trainParams: TrainParams
): PassengerDetail[] => {
  const passengerCount = getTotalPassengersNumber();
  const seats = trainParams.selectedSeats;
  return Array.from({ length: passengerCount }, (_, index) => ({
    name: "",
    birthDate: "",
    seat: seats![index],
  }));
};

/**
 * Generates an object with initial values for passenger birthdate validation.
 * The keys are in the format of "birthDate{index}" and the values are all true.
 *
 * @param reservation - The reservation state object.
 * @returns An object with initial values for passenger birthdate validation.
 */
const getInitialPassengerValidState = () => {
  const passengerCount = getTotalPassengersNumber();
  const passengerValidState: PassengerValidState = {};

  for (let i = 0; i < passengerCount; i++) {
    passengerValidState[`birthDate${i}`] = true;
  }
  return passengerValidState;
};

const initialBookerValidState = {
  name: true,
  phone: true,
  email: true,
  birthDate: true,
};

export type PassengerValidState = Record<`birthDate${number}`, boolean>;

const PaymentBookingPage = () => {
  // redux
  const dispatch = useAppDispatch();
  const { trainParams } = useAppSelector((state) => state.reservation);

  const theme = useTheme();
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const { schedule } = useLocation().state as { schedule: ScheduleType };

  const ticketPriceForm = createTicketPriceForm(schedule);
  const [reserveTicket, { isLoading }] = useReserveTicketMutation();
  // const { data: priceData } = useGetTicketPriceQuery();
  const [getTicketPrice, { data: ticketPrice, isFetching: isFetchingPrice }] =
    useLazyGetTicketPriceQuery();
  const [getUserInfo] = useLazyMyInfoQuery({});
  const { data: user } = useMyInfoQuery();
  const { name = "", phone = "", email = "", birthDate = "" } = user ?? {};

  const {
    data: relatedGoods,
    isError,
    isSuccess,
  } = useGetRelatedGoodsQuery({
    stationCode: schedule.arvRsStnCd,
  });
  const couponList = relatedGoods?.filter((goods) => goods.saleAmount >= 5000);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | undefined>(
    undefined
  );

  useEffect(() => {
    if (isSuccess) {
      const setDefaultPrice = async () => {
        setSelectedCoupon(couponList?.[0]);
        const { data, isSuccess } = await getTicketPrice(ticketPriceForm);
        if (isSuccess) {
          setPrice(data);
        }
      };
      setDefaultPrice();
    }
  }, [isSuccess]); // couponList 추가하면 카카오 금액 변경 시에도 다시 첫 번째 이용권으로 초기화 됨

  // modal
  const [modalContent, setModalContent] = useState("");
  const [CartModal, setCartModalVisible] = useModalSheet({
    callbackOk: () => {
      navigate("/cart");
    },
  });
  const [ValidationModal, setModalVisible] = useModalSheet({
    callbackOk: () => window.scrollTo({ top: 280, behavior: "smooth" }),
  });
  const [ConsentModal, setConsentModalVisible] = useModalSheet({});

  // state
  const [agreements, setAgreements] = useState(initialAgreements);
  const [hanpassAgencyCode, setHanpassAgencyCode] = useState("");
  const [price, setPrice] = useState<OrderType>();

  // 계산
  const [bookerValidState, bookerValidate] = useValidate(
    initialBookerValidState
  );
  const [passengerValidState, passengerValidate] = useValidate(
    getInitialPassengerValidState()
  );

  // 예매자 정보 - userInfo없는 경우에만 서버로 전달하기
  const [bookerInfo, setBookerInfo] = useState<BookerInfo>({
    name,
    phone,
    email,
    birthDate,
  });
  // 첫 rendering 시에 user가 undefined 일 수 있으므로 useEffect로 초기화
  useEffect(() => {
    setBookerInfo({ name, phone, email, birthDate });
  }, [birthDate, email, name, phone]);

  // 승객 상세 정보
  const [passengerDetails, setPassengerDetails] = useState(() =>
    getInitialPassengerDetails(trainParams)
  );

  // data
  const isBookerInfoVisible = !(
    name?.trim() &&
    phone?.trim() &&
    email?.trim() &&
    birthDate?.trim()
  );
  const seats = trainParams.selectedSeats;
  const reservationInput: ReservationInput = useMemo(
    () => ({
      selectedCoupon,
      bookerInfo: { ...bookerInfo, agencyCode: hanpassAgencyCode },
      passengerDetails,
      schedule,
      trainParams,
    }),
    [
      bookerInfo,
      hanpassAgencyCode,
      passengerDetails,
      schedule,
      selectedCoupon,
      trainParams,
    ]
  );

  // action
  const handleAllAgree = useCallback(() => {
    setAgreements(makeAllOptionsTrue(agreements));
  }, [agreements]);

  // 탑승자 정보 변경 핸들러
  const handleChangePassengerDetail =
    (index: number, field: PassengerDetailField) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      switch (field) {
        case "birthDate":
          passengerValidate(e);
          break;
        default:
          break;
      }

      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];

        // 특정 인덱스의 탑승자 정보를 복사하고, 필요한 필드를 업데이트합니다.
        updatedDetails[index] = {
          ...updatedDetails[index],
          [field]: value,
        };

        dispatch(updateSearchParams({ passengerDetails: updatedDetails }));
        // 업데이트된 탑승자 정보 배열을 반환합니다.
        return updatedDetails;
      });
    };

  const updatePrice = async (coupon: Coupon) => {
    const reservationFormData = createReservationFormData({
      ...reservationInput,
      selectedCoupon: coupon,
    });
    const { data } = await getTicketPrice(reservationFormData);

    // setPrice((prev) => ({
    //   ...prev,
    //   totalPrice: data?.totalPrice || 0,
    //   pointAmount: data?.pointEarned || 0,
    // trainOriginalPrice: data?.trainOriginalPrice || 0,
    // }));

    setPrice(data);
  };

  useEffect(() => {
    if (isSuccess && couponList) {
      setSelectedCoupon(couponList?.[0]);
      updatePrice(couponList?.[0]);
    }
  }, [isSuccess]); // couponList 추가하면 카카오 금액 변경 시에도 다시 첫 번째 이용권으로 초기화 됨

  // Coupon selection change handler
  const handleCouponChange = async (coupon: Coupon) => {
    setSelectedCoupon(coupon);
    reservationInput.selectedCoupon = coupon;

    updatePrice(coupon);
  };

  const validateBookerInfo = () => {
    const handleFail = (message: string) => {
      setModalVisible(true);
      setModalContent(message);
    };

    if (!bookerInfo.name || !bookerInfo.name) {
      handleFail(intl.formatMessage({ id: "signup.enterName" }));
      return false;
    }

    if (!bookerInfo.phone) {
      handleFail(intl.formatMessage({ id: "signup.enterPhone" }));
      return false;
    }
    if (!bookerValidState.phone) {
      handleFail(intl.formatMessage({ id: "signup.phoneInvalid" }));
      return false;
    }

    if (!bookerInfo.email) {
      handleFail(intl.formatMessage({ id: "signup.enterEmail" }));
      return false;
    }
    if (!bookerValidState.email) {
      handleFail(intl.formatMessage({ id: "signup.emailInvalid" }));
      return false;
    }

    if (!bookerValidState.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.invalidBirthDate" }));
      return false;
    }
    if (!bookerInfo.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.birthDate" }));
      return false;
    }

    return true;
  };

  const handleReservation = async () => {
    try {
      const orderId = await makeReservation();

      if (orderId) {
        const paymentId = await PaymentService.createPaymentId([orderId]);
        navigate(`/payment/${paymentId}`);
      }
    } catch (error) {
      // alert("Reservation Failed. Please try again");
    }
  };

  const handleAddToCart = async () => {
    try {
      if (await makeReservation()) {
        setCartModalVisible(true);
      }
    } catch (error) {
      // alert("Reservation Failed. Please try again");
    }
  };

  const makeReservation = async () => {
    // 예매자 정보 validation
    if (isBookerInfoVisible && !validateBookerInfo()) {
      return;
    }

    // 탑승자 정보 validation
    if (!isFormFilled(passengerDetails)) {
      setModalVisible(true);
      setModalContent(
        intl.formatMessage({ id: "booking.enterPassengerDetails" })
      );
      throw Error("탑승자 정보 에러");
    }
    if (!isFormValid(passengerValidState)) {
      setModalVisible(true);
      setModalContent(
        intl.formatMessage({ id: "booking.enterValidPassengerInfo" })
      );
      throw Error("탑승자 정보 에러");
    }

    if (!checkAllAgree(agreements)) {
      setConsentModalVisible(true);
      setModalContent(
        isKorean
          ? "이용약관을 확인 후 동의에 체크해주세요"
          : "Please consent to the forms before proceeding"
      );
      throw Error("이용약관 미동의 에러");
    }

    const reservationFormData = createReservationFormData(reservationInput);
    const result = await reserveTicket(reservationFormData);

    // 처음 예매하는 경우 유저 정보 업데이트
    if (isBookerInfoVisible) {
      await getUserInfo();
    }

    if ("data" in result) {
      return result.data.orderId;
    }

    if ("error" in result) {
      const errorMessage = (result.error as any).data.errorMessage;
      if (errorMessage === "INVALID_BIRTHDAY") {
        setModalVisible(true);
        setModalContent("탑승자에 맞는 유효한 생년월일을 입력해주세요.");
        return;
      }
      if (errorMessage === "해당좌석은 이미 판매된 좌석입니다.") {
        alert(errorMessage);
        navigate(-1);
        return;
      }
      setModalVisible(true);
      setModalContent(errorMessage);
    }
  };

  // 결합상품 조회 실패 시 이전 페이지로 이동
  if (isError) {
    alert("결합상품 조회 실패");
    navigate(-1);
    return;
  }

  return (
    <Layout text={intl.formatMessage({ id: "booking.title" })}>
      <Container maxWidth={false} className="mt-4 space-y-4 p-0">
        <TravelInfoSection schedule={schedule} seats={seats} />

        <Divider />

        {isBookerInfoVisible && (
          <BookerInfoSection
            bookerInfo={bookerInfo}
            setBookerInfo={setBookerInfo}
            validState={bookerValidState}
            validate={bookerValidate}
          />
        )}

        {/* TODO: Props를 더 깔끔하게 내려주는 방법 없을까? 너무 많음 지금 - Context 사용? */}
        <PassengerInfoSection
          agencyCode={hanpassAgencyCode}
          setAgencyCode={setHanpassAgencyCode}
          bookerDetails={bookerInfo}
          passengerDetails={passengerDetails}
          setPassengerDetails={setPassengerDetails}
          onChangePassengerDetail={handleChangePassengerDetail}
          validState={passengerValidState}
        />

        <Divider />

        {/* 카카오 쿠폰 표시 섹션 */}
        <CouponSection
          selectedCoupon={selectedCoupon}
          handleCouponChange={handleCouponChange}
          relatedGoods={couponList}
        />
        {/* 라차 적립금 */}
        {/* <LachaPointSection
          expectedPoint={price.pointAmount}
          isLoading={isFetchingPrice}
        /> */}

        {/* 총액 표시 섹션 */}
        <PriceSummarySection
          price={price}
          selectedCoupon={selectedCoupon}
          // expectedPoint={price.pointAmount}
          isLoading={isFetchingPrice}
        />

        <BookingGuidelines />
        <AgreementsSection
          agreements={agreements}
          setAgreements={setAgreements}
          handleAllAgree={handleAllAgree}
        />

        {/* 예매 버튼 */}
        <div className="flex gap-2">
          <CustomButton
            variant="outlined"
            onClick={handleAddToCart}
            size="medium"
            style={{
              backgroundColor: theme.palette.white.main,
            }}
            disabled={isLoading || isFetchingPrice}
          >
            {isKorean ? "장바구니 담기" : "Add to cart"}
          </CustomButton>

          <CustomButton
            id="booking.confirmText"
            onClick={handleReservation}
            size="medium"
            disabled={isLoading || isFetchingPrice}
          />
        </div>
      </Container>

      {isLoading && <LoadingSpinner overlap />}

      <ValidationModal modal>
        <p className="text-xl text-text-secondary">{modalContent}</p>
      </ValidationModal>
      <ConsentModal modal>
        <p className="text-xl text-text-secondary">{modalContent}</p>
      </ConsentModal>

      {/* 장바구니 담기 모달 */}
      <CartModal modal>
        <div>
          <p className="text-lg font-bold">
            {isKorean
              ? "상품을 장바구니에 담았어요."
              : "Products have been added to your cart."}
          </p>
          <p className="text-lg font-bold">
            {isKorean
              ? "장바구니에 담긴 상품은 20분 후에 자동으로 삭제됩니다."
              : "Products will be automatically removed from cart in 20 minutes."}
          </p>
        </div>
      </CartModal>
    </Layout>
  );
};

export default PaymentBookingPage;
