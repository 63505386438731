import CardLayout from "components/layout/CardLayout";
import TrainLogo from "components/TrainLogo";
import { Skeleton } from "@mui/material";

interface ScheduleItemSkeletonProps {
  number?: number;
}

const ScheduleItemSkeleton = ({ number = 10 }: ScheduleItemSkeletonProps) => {
  const skeletonHeader = () => {
    return (
      <div className="ml-2 flex items-center gap-2">
        <TrainLogo />
        <Skeleton variant="text" width={96} />
      </div>
    );
  };

  const skeletonAdditionalInfo = () => {
    return (
      <div className="flex w-full justify-end">
        <Skeleton variant="rounded" width={96} height={24} />
      </div>
    );
  };

  return Array.from(Array(number)).map((_, key) => (
    <CardLayout
      key={key}
      className="max-h-[184px] w-full p-3"
      header={skeletonHeader()}
      additionalInfo={skeletonAdditionalInfo()}
    >
      <section className="flex w-full flex-col items-center gap-3">
        <Skeleton variant="rounded" height={40} width="60%" />
        <Skeleton variant="rounded" height={28} width="40%" />
      </section>
    </CardLayout>
  ));
};

export default ScheduleItemSkeleton;
