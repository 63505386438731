import { Typography } from "@mui/material";
import { formatOrderStatus } from "utils/formatUtils";
import { FormattedMessage } from "react-intl";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";

type OrderStationSectionType = {
  order: Pick<OrderType, "orderCreatedDate" | "orderId" | "orderStatus">;
};

const OrderStatusSection = ({ order }: OrderStationSectionType) => {
  const [date, time] = order?.orderCreatedDate?.split("T") ?? [];

  return (
    <CardLayout>
      {order.orderId && (
        <p>
          <span className="font-bold">
            <FormattedMessage id="orders.orderId" />
          </span>
          <span>: {order.orderId}</span>
        </p>
      )}
      <p>
        <span className="font-bold">
          <FormattedMessage id="orders.orderStatus" />
        </span>
        <span>: </span>
        <Typography
          component="span"
          color={order.orderStatus === "CANCELED" ? "error" : "primary"}
          className="!font-bold"
        >
          <FormattedMessage id={formatOrderStatus(order.orderStatus)} />
        </Typography>
      </p>
      <p>
        <span className="font-bold">
          <FormattedMessage id="orders.orderDate" />
        </span>
        <span>
          : {date} {time}
        </span>
      </p>
    </CardLayout>
  );
};

export default OrderStatusSection;
