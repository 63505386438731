import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useFormatPrice from "hooks/useFormatPrice";
import {
  useLazyGetSearchCostQuery,
  useLazyGetSearchRemainingSeatsQuery,
  useLazyGetSeatMapQuery,
} from "app/apiSlice";
import CustomButton from "components/button/CustomButton";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { updateTrainParams } from "app/reservationSlice";
import { ScheduleType } from "types/scheduleType";
import { getTotalPassengersNumber } from "utils/urlSearchParamsUtils";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import LoadingSpinner from "components/LoadingSpinner";
import { getCostParams, getRequestData } from "utils/trainCostUtils";

interface CostDetailsProps {
  schedule: ScheduleType;
  isLoggedIn: () => Promise<boolean>;
}

const CostDetailsSection = ({ schedule, isLoggedIn }: CostDetailsProps) => {
  // 기차 예약을 위한 기본 요청 데이터 설정
  const requestData = getRequestData(schedule);
  // const ticketPriceFormData = createTicketPriceForm(schedule);

  const intl = useIntl();
  const formatPrice = useFormatPrice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // state
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const detailsRef = useRef<HTMLDivElement | null>(null); // Collapse 섹션 참조
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const [getSearchCost, { data: costDetails, isFetching, isUninitialized }] =
    useLazyGetSearchCostQuery();

  const isLoading = isUninitialized || isFetching;
  const [getRemainingSeats] = useLazyGetSearchRemainingSeatsQuery();
  const [getSeatMap] = useLazyGetSeatMapQuery();

  // const [ticketPrice, setTicketPrice] = useState<OrderType>();
  // const [isFetching, setIsFetching] = useState(true);
  const pointSavingRate = costDetails?.[0]?.pointSavingRt;
  useEffect(() => {
    const costParams = getCostParams(schedule);
    const timeoutMs = Number(schedule.chtnTrnOrdrNo) * 500;
    const timeoutId = setTimeout(async () => {
      getSearchCost(costParams, true);

      // console.log(data.refetch);
    }, timeoutMs);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [getSearchCost, schedule]);

  const handleSeatSelection = useCallback(async () => {
    if (!(await isLoggedIn())) {
      return;
    }

    setIsNextPageLoading(true);

    try {
      const { data: remainingSeats } = await getRemainingSeats(requestData);
      const availableCars = remainingSeats?.filter(
        (car) => car.restSeatNum > 0
      );

      if (!availableCars || availableCars?.length === 0) {
        alert("충분한 좌석이 없습니다.");
        window.location.reload();
        return;
      }

      const seatMaps = await Promise.all(
        availableCars.map(async (car) => {
          // console.log(
          //   `Car Number: ${car.scarNo}, Remaining Seats: ${car.restSeatNum}`
          // );
          const seatMapRequest = {
            ...requestData,
            scarNo: car.scarNo, // 차량 번호
            seatAttCd: "024", // 코레일 좌석 위탁 판매 속성 - 024로 고정
          } as const;

          return (await getSeatMap(seatMapRequest)).data;
        })
      );

      navigate(`/seat-selection/${schedule.trnNo}?${params.toString()}`, {
        state: { cars: seatMaps, restSeats: availableCars, schedule },
      });
    } catch (error) {
      console.error("Failed to fetch seat map:", error);
      navigate("/");
    } finally {
      setIsNextPageLoading(false);
    }
  }, [
    getRemainingSeats,
    getSeatMap,
    isLoggedIn,
    navigate,
    params,
    requestData,
    schedule,
  ]);

  const handleAutoSeatSelectionAndReservation = useCallback(async () => {
    if (!(await isLoggedIn())) {
      return;
    }

    setIsNextPageLoading(true);

    try {
      // 예약 가능한 좌석 조회
      const { data: remainingSeats } = await getRemainingSeats(requestData);
      // 예약 가능한 차량만 필터링
      const availableCars = remainingSeats?.filter(
        (car) => car.restSeatNum > 0
      );

      // 선택될 좌석들을 저장할 배열
      let selectedSeats = [];
      // 예약할 총 인원 수 계산
      let neededSeats = getTotalPassengersNumber();

      // 예약 가능한 차량을 순회하면서 좌석을 선택
      if (availableCars) {
        for (const car of availableCars) {
          // 필요한 좌석 수를 충족했으면 반복 중단
          if (neededSeats <= 0) break;

          // 차량별 좌석 맵 요청
          const seatMapRequest = {
            ...requestData,
            scarNo: car.scarNo, // 차량 번호
            seatAttCd: "024", // 코레일 좌석 위탁 판매 속성 - 024로 고정
          } as const;

          // 해당 차량의 좌석 맵 조회
          const { data: seatMapResponse } = await getSeatMap(seatMapRequest);

          // console.log("seatMapResponse", seatMapResponse);
          // seatMap이 배열인지 확인
          if (!Array.isArray(seatMapResponse?.restSeatList)) {
            console.error(
              "seatMap is not an array:",
              seatMapResponse?.restSeatList
            );
            alert("좌석 정보를 불러오는 데 실패했습니다.");
            return;
          }
          // 판매 가능한 좌석만 필터링
          const availableSeats = seatMapResponse.restSeatList.filter(
            (seat) => seat.salePsbFlg === "Y"
          );

          // 사용 가능한 좌석을 필요한 만큼 선택
          for (const seat of availableSeats) {
            if (neededSeats <= 0) {
              break;
            }

            selectedSeats.push({
              scarNo: car.scarNo,
              seatNo: seat.seatNo.padStart(3, "0"),
              seatSpec: seat.seatSpec,
            });

            neededSeats--;
          }
        }
      }

      // 선택된 좌석이 예약할 인원 수보다 적은 경우 경고
      if (selectedSeats.length < neededSeats) {
        alert("충분한 좌석이 없습니다.");
        window.location.reload();
        return;
      }

      dispatch(
        updateTrainParams({
          selectedSeats,
          trnNo: schedule.trnNo,
          seatAppFlg: "Y", // "자동 선택"
          costDetails, // 좌석 선택 시 해당 기차편 costDetail을 redux에 저장
        })
      );

      // 예약 상세 페이지로 이동
      // navigate(`/booking-detail/${schedule.trnNo}`, {
      navigate(`/payment/booking?${params.toString()}`, {
        state: {
          schedule,
          seats: selectedSeats,
        },
      });
    } catch (error) {
      // 오류 처리
      console.error("자동 좌석 선택 실패:", error);
      navigate("/");
    } finally {
      // 로딩 상태 종료
      setIsNextPageLoading(false);
    }
  }, [
    costDetails,
    dispatch,
    getRemainingSeats,
    getSeatMap,
    isLoggedIn,
    navigate,
    params,
    requestData,
    schedule,
  ]);

  useEffect(() => {
    // 좌석 조회 시 해당 기차편 costDetail을 redux에 저장
    dispatch(
      updateTrainParams({
        trnNo: schedule.trnNo,
        costDetails,
      })
    );
  }, [costDetails, dispatch, schedule.trnNo]);

  if (isLoading) {
    return <LoadingSpinner className="h-[88px]" />;
  }

  // price/kakaoT data
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  );
  const childPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "3"
  );

  // useEffect(() => {
  //   // 세부사항 표시 후 스크롤 이동
  //   if (!isLoading) {
  //     detailsRef.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //     detailsRef.current = null;
  //   }
  // });

  return (
    <div className="w-full">
      <PriceChipsSection
        pointSavingRate={pointSavingRate}
        className="max-[512px]:hidden"
      />
      <div className="flex w-full justify-between gap-6 max-[512px]:flex-col max-[512px]:items-center">
        <div
          ref={detailsRef}
          className="flex flex-col gap-2 max-[512px]:items-center"
        >
          {/* 적립 & 카카오 Chip */}
          <PriceChipsSection
            pointSavingRate={pointSavingRate}
            className="min-[512px]:hidden"
          />

          {/* 기차 가격 표시 */}
          {/* {ticketPrice?.totalPrice === 0 ? (
          <Skeleton variant="rounded" width={120} height={26} />
        ) : (
          <p className="font-bold text-gray-600">
            ₩ {ticketPrice?.totalPrice.toLocaleString()}
          </p>
        )} */}
          <div className="space-y-1 text-gray-700">
            <p className="text-sm">
              {intl.formatMessage(
                { id: "fareInquiry.adultPrice" },
                { price: formatPrice(adultPriceDetail?.csmrFare) }
              )}
            </p>
            <p className="text-sm text-gray-700">
              {intl.formatMessage(
                { id: "fareInquiry.childPrice" },
                { price: formatPrice(childPriceDetail?.mrkFare) }
              )}
            </p>
          </div>
        </div>
        <div className="flex items-end gap-1">
          <CustomButton
            id="fareInquiry.seatButtonText"
            onClick={handleSeatSelection}
            size="small"
            variant="outlined"
            className="bg-white"
            disabled={isNextPageLoading}
          />
          <CustomButton
            id="fareInquiry.autoButtonText" // 바로예약
            onClick={handleAutoSeatSelectionAndReservation}
            size="small"
            disabled={isNextPageLoading}
          />
        </div>
      </div>
      {isNextPageLoading && <LoadingSpinner overlap />}
    </div>
  );
};

export default CostDetailsSection;

interface PriceChipsSectionProps {
  pointSavingRate?: number;
  className?: string;
}

const PriceChipsSection = ({
  pointSavingRate,
  className = "",
}: PriceChipsSectionProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();
  // const kakaoCouponAmount = costDetails?.[0]?.couponAmount;
  const kakaoCouponAmount = 5000;
  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };
  const hideInfo = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    window.document.addEventListener("click", hideInfo);

    return () => {
      window.document.removeEventListener("click", hideInfo);
    };
  }, []);

  return (
    <div className={className}>
      {/* 적립 & 카카오 Chip */}
      {Boolean(pointSavingRate) && (
        <div className="relative flex items-center gap-1">
          <p className="mb-1 rounded-full bg-[#E10] px-2 py-1 text-center text-xs font-bold text-white">
            <FormattedMessage
              id={"fareInquiry.reward"}
              values={{ pointSavingRate }}
            />
          </p>
          <p className="mb-1 rounded-full bg-[#3856ee] px-2 py-1 text-center text-xs font-bold text-white">
            <FormattedMessage
              id={"cost.discount"}
              values={{ amount: formatPrice(kakaoCouponAmount) }}
            />
          </p>

          <InfoIcon
            fontSize="small"
            color="disabled"
            onClick={toggleInfo}
            className="max-[512px]:absolute max-[512px]:-right-[22px]"
          />
          {showInfo && (
            <Tooltip title="purchase information" arrow>
              <CardLayout className="absolute -right-2 top-8 z-10 w-4/5 p-2 min-[512px]:-top-14 min-[512px]:left-0">
                <div className="flex">
                  <p className="text-xs">
                    <FormattedMessage
                      id="train.costDetailedInformation"
                      defaultMessage={intl.formatMessage({
                        id: "train.costDetailedInformation",
                      })}
                    />
                  </p>
                </div>
              </CardLayout>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
