import { Divider } from "@mui/material";
import Arrow from "components/Arrow";
import { formatTime } from "utils/formatUtils";
import TrainLogo from "components/TrainLogo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useIntl } from "react-intl";
import { ScheduleType } from "types/scheduleType";
import CardLayout from "components/layout/CardLayout";

interface TrainInfoSectionProps {
  schedule: ScheduleType;
}

const TrainInfoSection = ({ schedule }: TrainInfoSectionProps) => {
  const intl = useIntl();

  const formatRunTime = (time: string) => {
    return `${time.substring(0, 2)}:${time.substring(2, 4)}`;
  };

  return (
    <CardLayout className="border-none p-0 shadow-none">
      <div className="mb-2 ml-2 flex items-center gap-2">
        <TrainLogo stlbTrnClsfCd={schedule.stlbTrnClsfCd} />

        <p className="text-sm">
          {schedule.trnNo} | {formatRunTime(schedule.runHm)}
        </p>
      </div>
      <Divider />
      <div className="mb-1 mt-3 flex w-full flex-col items-center">
        <p className="text-2xl">
          <div className="flex items-center gap-2">
            {formatTime(schedule.dptTm)}
            <Arrow />
            {formatTime(schedule.arvTm)}
          </div>
          {/* {formatTime(schedule.dptTm)} → {formatTime(schedule.arvTm)} */}
        </p>

        <div className="mt-2 flex items-center">
          <AccessTimeIcon color="action" />
          <p className="ms-1 text-sm">
            {intl.formatMessage({ id: "fareInquiry.economyClass" })}
            {" | "}
            {schedule.gnrmRsvPsbFlg === "Y"
              ? intl.formatMessage({ id: "fareInquiry.available" })
              : intl.formatMessage({ id: "fareInquiry.soldOut" })}
          </p>
        </div>
      </div>
    </CardLayout>
  );
};

export default TrainInfoSection;
