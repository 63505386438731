import { useMediaQuery } from "@mui/material";
import CustomButton from "components/button/CustomButton";

interface SeatSelectionActionsProps {
  onResetSelection: () => void;
  onAutoAssign: () => void;
  onFinalizeReservation: () => Promise<void>;
}

export default function SeatSelectionActions({
  onResetSelection,
  onAutoAssign,
  onFinalizeReservation,
}: SeatSelectionActionsProps) {
  const matches = useMediaQuery("(min-width:357px)");

  return (
    <div className="flex justify-end space-x-2">
      <CustomButton
        id="seatSelection.resetSelection"
        variant="outlined"
        size={matches ? "medium" : "small"}
        onClick={onResetSelection}
        style={{ backgroundColor: "white" }}
      />
      <CustomButton
        id="seatSelection.autoSelect"
        variant="outlined"
        size={matches ? "medium" : "small"}
        onClick={onAutoAssign}
        style={{ backgroundColor: "white" }}
      />
      <CustomButton
        id="seatSelection.confirmSelection"
        size={matches ? "medium" : "small"}
        onClick={onFinalizeReservation}
      />
    </div>
  );
}
