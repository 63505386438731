import { useOutletContext } from "react-router-dom";
import { CardContext } from "./CardPageLayout";
import CardDetail from "./CardDetail";
import RecentTransaction from "./RecentTransaction";
import A2HS from "components/A2HS";

const CardHome = () => {
  const { currentCard } = useOutletContext<CardContext>();
  const { iapCrdStcd: status } = currentCard || {};

  const pathname = window.location.pathname;
  const lastUrlSegment = pathname.slice(pathname.lastIndexOf("/") + 1);

  return (
    <>
      {status && (
        <div className="space-y-2">
          <CardDetail />
          {/* 카드 설정 탭에서는 최근 거래내역 가리기 */}
          {["card", "deposit", "transfer"].includes(lastUrlSegment) && (
            <RecentTransaction />
          )}
        </div>
      )}
      <A2HS />
    </>
  );
};

export default CardHome;
