import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import { OrderType } from "types/orderType";
import { CancelFeeCheckResponse } from "services/paymentService";
import KakaoAccordion from "components/coupon/KakaoAccordion";
import useLanguages from "hooks/useLanguages";
import CardLayout from "components/layout/CardLayout";

interface RelatedProductProps {
  order: OrderType;
  cancelFee?: CancelFeeCheckResponse;
}

const RelatedProduct = ({ order, cancelFee }: RelatedProductProps) => {
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  return (
    <CardLayout>
      <div className="mb-2 flex items-center justify-between">
        <p className="font-bold">{order.goodsList?.name}</p>
        <p className="font-bold">
          {formatPrice(order.goodsList?.price)}
          {isKorean && "권"}
        </p>
      </div>

      {cancelFee && cancelFee.errorMessage == null && (
        <div className="flex w-full items-center justify-between">
          <p>
            <FormattedMessage id="orders.cancelFee" />:
          </p>
          <p>
            {"1000".toLocaleString()}
            <FormattedMessage id="payment.koreanWon" />
          </p>
        </div>
      )}
      {!(order.orderStatus === "CANCELED") && (
        <KakaoAccordion descriptionKey={order.goodsList?.description} />
      )}
    </CardLayout>
  );
};

export default RelatedProduct;
