import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import Instructions from "./sections/Instructions";
import SeatMap from "./sections/SeatMap";
import SeatSelectionActions from "./sections/SeatSelectionActions";
import TrainInfoIndicator from "./sections/TrainInfoIndicator";
import Layout from "components/layout/Layout";
import CarTabs from "./sections/CarTabs";
import useTranslateStation from "hooks/useTranslateStation";
import { SeatAppFlgType, updateTrainParams } from "app/reservationSlice";
import { useAppDispatch } from "app/hooks";
import { SeatDetails } from "components/seat/Seat";
import { CarType } from "types/carType";
import { getTotalPassengersNumber } from "utils/urlSearchParamsUtils";
import CardLayout from "components/layout/CardLayout";
import { providerClasses } from "utils/tailwindUtils";

type RequiredSeatDetails = Required<SeatDetails>;

const sortSelectedSeats = (selectedSeats: RequiredSeatDetails[]) => {
  const newSeats = selectedSeats.map((seat) => seat.seatSpec);
  newSeats.sort();
  return newSeats.join(", ");
};

const SeatSelectionPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const translateStation = useTranslateStation();

  const params = new URLSearchParams(window.location.search);
  const totalPassengers = getTotalPassengersNumber(params);
  const departure = params.get("departure") ?? "Departure Station";
  const arrival = params.get("arrival") ?? "Arrival Station";

  const {
    state: { cars, restSeats, schedule },
  } = useLocation(); // cars, restSeats : 이전 페이지에서 받은 차량 데이터

  // 선택된 좌석과 탭 상태 관리
  const [selectedSeats, setSelectedSeats] = useState<RequiredSeatDetails[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [seatAppFlg, setSeatAppFlg] = useState<SeatAppFlgType>("Y"); // default 수동 선택

  // 호차 탭 레이블 생성
  const tabLabels: string[] = cars.map((car: CarType) =>
    intl.formatMessage(
      { id: "seatSelection.carNumber" },
      { number: +car.scarNo }
    )
  );

  // 좌석 선택 핸들러 업데이트
  const handleSeatSelect = ({
    seatNo,
    seatSpec,
  }: Pick<RequiredSeatDetails, "seatNo" | "seatSpec">) => {
    const currentCarNo = cars[selectedTab].scarNo;

    setSeatAppFlg("Y"); // 수동 선택
    setSelectedSeats((prevSeats) => {
      // const newSeat = { scarNo: currentCarNo, seatNo: seatId };
      // 이미 선택된 좌석이면 제거, 아니면 추가
      if (
        prevSeats.some(
          (prevSeat) =>
            Number(prevSeat.seatNo) === Number(seatNo) &&
            prevSeat.scarNo === currentCarNo
        )
      ) {
        // if (prevSeats.some((prevSeat) => prevSeat.seatNo === seatId)) {
        return prevSeats.filter(
          (prevSeat) =>
            Number(prevSeat.seatNo) !== Number(seatNo) ||
            prevSeat.scarNo !== currentCarNo
        );
        // return prevSeats.filter((prevSeat) => prevSeat.seatNo !== seatId);
      }

      // 선택 가능한 최대 좌석 수를 초과하는 경우 경고
      if (prevSeats.length + 1 > totalPassengers) {
        alert(intl.formatMessage({ id: "seatSelection.seatExceedError" }));
        return prevSeats;
      }

      const newSeat = {
        scarNo: currentCarNo,
        seatNo: seatNo.padStart(3, "0"),
        seatSpec,
      };

      return [...prevSeats, newSeat];
    });
  };

  const handleFinalizeReservation = async () => {
    // Check if any seats are selected
    if (selectedSeats.length === 0) {
      alert(intl.formatMessage({ id: "seatSelection.seatNotSelectError" }));
      return;
    }

    // Map selected seats for reservation
    const seatsForReservation = selectedSeats
      .map(({ scarNo, seatNo, seatSpec }: RequiredSeatDetails) => ({
        scarNo,
        seatNo: seatNo.padStart(3, "0"),
        seatSpec,
      }))
      .sort((a, b) => Number(a.seatNo) - Number(b.seatNo)); // 좌석 오름차순 정렬

    // Update reservation state with selected seats
    dispatch(
      updateTrainParams({
        selectedSeats: seatsForReservation,
        seatAppFlg,
      })
    );

    // Compare the number of selected seats with the total number of passengers
    if (seatsForReservation.length < totalPassengers) {
      alert(
        intl.formatMessage(
          { id: "seatSelection.seatNotEnoughError" },
          { seats: totalPassengers }
        )
      );
      return;
    }

    navigate(`/payment/booking?${params.toString()}`, {
      state: {
        schedule,
      },
    });
  };

  // 자동 좌석 할당 핸들러
  const handleAutoAssign = () => {
    setSeatAppFlg("Y"); // 자동 선택

    const availableSeats = cars[selectedTab].restSeatList.filter(
      (seat: { salePsbFlg: SeatAppFlgType }) => seat.salePsbFlg === "Y" // 판매 가능한 좌석 필터링
    );
    // Compare the number of selected seats with the total number of passengers
    if (availableSeats.length >= totalPassengers) {
      const randomSeats: RequiredSeatDetails[] = [];
      for (let i = 0; i < totalPassengers; i++) {
        const randomSeatIndex = Math.floor(
          Math.random() * availableSeats.length
        );
        const randomSeat = availableSeats.splice(randomSeatIndex, 1)[0];
        randomSeats.push(randomSeat);
      }

      // Map selected seats for reservation
      const seatsForReservation = randomSeats.map(
        ({
          seatNo,
          seatSpec,
        }: Pick<RequiredSeatDetails, "seatNo" | "seatSpec">) => ({
          scarNo: cars[selectedTab].scarNo,
          seatNo: seatNo.padStart(3, "0"),
          seatSpec,
        })
      );

      // Update reservation state with selected seats
      dispatch(
        updateTrainParams({
          selectedSeats: seatsForReservation,
          seatAppFlg,
        })
      );

      navigate(`/payment/booking?${params.toString()}`, {
        state: {
          schedule,
        },
      });
    } else {
      alert("사용 가능한 좌석이 없습니다.");
    }
  };

  // 열차 호차 탭 변경 핸들러
  const handleTabChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    // Check if there are any selected seats
    if (selectedSeats.length > 0 && newValue !== selectedTab) {
      alert("한 호차에서 예매를 완료해주세요.");
      return;
    }

    setSelectedTab(newValue);
  };

  return (
    <Layout text={intl.formatMessage({ id: "seatSelection.title" })}>
      <div className="space-y-4">
        <Instructions />
        <TrainInfoIndicator
          schedule={schedule}
          car={cars[selectedTab]}
          restSeat={restSeats[selectedTab]}
        />
        <CardLayout className="pt-0 text-center">
          <CarTabs
            value={selectedTab}
            onChange={handleTabChange}
            tabLabels={tabLabels}
          />
          <p>
            {intl.formatMessage(
              { id: "seatSelection.stationDirection" },
              { station: translateStation(arrival) }
            )}
          </p>
          <SeatMap
            seats={cars[selectedTab]?.restSeatList}
            onSeatSelect={handleSeatSelect}
            selectedSeats={selectedSeats}
            scar={cars[selectedTab]}
          />
          <p>
            {intl.formatMessage(
              { id: "seatSelection.stationDirection" },
              { station: translateStation(departure) }
            )}
          </p>
          <p className="mt-2 font-bold">
            {intl.formatMessage({ id: "seatSelection.selectedSeatNumber" })}:
            <span className={`${providerClasses["text-primary"]}`}>
              {sortSelectedSeats(selectedSeats)}
            </span>
          </p>
        </CardLayout>

        <SeatSelectionActions
          onResetSelection={() => setSelectedSeats([])}
          onAutoAssign={handleAutoAssign}
          onFinalizeReservation={handleFinalizeReservation}
        />
      </div>
    </Layout>
  );
};

export default SeatSelectionPage;
