import { __DEV__ } from "const/properties";
import {
  darkDefaultTheme,
  darkPayboocTheme,
  lightDefaultTheme,
  lightGMETheme,
  lightHanaCardTheme,
  lightLotteCardTheme,
  lightPayboocTheme,
  lightRedTableTheme,
} from "theme/theme";
import { ProviderUtils } from "./providerUtils";

export const getTheme = (theme: "light" | "dark") => {
  if (__DEV__) {
    return lightDefaultTheme;
  }

  if (ProviderUtils.isPaybooc) {
    return theme === "light" ? lightPayboocTheme : darkPayboocTheme;
  }

  if (ProviderUtils.isLottecard) {
    return lightLotteCardTheme;
  }

  if (ProviderUtils.isHanacard) {
    return lightHanaCardTheme;
  }

  if (ProviderUtils.isGME) {
    return lightGMETheme;
  }

  if (ProviderUtils.isRedTable) {
    return lightRedTableTheme;
  }

  // return lightHanaCardTheme;
  return theme === "light" ? lightDefaultTheme : darkDefaultTheme;
};
