import { useAppDispatch } from "app/hooks";
import { apiSlice } from "app/apiSlice";
import { deleteUser } from "app/userSlice";
import { deleteReservation } from "app/reservationSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import { resetCard } from "app/cardsSlice";
import TokenService from "services/tokenService";
import { logout } from "app/actionCreators";

interface useReduxUtilsParams {
  retainToken?: boolean;
}

/**
 * Reset all of the state in the redux store and RTK query cache.
 *
 * @param {boolean} [retainToken=false] - If true, the access token is retained.
 * @returns {{ resetStore: () => void }}
 */
const useReduxUtils = ({ retainToken = false }: useReduxUtilsParams) => {
  const dispatch = useAppDispatch();

  const resetStore = () => {
    // Reset the RTK query cache. This is required to delete all the data cached by RTK query.
    dispatch(apiSlice.util.resetApiState());

    // RTK query로 바꾼 다음 삭제하기
    dispatch(deleteUser());
    dispatch(deleteReservation());
    dispatch(deleteMyOrders());
    dispatch(resetCard());

    // // localStorage에 있는 redux-persist 삭제
    dispatch(logout());

    if (retainToken) {
      return;
    }
    TokenService.removeAccessToken();
  };

  return { resetStore };
};

export default useReduxUtils;
