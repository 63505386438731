import { Divider, TextField } from "@mui/material";
import Layout from "components/layout/Layout";
import HomeActivityButton, {
  ActivityType,
} from "./components/HomeActivityButton";
import ActivityThumbnail from "./components/ActivityThumbnail";
import ThumbnailSkeleton from "./components/skeleton/ThumbnailSkeleton";
import clsx from "clsx";
import JapanMap from "./components/JapanMap";
import { useGetRecommendedActivityQuery } from "app/apiSlice";
import JRIcon from "assets/icons/jr.png";
import USJIcon from "assets/icons/usj.png";
import BusIcon from "assets/icons/bus.png";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import BackgroundImage from "pages/ktx/home-panel/sections/bgImage/BackgroundImage";
import { ProviderUtils } from "utils/providerUtils";
import CustomButton from "components/button/CustomButton";
import { useNavigate } from "react-router-dom";

const ActivityHome = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const { data: recommendedActivityList, isFetching } =
    useGetRecommendedActivityQuery(undefined, {
      refetchOnMountOrArgChange: 3600, // 캐시 유지 시간을 1시간으로 설정
    });


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);

    // if (!keyword) {
    //   params.delete("keyword");
    //   navigate(`?${params.toString()}`);
    //   return;
    // }

    // params.set("keyword", keyword);
    // navigate(`?${params.toString()}`, { replace: true });
  };

  const handleSearchSubmit = () => {
    if (!searchTerm) {
      return;
    }

    navigate(`search?keyword=${searchTerm}`);
  };

  const activityButtonList: ActivityType[] = useMemo(
    () => [
      {
        type: "CATEGORY",
        text: intl.formatMessage({ id: "jtr.jr" }),
        color: "#DAFFD3",
        href: `category/jr`,
        icon: JRIcon,
      },
      {
        type: "CATEGORY",
        text: intl.formatMessage({ id: "jtr.usj" }),
        color: "#FFDBC3",
        href: `category/usj`,
        icon: USJIcon,
      },
      {
        type: "CATEGORY",
        text: intl.formatMessage({ id: "jtr.bus" }),
        color: "#FFF2CD",
        href: `category/bus`,
        icon: BusIcon,
      },
    ],
    [intl]
  );

  return (
    <>
      {ProviderUtils.isHanacard && <BackgroundImage />}
      <Layout>
        <article className="my-4 space-y-4">
          <form action="" onSubmit={() => handleSearchSubmit()}>
            <div className="grid grid-cols-8 gap-2">
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder={"지역, 상품명 검색"}
                className="col-span-6 rounded-lg bg-white"
                // value={params.get("keyword") || ""}
                value={searchTerm}
                onChange={handleSearch}
              />
              <CustomButton
                id="jtr.search"
                size="medium"
                // disabled={!searchTerm}
                className="col-span-2"
                style={{ fontWeight: "500" }}
                onClick={handleSearchSubmit}
              />
            </div>
          </form>
          <Divider />

          {/* 주제별 */}
          <section className="space-y-2">
            <h3 className="text-xl font-bold">
              {intl.formatMessage({ id: "jtr.categoryProduct" })}
            </h3>

            <section className="grid grid-cols-3 items-stretch gap-1">
              {activityButtonList.map((activityButton) => (
                <HomeActivityButton
                  key={activityButton.text}
                  activity={activityButton}
                />
              ))}
            </section>
          </section>

          <Divider />
          {/* 지역별 */}
          <section className="space-y-2">
            <h3 className="text-xl font-bold">
              {intl.formatMessage({ id: "jtr.regionProduct" })}
            </h3>

            <JapanMap />
          </section>

          <Divider />

          {/* 추천상품 */}
          <section className="space-y-2">
            <h3 className="text-xl font-bold">
              {intl.formatMessage({ id: "jtr.recommendedProduct" })}
            </h3>
            <section
              className={clsx(
                "grid grid-cols-2 gap-2",
                isFetching && "opacity-50"
              )}
            >
              {isFetching ? (
                <ThumbnailSkeleton direction="column" />
              ) : (
                recommendedActivityList?.content.map((activity, key) => (
                  <ActivityThumbnail
                    activity={activity}
                    direction="column"
                    key={`${activity.goodsName}-${key}`}
                  />
                ))
              )}
            </section>
          </section>
        </article>
      </Layout>
    </>
  );
};

export default ActivityHome;
