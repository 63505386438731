import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ProviderUtils } from "utils/providerUtils";

const useHappytalk = (urlArray: string[] = ["/"]) => {
  const location = useLocation();
  // const happytalk = useRef();

  // useEffect(() => {
  // const script = document.createElement("script");

  // Set the source to the path of the JavaScript file
  // script.src = "https://design.happytalkio.com/sdk/happytalk.chat.v2.min.js";
  // script.id = "happytalkSDK";
  // script.async = true;

  // script.onload = () => {
  // SDK 초기화
  // happytalk.current = new window.Happytalk({
  //   siteId: "4000001875",
  //   siteName: "주식회사 라쿠카라차",
  //   categoryId: "135014",
  //   divisionId: "134725",
  //   kakaoId: "@lacucaracha",
  //   options: {
  //     theme: "simple",
  //     mobileButtonRight: "12",
  //     mobileButtonBottom: "60",
  //   },
  // });
  // };

  // Append the script to the document's head or body
  // document.body.appendChild(script); // Or document.head.appendChild(script);

  // return () => {
  //   const happytalkSDK = document.getElementById("happytalkSDK");
  //   happytalkSDK?.remove();
  // };
  // }, []);

  useEffect(() => {
    if (!ProviderUtils.isCard) {
      const happytalkIframe = document.getElementById("HappytalkIframe");
      if (happytalkIframe) {
        if (urlArray.includes(location.pathname)) {
          happytalkIframe.style.visibility = "visible";
        }

        return () => {
          happytalkIframe.style.visibility = "hidden";
        };
      }
    }
  }, [location.pathname, urlArray]);
};

export default useHappytalk;
