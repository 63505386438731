import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Barcode from "react-barcode";
import CardService, { VAccountInfo } from "services/cardService";
import { useOutletContext } from "react-router-dom";
import { CardContext } from "../CardPageLayout";
import useModalSheet from "hooks/overlay/useModalSheet";
import CardLayout from "components/layout/CardLayout";
import clsx from "clsx";
import AmountWrapper from "../components/deposit-withdraw/AmountWrapper";
import AmountInput from "../components/deposit-withdraw/AmountInput";
import VAccountContent from "../components/deposit-withdraw/VAccountContent";
import {
  getURLSearchParams,
  removeSearchParam,
} from "utils/urlSearchParamsUtils";

const options = ["DEPOSIT", "WITHDRAWAL"] as const;
export type OptionType = (typeof options)[number]; // "DEPOSIT" | "WITHDRAWAL"

const DepositWithdraw = () => {
  const intl = useIntl();
  const [VAccountInfo, setVAccountInfo] = useState<VAccountInfo>();
  const [VAccountBottomSheet, setVAccountBottomSheetVisibility] = useModalSheet(
    {}
  );
  const [VAccountAmountBottomSheet, setVAccountAmountBottomSheetVisibility] =
    useModalSheet({
      callbackOk: () => {
        openVAccountChargeBottomSheet();
      },
    });
  const [
    ConvenienceStoreAmountBottomSheet,
    setConvenienceStoreAmountBottomSheetVisibility,
  ] = useModalSheet({
    callbackOk: () => {
      handleDepositWithdrawal();
    },
  });

  const depositWithdrawalResult = getURLSearchParams().get("result");
  const [AlertModal] = useModalSheet({
    callbackOk: () => removeSearchParam("result"),
    defaultVisible: Boolean(depositWithdrawalResult),
  });

  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber, iapCrdStcd: status } = currentCard || {};
  const isActive = status === "01";
  const [balance, setBalance] = useState(0);
  // card balance
  useEffect(() => {
    const getBalance = async () => {
      const { data } = await CardService.getCardBalance(cardNumber);
      const balance = Number(data?.acnRmd || 0);
      setBalance(balance);
    };

    getBalance();
  }, [cardNumber]);

  const [option, setOption] = useState<OptionType>("DEPOSIT");
  const [barcodeSpec, setBarcodeSpec] = useState("");
  // 입출금 입력 금액을 위한 ref
  const amountRef = useRef(0);

  // Reset form when card changes
  useEffect(() => {
    setOption("DEPOSIT");
    amountRef.current = 0;
    setBarcodeSpec("");
  }, [currentCard]);

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as OptionType;
    amountRef.current = 0;
    setOption(value);
    if (barcodeSpec) {
      setBarcodeSpec("");
    }
  };

  const handleDepositWithdrawal = async () => {
    if (
      option === "DEPOSIT" &&
      (amountRef.current < 30_000 || amountRef.current > 500_000)
    ) {
      alert("Amount must be between 30,000 and 500,000 won");
      return;
    }

    if (option === "WITHDRAWAL" && amountRef.current > balance) {
      alert("Can't withdraw more than balance");
      amountRef.current = 0;
      return;
    }

    try {
      const response = await CardService.cvsBarcode(
        cardNumber,
        option,
        amountRef.current,
        balance
      );

      setBarcodeSpec(response.barcodeSpec);
    } catch (error) {
      alert(intl.formatMessage({ id: "alert.tryAgain" }));
      console.error(error);
    }
  };

  const openVAccountChargeBottomSheet = async () => {
    if (option === "DEPOSIT" && amountRef.current === 0) {
      alert("Please enter a amount");
      return;
    }

    try {
      const data = await CardService.depositVirtualAccount(
        cardNumber,
        amountRef.current
      );
      setVAccountInfo(data);
      setVAccountAmountBottomSheetVisibility(false);
      setVAccountBottomSheetVisibility(true);
    } catch (error) {
      alert(intl.formatMessage({ id: "alert.tryAgain" }));
      console.error(error);
    }
  };

  const navigateToVoucherPage = async (
    cardType: "international" | "domestic"
  ) => {
    const pgType = cardType === "international" ? "104" : "101";

    try {
      const { data } = await CardService.getCardChargeUrl(cardNumber, pgType);
      const params = new URLSearchParams({
        // cardNo: cardNumber,
        // isgRqsDscd: "101", // 101 | 104 (default) | 106 | 901 | 902
        isgLanDscd: intl.locale, // "ko" (default) | "en" | "cn" | "jp"
        cardBalance: balance,
      } as any);
      const url = `${data}&${params.toString()}`;
      window.location.href = url;
      // window.open(url, "_blank", "noopener, noreferrer");
    } catch (error) {
      alert(intl.formatMessage({ id: "alert.tryAgain" }));
      console.error(error);
    }
  };

  const getHeader = () => {
    return (
      <FormControl>
        <RadioGroup
          row
          value={option}
          defaultValue={option}
          name="option-group"
          onChange={handleChangeOption}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              disabled={option === "WITHDRAWAL" && balance === 0}
              label={
                <p className="font-bold">
                  <FormattedMessage id={`card.${option.toLowerCase()}`} />
                </p>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <>
      <CardLayout header={getHeader()} className="border-none p-2 shadow-none">
        <div className="w-full space-y-4">
          {option === "WITHDRAWAL" && (
            <AmountWrapper option={option}>
              <AmountInput amountRef={amountRef} />

              <p className="text-end text-xs">
                <FormattedMessage
                  id={`card.convenienceStore.${option.toLocaleLowerCase()}Limit`}
                  values={{
                    max: 3,
                  }}
                />
              </p>
            </AmountWrapper>
          )}

          {barcodeSpec && (
            <div className="flex justify-center">
              <Barcode
                value={barcodeSpec}
                height={48}
                width={1}
                format="CODE128"
                // displayValue
                fontSize={12}
              />
            </div>
          )}

          <div
            className={clsx("grid gap-2", {
              "grid-cols-2": option === "DEPOSIT",
              "grid-cols-1": option === "WITHDRAWAL",
            })}
          >
            {option === "DEPOSIT" && (
              <CustomButton
                size="medium"
                onClick={setVAccountAmountBottomSheetVisibility.bind(
                  null,
                  true
                )}
                id={`card.virtualAccount.${option.toLowerCase()}`}
                disabled={!isActive}
              />
            )}

            <CustomButton
              size="medium"
              onClick={
                option === "DEPOSIT"
                  ? setConvenienceStoreAmountBottomSheetVisibility.bind(
                      null,
                      true
                    )
                  : handleDepositWithdrawal
              }
              id={`card.convenienceStore.${option.toLowerCase()}`}
              disabled={!isActive}
            />
            {option === "DEPOSIT" && (
              <>
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={() => navigateToVoucherPage("international")}
                  id={`card.internationalCard`}
                  disabled={!isActive}
                />
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={() => navigateToVoucherPage("domestic")}
                  id={`card.domesticCard`}
                  disabled={!isActive}
                />
              </>
            )}
          </div>
        </div>
      </CardLayout>

      {option === "DEPOSIT" && (
        <>
          <VAccountAmountBottomSheet>
            <AmountWrapper option={option}>
              <AmountInput amountRef={amountRef} />
            </AmountWrapper>
          </VAccountAmountBottomSheet>
          <ConvenienceStoreAmountBottomSheet>
            <AmountWrapper option={option}>
              <AmountInput amountRef={amountRef} />
              <p className="text-end text-xs">
                <FormattedMessage
                  id={`card.convenienceStore.${option.toLocaleLowerCase()}Limit`}
                  values={{
                    min: 3,
                    max: 50,
                  }}
                />
              </p>
            </AmountWrapper>
          </ConvenienceStoreAmountBottomSheet>
        </>
      )}

      {VAccountInfo && (
        <VAccountBottomSheet>
          <VAccountContent VAccountInfo={VAccountInfo} />
        </VAccountBottomSheet>
      )}

      <AlertModal modal>
        <p className="font-bold">
          <FormattedMessage
            id={`${depositWithdrawalResult === "true" ? "card.depositSuccess" : "card.depositFail"}`}
          />
        </p>
      </AlertModal>
    </>
  );
};

export default DepositWithdraw;
