import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TokenService from "services/tokenService";
import LoadingSpinner from "components/LoadingSpinner";
import { fetchUser } from "app/userSlice";
import { useAppDispatch } from "app/hooks";
import UserService from "services/userService";
import { StorageUtils } from "utils/storageUtils";
import useReduxUtils from "hooks/useReduxUtils";

/**
 *  Hanpass, Lottecard, GME처럼 자체적으로 로그인하는 경우 접속하는 provider page
 *  - Get the access token and go to the user page
 *  - Because Hanpass, Lottecard, GME has no logout function, delete the user information when logging in.
 *  - If the user logs in with Hanpass, set the Hanpass back link value for handling hanpass back button click.
 */
const LoginProviderPage = () => {
  // 사용자 로그인 정보 상태 관리
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const at = searchParams.get("accessToken");
  const link = searchParams.get("initialLink");
  const { resetStore } = useReduxUtils({ retainToken: false });

  useEffect(() => {
    // 다른 계정으로 로그인하는 경우 redux에서 이전 로그인 정보 삭제(like logout function) + delete access token
    TokenService.removeAccessToken();
    resetStore();

    // Hanpass 뒤로가기 대응 코드
    // if (ProviderUtils.isHanpass) {
    //   if (StorageUtils.HanpassBack.get()) {
    //     StorageUtils.HanpassBack.remove();
    //     window.location.href = "http://finish";
    //     return;
    //   }

    //   StorageUtils.HanpassBack.set("login");
    // }

    // let perfEntries = window.performance.getEntriesByType("navigation");
    // if ((perfEntries[0] as any).type === "back_forward") {
    //   alert("뒤로가기로 넘어왔습니다. 메인 메뉴로 돌아갑니다.");
    //   window.location.replace("http://finish");
    // }

    // window.addEventListener("pageshow", (event) => {
    //   alert("뒤로가기");
    //   if (event.persisted) {
    //     alert("뒤로가기로 넘어왔습니다. 메인 메뉴로 돌아갑니다.");
    //     window.location.replace("http://finish");
    //   }
    // });

    if (at) {
      TokenService.setAccessToken(at);

      UserService.getMyInfo().then((userInfoResponse) => {
        dispatch(fetchUser(userInfoResponse));
        StorageUtils.SavedLoginId.set(userInfoResponse.loginId);
        navigate("/" + link, { replace: true });
      });
    }
  }, [at, dispatch, link, navigate]);

  return <LoadingSpinner />;
};

export default LoginProviderPage;
