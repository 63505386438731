import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import CardLayout from "components/layout/CardLayout";

const MenuOptions = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <section className="my-2 flex w-full justify-around gap-2">
      {[
        {
          Icon: HeadsetMicIcon,
          text: intl.formatMessage({ id: "user.customerCenter" }),
          link: "/customer-center",
        },
        {
          Icon: CampaignIcon,
          text: intl.formatMessage({ id: "user.notice" }),
          link: "./",
        },
      ].map(({ Icon, text, link }, index) => (
        <CardLayout key={index} className="w-full">
          <div className="m-auto h-[30vw] max-h-[120px]">
            <button
              onClick={() => navigate(link)}
              className="h-full w-full transform-none"
              // sx={{
              //   textTransform: "none",
              //   width: "100%",
              //   height: "100%",
              // }}
              color="secondary"
            >
              <div className="space-y-2">
                <Icon fontSize="large" />
                <p className="text-sm">{text}</p>
              </div>
            </button>
          </div>
        </CardLayout>
      ))}
    </section>
  );
};

export default MenuOptions;
