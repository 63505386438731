import { forwardRef } from "react";

interface ActivityDetailLayoutProps {
  children: React.ReactNode;
  title: string;
}

const ActivityDetailLayout = forwardRef<
  HTMLDivElement,
  ActivityDetailLayoutProps
>(({ children, title }, ref) => {
  return (
    <article
      id={title}
      ref={ref}
      className="activity-layout relative min-h-[50vh] max-w-lg space-y-4 break-words rounded-lg bg-white p-4 [&_img]:!h-auto [&_img]:!w-full [&_img]:!max-w-md"
      // className="activity-layout relative min-h-[50vh] max-w-lg space-y-4 break-words rounded-lg bg-white p-4 [&_img]:!h-auto [&_img]:!w-full [&_img]:!max-w-md [&_img]:rounded-xl"
      // style={
      // {
      // Added this style using tailwind arbitrary variants
      // https://tailwindcss.com/docs/hover-focus-and-other-states#using-arbitrary-variants
      // "& img": {
      //   height: "auto !important",
      //   width: "100% !important",
      //   maxWidth: "480px !important",
      //   borderRadius: "12px",
      // },
      // Added this style using tailwind @layer directive
      // "& div[style='background: rgb(238, 238, 238); border: 1px solid rgb(204, 204, 204); padding: 5px 10px;']":
      //   {
      //     bgcolor: "#F5F6F8 !important",
      //     borderRadius: "12px",
      //     border: "none !important",
      //     py: "12px !important",
      //     px: "16px !important",
      //   },
      // }
      // }
    >
      <h6 className="text-xl font-bold">{title}</h6>
      {children}
    </article>
  );
});

export default ActivityDetailLayout;
